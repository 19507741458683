<template>
  <!-- eslint-disable max-len -->
  <div id="app">
    <div id="page-container">
      <header>
        <nav>
          <div>
            <a href="https://www.ameren.com/illinois/residential/electric-vehicles" target="_blank">
              <img alt="Ameren"
                src="../../assets/Ameren_IL_logo.png"
                />
            </a>
          </div>
          <div class="navbar" v-if="! $globalObservableData.showNavDropown">
            <router-link to="/amerenillinois/ev_comparison">Compare EVs</router-link>
            <!-- <router-link to="/amerenillinois/ev_comparison_commercial">Fleet EVs</router-link> -->
            <router-link to="/amerenillinois/charger_comparison">Compare Chargers</router-link>
            <router-link to="/amerenillinois/savings_calculator">Calculate Savings</router-link>
            <router-link to="/amerenillinois/charger_map">Find Chargers</router-link>
            <router-link to="/amerenillinois/dealers">Find Dealerships</router-link>
          </div>
          <div v-else>
            <b-dropdown right no-caret class="mobileNavDropdown">
              <template #button-content>
                <font-awesome-icon  icon="fa-solid fa-bars"/>
              </template>
              <b-dropdown-item> <router-link to="/amerenillinois/ev_comparison">Compare EVs</router-link> </b-dropdown-item>
              <!-- <b-dropdown-item> <router-link to="/amerenillinois/ev_comparison_commercial">Fleet EVs</router-link> </b-dropdown-item> -->
              <b-dropdown-item> <router-link to="/amerenillinois/charger_comparison">Compare Chargers</router-link> </b-dropdown-item>
              <b-dropdown-item> <router-link to="/amerenillinois/savings_calculator">Calculate Savings</router-link> </b-dropdown-item>
              <b-dropdown-item> <router-link to="/amerenillinois/charger_map">Find Chargers</router-link> </b-dropdown-item>
              <b-dropdown-item> <router-link to="/amerenillinois/dealers">Find Dealerships</router-link> </b-dropdown-item>
            </b-dropdown>
          </div>
        </nav>
      </header>
      <main>
        <router-view/>
      </main>
      <footer id="footer">
        <a href="https://www.thedrivenexperience.com" target="_blank">
          <img alt="Driven"
            src="../../assets/TagLine_Driven_Clr.png"
            style="height: 30px;" />
        </a>
        <div>
          EV Tools Powered by Driven. Find out more at
          <a href="https://thedrivenexperience.com" target="_blank">The Driven Experience</a>
          Last updated 5/30/2023.
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: {
        dollarsPerKwh: 0.11,
        dollarsPerGallon: 3.00,
      },
      client_api_path: 'amerenillinois',
      // pdf_url: require('@/assets/AIC_RES_EVChargeGuide_IR5_050323.pdf'),
      ev_charger_description: 'Ameren Illinois has created a <a href="/AIC_RES_EVChargeGuide_IR5_050323.pdf" target="_blank">Charging Installation Guide for Residents</a> to help provide practical tips on EV charging. To install Level 2 charging at your home or business we recommend you consult with an Illinois Commerce Commission <a href="https://www.icc.illinois.gov/authority/electric-vehicle-charging-station-installer" target="_blank">EV Charging Station Certified Installer</a>.',
      center: { lat: 40, lng: -90 },
      dealer_partner_description: 'The listed dealers participate in the Ameren Illinois Dealer Partner Program, which works closely with local EV dealers to provide the latest EV resources to all those looking to purchase an EV.',
      ev_comparison_description: 'Ameren Illinois can help you save on home charging. Learn more about the ChargeSmart program <a href="https://www.ameren.com/illinois/residential/electric-vehicles/rate"  target="_blank">here</a>.',
      plugshare_map_url: 'https://www.plugshare.com/widget2.html?latitude=40.6331249&longitude=-89.3985283&spanLat=3.61418&spanLng=3.61418&plugs=1,2,3,4,5,6,42,13,7,8,9,10,11,12,14,15,16,17',
    };
  },
  mounted() {
  },
  methods: {
  },
  created() {
    // Override tab title and favicon
    const link = document.querySelector('[rel~=\'icon\']');
    /* eslint-disable */
    link.href = require('../../assets/Ameren_Logo_Favicon.png');
    /* eslint-enable */

    // Load EV Data
    this.$parent.getEVData();
    this.$parent.getChargerData();
    // this.$parent.getIncentiveData();
  },
};
</script>

<style scoped>
#app {
  --client-color: #599346;
  --client-color-darker: #438441;
  --client-color-selected: #1B6CB5;
  --client-color-red: #D98C24;
  --client-color-savings-icons: #599346;
}
</style>
