import Vue from 'vue';
import VueRouter from 'vue-router';
// Works with Google Analytics
import VueGtag from 'vue-gtag';

import MarinCounty from '../components/subsites/MarinCounty.vue';
import PacificPower from '../components/subsites/PacificPower.vue';
import DrivenRootSite from '../components/subsites/DrivenRootSite.vue';
import AmerenMissouri from '../components/subsites/AmerenMissouri.vue';
import AmerenIllinois from '../components/subsites/AmerenIllinois.vue';
import NVEnergy from '../components/subsites/NVEnergy.vue';

import IncentiveComparison from '../components/pages/IncentiveComparison.vue';
import SavingsCalculator from '../components/pages/SavingsCalculator.vue';
import EVModelComparison from '../components/pages/EVModelComparison.vue';
import EVChargerComparison from '../components/pages/EVChargerComparison.vue';
import DealershipMap from '../components/pages/DealershipMap.vue';
import AnalyticsPage from '../components/pages/AnalyticsPage.vue';
import ChargerMap from '../components/pages/ChargerMap.vue';
import AMOEVPTable from '../components/pages/AMOEVPTable.vue';
import UpcomingEvents from '../components/pages/UpcomingEvents.vue';
import DrivenSalesPage from '../components/pages/DrivenSalesPage.vue';

Vue.use(VueRouter);

const routes = [
  {
    // Paths "nest", so /driven is the subsite under driventoelectrify.com
    // and paths /analytics, /ev_comparison, etc are pages under /driven
    // redirects at the bottom ensure faulty paths don't just error
    path: '/driven',
    component: DrivenRootSite,
    children: [
      {
        path: 'charger_map',
        name: 'Driven - Charger Map',
        component: ChargerMap,
        meta: {
          title: 'Driven - Charger Map',
        },
      },
      {
        path: 'analytics',
        name: 'Driven - Analytics',
        component: AnalyticsPage,
        meta: {
          title: 'Driven - Analytics',
        },
      },
      {
        path: 'ev_comparison',
        name: 'Driven - EVs',
        props: {
          vehicleType: 'Passenger',
        },
        component: EVModelComparison,
        meta: {
          title: 'Driven - EVs',
        },
      },
      {
        path: 'charger_comparison',
        name: 'Driven - Chargers',
        component: EVChargerComparison,
        meta: {
          title: 'Driven - Chargers',
        },
      },
      {
        path: 'savings_calculator',
        name: 'Driven - Savings',
        component: SavingsCalculator,
        meta: {
          title: 'Driven - Savings',
        },
      },
      {
        path: 'incentives',
        name: 'Driven - Incentives',
        component: IncentiveComparison,
        meta: {
          title: 'Driven - Incentives',
        },
      },
      // {
      //   path: 'home',
      //   name: 'Driven - Home',
      //   component: DrivenHomePage,
      //   meta: {
      //     title: 'Driven - Home',
      //   },
      // },
      {
        path: 'dealers',
        name: 'Driven - Dealers',
        component: DealershipMap,
        meta: {
          title: 'Driven - Dealers',
        },
      },
      {
        path: 'sales',
        name: 'Driven - Sales',
        component: DrivenSalesPage,
        meta: {
          title: 'Driven - Sales',
        },
      },
      {
        path: '/',
        redirect: '/driven/sales',
      },
      {
        path: ':wrong',
        redirect: '/driven',
      },
    ],
  },
  {
    // Paths "nest", so /driven is the subsite under driventoelectrify.com
    // and paths /analytics, /ev_comparison, etc are pages under /driven
    // redirects at the bottom ensure faulty paths don't just error
    path: '/pacificpower',
    component: PacificPower,
    children: [
      {
        path: 'charger_map',
        name: 'PacificPower - Charger Map',
        component: ChargerMap,
        meta: {
          title: 'PacificPower - Charger Map',
        },
      },
      {
        path: 'analytics',
        name: 'PacificPower - Analytics',
        component: AnalyticsPage,
        meta: {
          title: 'PacificPower - Analytics',
        },
      },
      {
        path: 'ev_comparison',
        name: 'PacificPower - EVs',
        props: {
          vehicleType: 'Passenger',
        },
        component: EVModelComparison,
        meta: {
          title: 'PacificPower - EVs',
        },
      },
      {
        path: 'charger_comparison',
        name: 'PacificPower - Chargers',
        component: EVChargerComparison,
        meta: {
          title: 'PacificPower - Chargers',
        },
      },
      {
        path: 'savings_calculator',
        name: 'PacificPower - Savings',
        component: SavingsCalculator,
        meta: {
          title: 'PacificPower - Savings',
        },
      },
      {
        path: 'incentives',
        name: 'PacificPower - Incentives',
        component: IncentiveComparison,
        meta: {
          title: 'PacificPower - Incentives',
        },
      },
      {
        path: 'dealers',
        name: 'PacificPower - Dealers',
        component: DealershipMap,
        meta: {
          title: 'PacificPower - Dealers',
        },
      },
      {
        path: '/',
        redirect: '/pacificpower/ev_comparison',
      },
      {
        path: ':wrong',
        redirect: '/pacificpower',
      },
    ],
  },
  {
    path: '/marincounty',
    component: MarinCounty,
    children: [
      {
        path: 'analytics',
        name: 'Marin County - Analytics',
        component: AnalyticsPage,
        meta: {
          title: 'Marin County - Analytics',
        },
      },
      {
        path: 'ev_comparison',
        name: 'Marin County - EVs',
        component: EVModelComparison,
        props: {
          vehicleType: 'Passenger',
        },
        meta: {
          title: 'Marin County - EVs',
        },
      },
      {
        path: 'charger_comparison',
        name: 'Marin County - Chargers',
        component: EVChargerComparison,
        meta: {
          title: 'Marin County - Chargers',
        },
      },
      {
        path: 'savings_calculator',
        name: 'Marin County - Savings',
        component: SavingsCalculator,
        meta: {
          title: 'Marin County - Savings',
        },
      },
      {
        path: 'incentives',
        name: 'Marin County - Incentives',
        component: IncentiveComparison,
        meta: {
          title: 'Marin County - Incentives',
        },
      },
      {
        // No home page. Redirect to EV Comparison Tool.
        path: '/',
        redirect: '/marincounty/ev_comparison',
      },
      {
        path: ':wrong',
        redirect: '/marincounty',
      },
    ],
  },
  {
    path: '/amerenmissouri',
    redirect: '/',
    component: AmerenMissouri,
    children: [
      {
        path: 'analytics',
        name: 'Ameren MO - Analytics',
        component: AnalyticsPage,
        meta: {
          title: 'Ameren MO - Analytics',
        },
      },
      {
        path: 'electric_vehicle_partners',
        name: 'Ameren MO - Electric Vehicle Partners',
        component: AMOEVPTable,
        meta: {
          title: 'Ameren MO - Electric Vehicle Partners',
        },
      },
      // {
      //   path: 'ev_comparison',
      //   name: 'Ameren MO - Commercial EVs',
      //   component: EVModelComparison,
      //   props: {
      //     vehicleType: 'Passenger',
      //   },
      //   meta: {
      //     title: 'Ameren MO - Commercial EVs',
      //   },
      // },
      // {
      //   path: 'ev_comparison_commercial',
      //   name: 'Ameren MO - Passenger EVs',
      //   component: EVModelComparison,
      //   props: {
      //     vehicleType: 'Commercial',
      //   },
      //   meta: {
      //     title: 'Ameren MO - Passenger EVs',
      //   },
      // },
      // {
      //   path: 'charger_map',
      //   name: 'Ameren MO - Charger Map',
      //   component: ChargerMap,
      //   meta: {
      //     title: 'Ameren MO - Charger Map',
      //   },
      // },
      // {
      //   path: 'charger_comparison',
      //   name: 'Ameren MO - Chargers',
      //   component: EVChargerComparison,
      //   meta: {
      //     title: 'Ameren MO - Chargers',
      //   },
      // },
      // {
      //   path: 'savings_calculator',
      //   name: 'Ameren MO - Savings',
      //   component: SavingsCalculator,
      //   meta: {
      //     title: 'Ameren MO - Savings',
      //   },
      // },
      {
        path: '/',
        redirect: '/amerenmissouri/electric_vehicle_partners',
      },
      {
        path: ':wrong',
        redirect: '/amerenmissouri',
      },
    ],
  },
  {
    path: '/amerenillinois',
    redirect: '/',
    component: AmerenIllinois,
    children: [
      {
        path: 'analytics',
        name: 'Ameren IL - Analytics',
        component: AnalyticsPage,
        meta: {
          title: 'Ameren IL - Analytics',
        },
      },
      {
        path: 'ev_comparison',
        name: 'Ameren IL - Passenger EVs',
        component: EVModelComparison,
        props: {
          vehicleType: 'Passenger',
        },
        meta: {
          title: 'Ameren IL - Passenger EVs',
        },
      },
      {
        path: 'ev_comparison_commercial',
        name: 'Ameren IL - Commercial EVs',
        component: EVModelComparison,
        props: {
          vehicleType: 'Commercial',
        },
        meta: {
          title: 'Ameren IL - Commercial EVs',
        },
      },
      {
        path: 'charger_map',
        name: 'Ameren IL - Charger Map',
        component: ChargerMap,
        meta: {
          title: 'Ameren IL - Charger Map',
        },
      },
      {
        path: 'charger_comparison',
        name: 'Ameren IL - Chargers',
        component: EVChargerComparison,
        meta: {
          title: 'Ameren IL - Chargers',
        },
      },
      {
        path: 'savings_calculator',
        name: 'Ameren IL - Savings',
        component: SavingsCalculator,
        meta: {
          title: 'Ameren IL - Savings',
        },
      },
      {
        path: 'dealers',
        name: 'Ameren IL - Dealers',
        component: DealershipMap,
        meta: {
          title: 'Ameren IL - Dealers',
        },
      },
      {
        path: '/',
        redirect: '/amerenillinois/ev_comparison',
      },
      {
        path: ':wrong',
        redirect: '/amerenillinois',
      },
    ],
  },
  {
    path: '/nvenergy',
    component: NVEnergy,
    children: [
      {
        path: 'analytics',
        name: 'NVEnergy Analytics',
        component: AnalyticsPage,
      },
      {
        path: 'events',
        name: 'NVEnergy_events',
        component: UpcomingEvents,
      },
      {
        path: 'dealers',
        name: 'NVEnergy_dealers',
        component: DealershipMap,
      },
      {
        path: '/',
        redirect: '/nvenergy/dealers',
      },
      {
        path: '*',
        redirect: '/nvenergy/dealers',
      },
    ],
  },
  {
    path: '/:p',
    // Redirects to /driven subsite, which will match /ev_comparison, etc.
    // If :p is not a path on the driven subsite, the wildcard will route to driven homepage
    redirect: (to) => ({ path: `/driven/${to.params.p}` }),
  },
  {
    path: '*',
    redirect: '/driven',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // linkActiveClass: 'active-link',
  // linkExactActiveClass: 'exact-active-link',
});

Vue.use(VueGtag, {
  config: {
    id: 'G-CSGHKTTWJT',
    params: {
      // Disables logging initial page load... Not sure why I'd want this. Disabling for now.
      // send_page_view: false
    },
  },
  // Loaded directly in index html so as to be present everywhere.
  disableScriptLoad: true,
}, router);

export default router;
