<template>
  <!-- eslint-disable max-len -->
  <div id="app">
    <!-- Opaque layer over the application, z-index set to sit under nav bar but over content -->
    <!-- Lightly blocks off using the tools to push folks towards demoing. Easily bypassed with browser tools. -->
    <!-- Skip displaying on sales page. This is brittle if you change sales page url, you must change this too. -->
    <div v-if="$route.path !== '/driven/sales'">
      <div style="background-color: white; z-index: 1; opacity: 0.5; height: 100vh; width: 100%; position: fixed;">
      </div>
    </div>
    <button class="buttonStyleAccentDark"
      style="position: fixed; top: 113px; right: 0px; z-index: 10; width: auto; font-size: 160%; height: auto; border-width: 2px;"
      @click="changeRoute('https://survey.zohopublic.com/zs/FLD4TT')"
      > Schedule Demo
    </button>
    <div id="page-container">
      <!-- <span style="position: fixed; top: 40px; right: 10px; z-index: 10; font-size: 150%; color: var(--accent);">[[DEMO SITE]]</span> -->
      <header>
        <nav>
          <div>
            <a href="https://thedrivenexperience.com" target="_blank">
<!--               <img alt="Driven" style="height:90px; margin: 10px;"
                src="../../assets/driven_logo.png"
                /> -->
              <img alt="Driven" style=""
                src="../../assets/Logo_Driven_Favicon.png"
                />
            </a>
          </div>
          <div class="navbar" v-if="! $globalObservableData.showNavDropown">
            <!-- [beta] -->
            <router-link to="/driven/sales">Our Products</router-link>
            <router-link to="/driven/ev_comparison">Compare EVs</router-link>
            <!-- <router-link to="/driven/charger_comparison">Compare Chargers</router-link> -->
            <router-link to="/driven/savings_calculator">Savings</router-link>
            <!-- <router-link to="/driven/incentives">Find Incentives</router-link> -->
            <router-link to="/driven/dealers">Dealers</router-link>
            <!-- <router-link to="/driven/home">EV Education</router-link> -->
          </div>
          <div v-else>
            <b-dropdown right no-caret class="mobileNavDropdown">
              <template #button-content>
                <font-awesome-icon  icon="fa-solid fa-bars"/>
              </template>
              <b-dropdown-item> <router-link to="/driven/home">Home</router-link> </b-dropdown-item>
              <b-dropdown-item> <router-link to="/driven/sales">Our Products</router-link> </b-dropdown-item>
              <b-dropdown-item> <router-link to="/driven/ev_comparison">Compare EVs</router-link> </b-dropdown-item>
              <!-- <b-dropdown-item> <router-link to="/driven/charger_comparison">Compare Chargers</router-link> </b-dropdown-item> -->
              <b-dropdown-item> <router-link to="/driven/savings_calculator">Savings</router-link> </b-dropdown-item>
              <!-- <b-dropdown-item> <router-link to="/driven/incentives">Find Incentives</router-link></b-dropdown-item> -->
              <b-dropdown-item> <router-link to="/driven/dealers">Dealers</router-link></b-dropdown-item>
            </b-dropdown>
          </div>
        </nav>
      </header>
      <main>
        <router-view/>
      </main>
      <footer id="footer">
        <div style="text-align: center;">
          <a href="https://thedrivenexperience.com" target="_blank">
            <img alt="Driven" style="width: 330px; filter: brightness(0) invert(1); vertical-align: top; margin-right: 40px;"
              src="../../assets/TagLine_Driven_Clr.png"
              />
          </a>
          <div class="navbar" style="text-align: left;">
            <!-- <div><router-link to="/driven/home">Home</router-link></div> -->
            <div><router-link to="/driven/sales">Our Products</router-link></div>
            <div><router-link to="/driven/ev_comparison">Compare EVs</router-link></div>
            <!-- <div><router-link to="/driven/charger_comparison">Compare Chargers</router-link></div> -->
            <div><router-link to="/driven/savings_calculator">Savings</router-link></div>
            <!-- <div><router-link to="/driven/incentives">Find Incentives</router-link></div> -->
            <div><router-link to="/driven/dealers">Dealers</router-link></div>
          </div>
<!--           <div style="display: inline-block; vertical-align: top; float: right; font-size: 80%; width: min(100%, 500px);">
            Your custom footer text and links go here. <br>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
          </div> -->
        </div>
<!--         <div><hr style="background: none;"></div>
        <div style="text-align: center;">
          <a href="https://www.thedrivenexperience.com" target="_blank">
            <img alt="Driven"
              src="../../assets/TagLine_Driven_Clr.png"
              style="height: 40px; margin:10px" />
          </a>
        </div> -->
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // Income Limits
      // AMI -- https://www.marinhousing.org/marin-county-income-limits
      household_size_ami: [
        122500,
        140000,
        157500,
        175000,
        189000,
        203000,
        217000,
        231000,
        245000,
      ],
      // SMI -- https://www.cde.ca.gov/sp/cd/ci/mb2205.asp unrelated program but only place I found SMI by household size chart
      household_size_smi: [
        84818,
        84818,
        96590,
        112105,
        130042,
        147988,
        151342,
        154705,
        158068,
        161431,
        164794,
        168158,
      ],
      // FPL -- https://aspe.hhs.gov/topics/poverty-economic-mobility/poverty-guidelines
      household_size_fpl: [
        14580,
        19720,
        24860,
        30000,
        35140,
        40280,
        45420,
        50560,
      ],
      // https://www.energysage.com/local-data/electricity-cost/ca/marin-county/#:~:text=Electric%20rates%20in%20Marin%20County%2C%20CA,-The%20easiest%20way&text=The%20average%20residential%20electricity%20rate,rate%20of%2018%20%C2%A2%2FkWh.
      dollars_per_kwh: 0.31,
      dollars_per_gallon: 5.20,
      client_api_path: 'driven',
      ev_charger_description: '',
      ev_data: [],
      // ev_comparison_description: '* As you’re browsing through, consider <a href="https://www.mcecleanenergy.org/ev-rebate/"  target="_blank">MCE’s EV rebate</a> of up to $3,500 at participating dealerships.',
      emissions_per_kwh: {
        // EPA link ^
        'National Average': 4.33 * (10 ** -4), // metric tons CO2/kWh
        // https://www.energy.ca.gov/media/7746 units = lbs CO2e/MWh -- conversion factor = 4.53592 * (10 ** -7)
        'Pacific Gas and Electric Company - Base Plan': 98 * 4.53592 * (10 ** -7),
        'Coal Only': 0.000512,
        'Renewable Only': 0,
      },
      center: { lat: 40, lng: -100 },
      zoom: 4,
    };
  },
  mounted() {
  },
  methods: {
  },
  created() {
    // Load EV Data
    this.$parent.getEVData();
    this.$parent.getChargerData();
    this.$parent.getIncentiveData();
  },
};
</script>

<style scoped>

#app {
  --light-accent: #e1eef0;
  --accent: rgb(38, 94, 110);
/*  --client-color: rgb(136, 189, 197);
  --client-color-darker: rgb(38, 94, 110);
  --client-color-selected: rgb(7, 119, 207);
  --client-color-red: #b45340;
  --client-color-savings-icons: #989f63;*/
}
</style>
