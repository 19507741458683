<template>
  <!-- eslint-disable max-len -->
  <div id="app">
    <div id="page-container">
      <div>
        <header>
        <nav>
          <div>
            <a href="https://www.nvenergy.com/cleanenergy/electric-vehicles">
              <img alt="NVEnergy"
                src="../../assets/PowerShift_Clr_Logo.png"
                />
            </a>
          </div>
          <div class="navbar"> NV Energy EV Tools </div>
<!--           <div class="navbar" v-if="! $globalObservableData.showNavDropown">
            <router-link to="/nvenergy/events">Events</router-link>
            <router-link to="/nvenergy/dealership_map">Dealership Map</router-link>
          </div>
          <div v-else>
            <b-dropdown no-caret class="mobileNavDropdown">
              <template #button-content>
                <font-awesome-icon  icon="fa-solid fa-bars"/>
              </template>
              <b-dropdown-item> <router-link to="/nvenergy/events">Events</router-link> </b-dropdown-item>
              <b-dropdown-item> <router-link to="/nvenergy/dealership_map">Dealership Map</router-link> </b-dropdown-item>
            </b-dropdown>
          </div> -->
        </nav>
        </header>

        <main>
          <router-view/>
        </main>
      </div>
      <footer id="footer">
        <a href="https://www.thedrivenexperience.com">
          <img alt="Driven"
            src="../../assets/TagLine_Driven_Clr.png"
            style="height: 30px;" />
            <!-- TODO add powered by driven logo to footer -->
        </a>
        <div>
          EV Tools Powered by Driven, last updated 5/30/2023. Find out more at
          <a href="https://thedrivenexperience.com" target="_blank">The Driven Experience</a>.
        </div>
      </footer>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      client_api_path: 'nvenergy',
      event_page_description: 'Welcome to the NV Energy electric vehicle events page! Here, you\'ll find all the latest information on electric vehicle events taking place in and around Nevada. Our aim is to help EV enthusiasts stay informed about upcoming events, including everything from ride-and-drives and car shows, to seminars and workshops, and more. By providing a centralized resource for electric vehicle events, we hope to promote the benefits of electric transportation and help accelerate the transition to sustainable energy. Whether you\'re a seasoned EV driver or simply interested in learning more, our website is the place to be for all the latest EV event news in Nevada.',
      dealer_partner_description: 'You can also toggle between viewing only PowerShift Dealer Partners or "All Dealers". The NV Energy PowerShift Dealer Partner Program works closely with local EV dealers to provide the latest EV resources to all those looking to purchase an EV. NV Energy PowerShift Dealer Partners are indicated by the green icons on the map and by the PowerShift logo in the list below.',
    };
  },
  mounted() {
  },
  methods: {
  },
  created() {
  },
};
</script>
<style scoped>
#app {
  /* green */
  --client-color: #D83738;
  /* grey */
  --client-color-darker: #404643;
}

/*.google_translate {
  position: relative;
  right: 10px;
  top: 10px;
  z-index: 2;
}*/
</style>
