<template>
<!-- eslint-disable max-len -->
  <div>
    <div id="content-wrap">
  <!--     <div>
        <h1 class="pageTitle" id="title" role="banner">
          Calculate Savings
        </h1>
      </div>
      <h2 class="subheader">
        Compare the costs of fueling electric and gas vehicles.
        <br><br>
        To start, choose any EV from the drop down list.
        <ul>
          <li>
           This sets miles per kWh efficiency. Adjust the vehicle efficiency and fuel prices as needed.
          </li>
          <li>
            Make sure to update the miles driven weekly to fit your driving habits.
          </li>
        </ul>
          Then, the calculations below adjust to your inputs.
        <ul>
          <li>
            <a href="#dollar-savings">Compare costs and savings</a> over weeks, months, and years of driving.
          </li>
          <li>
            <a href="#emissions-savings">Compare CO2 emissions</a> and potential savings over time.
          </li>
        </ul>
        <font-awesome-icon icon="fa-solid fa-link" style="margin-left: 10px; margin-right: 10px;"/> Click the "Copy Link" button. Then paste into a message to share your custom view with friends and family.
      </h2>
      <hr class="headerHR"> -->
        <div style="align-content: center; height: auto; margin: 0 0 20px 0;">
          <span class="subheader" style="font-size: 20px; color: var(--dark-grey); font-weight: 600; float: left; margin-top: 15px;">
            <!-- Compare SavingsChoose Parameters -->
          </span>
          <button class="buttonStyleAccent"
            id="copy-link"
            aria-label="copy link"
            @click="copyLink()"
            style="margin-right: 0; float:right">
            <font-awesome-icon icon="fa-solid fa-link" />
            Copy Link
          </button>
        </div>
      <div class="controlPanelItemListWrapper">
        <div class="controlPanel"
          role="form">
          <!-- <div class="controlPanelColumn"> -->
          <div style="margin-bottom: 10px;" class="standard"> Electric Vehicle </div>
          <select v-model="state.filterMake" @change="logMessage('info', 'Filter Make', state.filterMake)"
            aria-label="filter make"
            name="make-model"
            id="make-model"
            class="round">
            <option value="" selected>Select Make</option>
            <!-- Dynamically compute options from the data. Automatically updates if options are added/removed from data -->
            <option v-for="(val, index) in $parent.$parent.ev_data.filter(car => car.type === 'Passenger').map(
                (item) => item.make
              ).filter(
                (value, index, current_value) => current_value.indexOf(value) === index).sort()" :key="index"> {{val}}
            </option>
          </select>
          <select v-model="state.carIndex"
            @change="setVehicle()"
            aria-label="select ev"
            name="car-index"
            id="car-index"
            class="round">
            <option value="-1" selected>Select EV</option>
            <option v-for="(car, index) in getFilteredEVData()" :key="index" :value="car.index"> {{car.make}} {{car.display_name}} ({{car.model_year}}) </option>
          </select>
          <div>
           <!--  <span>
              <span v-if="state.carIndex == -1">Custom</span>
              <span v-else>Selected</span>
              Miles per kWh </span> -->
            <div class="numberInputRight" data-endtext="miles/kWh" style="margin-bottom: 0;">
              <input v-model="milesPerKwhDisplay" @change="logMessage('info', 'Miles per kWh', state.milesPerKwh)"
                :disabled="state.carIndex != -1"
                aria-label="miles per kWh"
                type="number"
                step="0.01"
                min="0.00"
                max="1000.00"
                class="numberInput">
            </div>
          </div>
          <hr>
          <div>
            <div style="margin-bottom: 10px;" class="standard">  Power Source </div>
            <select v-model="state.powerSourceSelected" @change="setPowerSource();"
              style=""
              aria-label="set emission factor"
              name="set-emissions"
              id="set-emissions"
              class="round">
                <option style="" v-for="(emissionObj, index) in powerSources" :key="index" :value="index"> {{emissionObj.name}}</option>
            </select>
          </div>
          <div style="">
            <!-- <span> Dollars per kWh </span> -->
            <div class="numberInputRight" data-endtext="$/kWh" style="margin-bottom: 0;">
              <input v-model="state.dollarsPerKwh"
                @change="logMessage('info', 'Dollars per kWh', state.dollarsPerKwh)"
                aria-label="dollars per kWh"
                type="number"
                step="0.01"
                min="0.00"
                max="1000.00"
                class="numberInput">
            </div>
          </div>
          <hr>
          <div style="margin-bottom: 10px;" class="standard">  Gas Vehicle </div>
          <!-- <span> Miles per gallon </span> -->
          <div class="numberInputRight" data-endtext="miles/gallon">
            <input v-model="state.milesPerGallon"
              @change="logMessage('info', 'Miles per Gallon', state.milesPerGallon)"
              aria-label="miles per gallon"
              type="number"
              step="0.01"
              min="0.00"
              max="1000.00"
              class="numberInput">
          </div>
          <!-- <span> Dollars per gallon </span> -->
          <div class="numberInputRight" data-endtext="$/gallon" style="margin-bottom: 0;">
            <input v-model="state.dollarsPerGallon"
              @change="logMessage('info', 'Dollars per Gallon', state.dollarsPerGallon)"
              aria-label="dollars per gallon"
              type="number"
              step="0.01"
              min="0.00"
              max="1000.00"
              class="numberInput">
          </div>
          <hr>
          <div style="margin-bottom: 10px;" class="standard"> Miles Driven Weekly </div>
          <div class="numberInputRight" data-endtext="miles/week" style="margin-bottom: 0;">
            <input v-model="state.milesDriven" @change="logMessage('info', 'Miles Driven', state.milesDriven)"
              aria-label="miles driven weekly"
              type="number"
              step="10"
              min="0.00"
              max="10000.00"
              class="numberInput">
          </div>
          <!-- In order to add reset here, I need to write a function that checks parent state -->
          <!-- Other apps with reset so far aren't pulling from parent, so I'm able to just reset to initial data values -->
          <!-- Here, I'll basically wan the "created" function but without url query vals -->
          <!-- I should probable refactor / combine the new func into "created" so I'm setting parent vals in one place -->
          <!-- EG created can call "reset" func and then override with query param vals! -->
          <!-- Once I do this, I should also apply it to reset buttons in cars and chargers -->
          <!-- Upside: should be more durable to change than simply setting state to a json - one fewer places to change when I modify initial state-->
<!--           <button class="buttonStyle"
            @click="TODOOOOOO">
            <font-awesome-icon icon="fa-regular fa-circle-xmark" />
            Reset Filters
          </button> -->
        </div>
        <div class="itemListWrapper">
          <div id="itemList" class="itemList" style="" v-if="getCar() && state">
            <!-- Wrap in array to easily reuse ev comparison code -->
            <div v-for="(car, index) in [getCar()]"
              v-bind:key="index"
              class="itemBlock"
              style="height:auto; width: 100%; text-align: center; display: inline-block;">
              <div>
                <!-- specify width/height and max width heights to force lazy load image placeholder size to not distort display -->
                <!-- Also 100% max width causes image to shrink in proportion with screen size on smaller devices -->
                <!-- Removed v-lazy optimization due to garbage experience on mobile. Downsides of no lazy load: higher api calls and slower initial page load -->
                <div style="display:inline-block; margin: 0; vertical-align: top">
                  <h5>
                    <strong class='right' style="font-size: 100%;" id="car_name">{{car.make}} {{car.model}}</strong>
                    <!-- <div style="display:inline-block; width: 200px; height: 140px;"></div> -->
                  </h5>

                  <div>
                     {{car.trim}} {{car.form_factor}} ({{car.model_year}})
                  </div>
                  <img :src="car.image_url || require('../../assets/dalle_generic_commercial_3.png')" :key="car.image_url" width=auto height=250 style="max-width: 100%; height:auto; max-height: 250px; margin: 0 auto;" :alt="car.display_name + ' ' + car.model_year" />
                </div>
                <div style="display:inline-block; text-align: left">
                  <!-- Button routes to Savings Calculator preset to display this vehicle and use it's efficiency -->
                  <div style="display: flex; justify-content: left;">
                    <button class="buttonStyle" style="width:auto; padding: 0 10px;"
                      @click="changeRoute(car.official_website_url)">
                      <font-awesome-icon icon="fa-solid fa-circle-info" />
                      Learn More
                    </button>
                  </div>
                  <div>
                    <b>MSRP: </b>
                      <span v-if="car.msrp">{{car.msrp | currencyUSDWhole}}</span>
                      <span v-else>Unavailable</span>
                    <br />

                    <div v-if="$attrs.vehicleType === 'Commercial'">
                      <b>Payload: </b>
                      <span>{{car.payload}} lbs</span>
                      <br />
                      <b>Curb Weight: </b>
                      <span>{{car.curb_weight}} lbs</span>
                      <br>
                      <b>GVWR: </b>
                      <span>{{car.gvwr}} lbs</span>
                      <br />
                      <!-- <b>Lift Capacity: </b>
                      <span>{{car.lift_capacity || 0}} lbs</span>
                      <br /> -->
                    </div>

                    <b>Efficiency:</b> <span>{{ car.miles_per_kwh | numberTwoDecimal}} miles/kWh</span>
                    <br />

                    <span><b>Battery Range:</b> {{car.electric_range | numberWhole}} miles</span>
                    <span v-if="car.electric_range !==car.total_range">
                      <br>
                      <b>Total Range:</b> {{car.total_range | numberWhole}} miles
                    </span>
                    <br />

                    <b>Battery Capacity: </b>
                    <span>{{car.battery_capacity}} kWh</span>
                    <br />

                    <!-- <b>Horsepower: </b>
                    <span>{{car.horsepower}}</span>
                    <br />

                    <b>Seats: </b>
                    <span>{{car.seats_max}}</span>
                    <br />

                    <b>Vehicle Type: </b>
                    <span>{{car.form_factor}}</span>
                    <br />

                    <b>Plug Types: </b>
                    <span>{{(car.plug_types).join(', ')}}</span>
                    <br /> -->

                    <!-- Boolean logic since we only display CA incentives for Marin County -->
                    <!-- And also only display "Incentives:" if there are incentives to display... -->
                    <!-- There's definitely a more elegant way to do this, which I'll sort out when we have more state specific incentives to deal with. Maybe these should eventually be handled using linked records like the home incentive calculator -->
                  </div>
                  <div class="vehicleIncentiveBox" v-if="car.eligible_for_usa_tax_credit ||
                    ($parent.client_api_path == 'marincounty' && (car.eligible_for_ca_hov || car.eligible_for_ca_rebate))">
                    <h6>Available Incentives:</h6>
                    <ul style="margin: 0">
                      <!-- <a href='https://www.irs.gov/credits-deductions/credits-for-new-clean-vehicles-purchased-in-2023-or-after' target='_blank'> -->
                      <li v-if="car.eligible_for_usa_tax_credit">Federal Tax Credit: {{car.usa_tax_credit_rebate | currencyUSDWhole}}</li>
                      <!-- Only display these two incentives for Marin County. Can expand if we obtain other CA clients. -->
                      <li v-if="car.eligible_for_ca_hov && $parent.client_api_path == 'marincounty'">Eligible for CA HOV</li>
                      <li v-if="car.eligible_for_ca_rebate && $parent.client_api_path == 'marincounty'">Eligible for CA rebate</li>
                      <!-- <li v-if="car.san_joaquin_valley_ev_rebate">San Joaquin Valley EV Rebate: {{car.san_joaquin_valley_ev_rebate | currencyUSDWhole}}</li> -->
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="itemList">
            <div class="itemBlock">
                <span class="t6">
                  <!-- <font-awesome-icon class="iconMobile" icon="fa-solid fa-gas-pump" style="color:var(--accent); margin-right: 10px;"/> -->
                  <font-awesome-icon class="iconMobile" icon="fa-solid fa-charging-station" style="color:var(--accent)"/>
                  How far does <b>{{state.dollarsPerGallon | currencyUSD}}</b> take your car?
                </span>
                <div class="chartContainer">
                  <div class="cardHeader">
                  </div>
                  <!-- TODO figure out issue with Apex charts preventing clean screen resizing... -->
                  <apex-chart height="400px" width="100%" :options="chartOptionsMiles" :series="getDrivingDistanceSeries()"></apex-chart>
                </div>
                <div class="itemList" style="">
                  <div class="itemBlock" style="">
                    <b>You can drive an EV</b>
                    <div>
                      <!-- Todo source this green from vars, maybe make a new class for this... -->
                      <h5 style="color:var(--accent)">{{
                        (state.dollarsPerGallon / state.dollarsPerKwh) * state.milesPerKwh | numberWhole}} miles
                      </h5>
                    </div>
                    for the price of a gallon of gas
                  </div>
                  <div class="itemBlock" style="">
                    <b>Driving electric takes you</b>
                    <div>
                      <!-- Todo source this green from vars, maybe make a new class for this... -->
                      <h5 style="color:var(--accent)">{{
                        (state.dollarsPerGallon / state.dollarsPerKwh) * state.milesPerKwh / state.milesPerGallon | numberOneDecimal}}× further
                      </h5>
                    </div>
                    for each dollar spent
                  </div>
                </div>
            </div>

            <div class="itemBlock">
              <span class="t6">
                <font-awesome-icon class="iconMobile" icon="fa-solid fa-sack-dollar" style="color:var(--accent)"/>
                How much will you spend on fuel annually driving <b>{{state.milesDriven | numberWhole}}</b> miles per week?
              </span>
              <div class="chartContainer">
                <apex-chart height="400px" width="100%" :options="chartOptionsDollarSavings" :series="getDollarSavingsSeries(52)"></apex-chart>
              </div>
              <div class="itemList">
                <div class="itemBlock" style="">
                  <b>Monthly Savings</b>
                  <div>
                    <h5 style="color:var(--accent)">{{
                      getDrivingCostGasoline(4) - getDrivingEmissionsElectric(4) | currencyUSDWhole
                    }}
                    </h5>
                  </div>
                  <!-- by driving electric -->
                </div>
                <div class="itemBlock" style="">
                  <b>Annual Savings</b>
                  <div>
                    <h5 style="color:var(--accent)">{{
                      getDrivingCostGasoline(52) - getDrivingEmissionsElectric(52) | currencyUSDWhole
                    }}
                    </h5>
                  </div>
                  <!-- saved -->
                </div>
                <div class="itemBlock" style="">
                  <b>Savings Over 5 Years</b>
                  <div>
                    <h5 style="color:var(--accent)">{{
                      getDrivingCostGasoline(52 * 5) - getDrivingEmissionsElectric(52 * 5) | currencyUSDWhole
                    }}
                    </h5>
                  </div>
                  <!-- saved -->
                </div>
              </div>
            </div>

            <div class="itemBlock">
              <span class="t6">
                <font-awesome-icon class="iconMobile" icon="fa-solid fa-leaf" style="color:var(--accent)"/>
                How does driving electric affect your greenhouse gas pollution (CO2)?
              </span>
              <div class="chartContainer">
                <apex-chart height="400px" width="100%" :options="chartOptionsEmissionsSavings" :series="getEmissionsSavingsSeries()"></apex-chart>
              </div>
              <div class="itemList">
                <div class="itemBlock">
                  <b>Driving electric saves</b>
                  <div>
                    <!-- Todo source this green from vars, maybe make a new class for this... -->
                    <h5 style="color:var(--accent)">{{
                      getDrivingEmissionsGasoline(52) - getDrivingEmissionsElectric(52) | numberTwoDecimal
                    }} Tons
                    </h5>
                  </div>
                  of CO2 emissions annually
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- TODOS: Change graph colors to dynamic -- pulling from client branding. Change to bar chart, groups of 3 columns. Add space between copy and chart. Title graph, label axes, brief explainer below? Remove toolbar -->
  <!-- TODO: number inputs overlapping nav bar. Make footer image wayy smaller -->
<!-- eslint-enable max-len -->
</template>

<script>
/* eslint-disable max-len */
/* eslint-disable object-shorthand */
export default {
  computed: {
    // Displaying fixed decimal places without modifying underlying value: https://stackoverflow.com/questions/68036984/how-do-i-display-a-value-as-a-decimal-place-in-vuejs-input-field
    // You can't use arrow functions here for some reason, so disable linting above and use regular function defs: https://stackoverflow.com/questions/50260260/vuejs-variable-is-undefined-inside-computed-only
    milesPerKwhDisplay: {
      get: function () { return this.state.milesPerKwh.toFixed(2); },
      set: function (newValue) { this.state.milesPerKwh = newValue; },
    },
  },
  data() {
    return {
      milesPerKwh: 4.8,
      state: {
        filterMake: '',
        carIndex: -1,
        dollarsPerGallon: 3.75,
        milesPerGallon: 25.4,
        dollarsPerKwh: 0.20,
        milesPerKwh: 4.8,
        milesDriven: 200,
        // https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references
        emissionsPerGallon: 8.89 * (10 ** -3), // 8.89 × 10^-3 metric tons CO2/gallon gas
        emissionsPerKwh: 4.33 * (10 ** -4),
        powerSourceSelected: 0,
      },
      powerSources: [
        {
          name: 'National Average',
          emissionsPerKwh: 4.33 * (10 ** -4), // metric tons CO2/kWh
          dollarsPerKwh: 0.10,
        },
        {
          name: 'Coal Only',
          emissionsPerKwh: 0.000512,
          dollarsPerKwh: 0.20,
        },
        {
          name: 'Renewable Only',
          emissionsPerKwh: 0,
          dollarsPerKwh: 0.30,
        },
      ],
      chartOptionsMiles: {
        margin: '100',
        // stroke: {
        //   show: true,
        //   width: 40,
        //   colors: ['transparent'],
        // },
        title: {
          // text: 'Electric vs Gas Fueling Comparison',
          text: undefined,
          align: 'center',
          offsetY: -50,
          margin: 10,
          style: {
            fontSize: '120%',
            fontWeight: 'bold',
            // fontFamily: undefined,
            // color: '#263238',
          },
          // offsetX: 15,
        },
        subtitle: {
          // text: 'Evaluate Costs and Savings over ten years of driving',
          text: undefined,
          align: 'center',
          offsetY: 0,
          style: {
            fontSize: '100%',
            // fontWeight: 'bold',
            // fontFamily: undefined,
            // color: '#263238',
          },
          // offsetX: 15,
        },
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
          parentHeightOffset: 0,
        },
        plotOptions: {
          bar: {
            columnWidth: '60%',
            distributed: true,
            borderRadius: 2,
            horizontal: false,
            dataLabels: {
              // orientation: 'vertical',
              position: 'top', // top, center, bottom
            },
          },
        },
        colors: ['var(--gasoline-brown)', 'var(--electric-blue)'],
        grid: {
          // borderColor: 'black',
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          // horizontal: true,
          enabled: true,
          formatter: (value) => `${this.$options.filters.numberWhole(value)} miles`,
          // textAnchor: 'top',
          // position: 'middle',
          // TODO: figure out why short bars don't have label ABOVE the bar.
          offsetY: -40,
          style: {
            fontSize: '22px',
            colors: ['var(--dark-grey)'],
          },
          // dropShadow: {
          //   enabled: true,
          //   opacity: 1,
          //   color: 'white',
          // },
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: '150%',
          },
        },
        xaxis: {
          axisBorder: {
            show: true,
            color: 'var(--dark-grey)',
            height: 1,
            width: '100%',
            // offsetX: 0,
            // Gets rid of tiny weird gap between bars and axis
            offsetY: -1,
          },
          axisTicks: {
            show: false,
          },
          // title: {
          //   text: 'Miles',
          //   offsetY: 20,
          //   style: {
          //     fontSize: '100%',
          //   },
          // },
          // axisBorder: {
          //   show: true,
          // },
          // There's probably a better way to hide the category labels, but this works
          categories: ['Gasoline', 'Electricity'],
          labels: {
            offsetY: 10,
            show: true,
            style: {
              fontSize: '22px',
              colors: ['#304758'],
            },
          },
        },
        yaxis: {
          show: false,
          labels: {
            style: {
              fontSize: '80%',
              colors: ['#304758'],
            },
            // formatter: (value) => `$${value.toFixed(2)}`,
            formatter: (value) => `${this.$options.filters.numberWhole(value)} miles`,
          },
        },
        legend: {
          show: false,
          fontSize: '22px',
          position: 'bottom',
          offsetX: 30,
          // offsetY: -10,
        },
      },
      chartOptionsDollarSavings: {
        margin: '100',
        // stroke: {
        //   show: true,
        //   width: 40,
        //   colors: ['transparent'],
        // },
        title: {
          // text: 'Electric vs Gas Fueling Comparison',
          text: undefined,
          align: 'center',
          offsetY: -50,
          margin: 10,
          style: {
            fontSize: '120%',
            fontWeight: 'bold',
            // fontFamily: undefined,
            // color: '#263238',
          },
          // offsetX: 15,
        },
        subtitle: {
          // text: 'Evaluate Costs and Savings over ten years of driving',
          text: undefined,
          align: 'center',
          offsetY: 0,
          style: {
            fontSize: '100%',
            // fontWeight: 'bold',
            // fontFamily: undefined,
            // color: '#263238',
          },
          // offsetX: 15,
        },
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
          parentHeightOffset: 0,
        },
        plotOptions: {
          bar: {
            columnWidth: '60%',
            distributed: true,
            borderRadius: 2,
            horizontal: false,
            dataLabels: {
              // orientation: 'vertical',
              position: 'top', // top, center, bottom
            },
          },
        },
        colors: ['var(--gasoline-brown)', 'var(--electric-blue)'],
        grid: {
          // borderColor: 'black',
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          // horizontal: true,
          enabled: true,
          formatter: (value) => this.$options.filters.currencyUSDWhole(value),
          // textAnchor: 'top',
          // position: 'middle',
          // TODO: figure out why short bars don't have label ABOVE the bar.
          offsetY: -40,
          style: {
            fontSize: '22px',
            colors: ['var(--dark-grey)'],
          },
          // dropShadow: {
          //   enabled: true,
          //   opacity: 1,
          //   color: 'white',
          // },
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: '150%',
          },
        },
        xaxis: {
          axisBorder: {
            show: true,
            color: 'var(--dark-grey)',
            height: 1,
            width: '100%',
            // offsetX: 0,
            // Gets rid of tiny weird gap between bars and axis
            offsetY: -1,
          },
          axisTicks: {
            show: false,
          },
          // title: {
          //   text: 'Miles',
          //   offsetY: 20,
          //   style: {
          //     fontSize: '100%',
          //   },
          // },
          // axisBorder: {
          //   show: true,
          // },
          // There's probably a better way to hide the category labels, but this works
          categories: ['Gasoline', 'Electricity'],
          labels: {
            offsetY: 10,
            show: true,
            style: {
              fontSize: '22px',
              colors: ['#304758'],
            },
          },
        },
        yaxis: {
          show: false,
          labels: {
            style: {
              fontSize: '80%',
              colors: ['#304758'],
            },
            // formatter: (value) => `$${value.toFixed(2)}`,
            formatter: (value) => this.$options.filters.currencyUSDWhole(value),
          },
        },
        legend: {
          show: false,
          fontSize: '22px',
          position: 'bottom',
          offsetX: 30,
          // offsetY: -10,
        },
      },
      chartOptionsEmissionsSavings: {
        margin: '100',
        // stroke: {
        //   show: true,
        //   width: 40,
        //   colors: ['transparent'],
        // },
        title: {
          // text: 'Electric vs Gas Fueling Comparison',
          text: undefined,
          align: 'center',
          offsetY: -50,
          margin: 10,
          style: {
            fontSize: '120%',
            fontWeight: 'bold',
            // fontFamily: undefined,
            // color: '#263238',
          },
          // offsetX: 15,
        },
        subtitle: {
          // text: 'Evaluate Costs and Savings over ten years of driving',
          text: undefined,
          align: 'center',
          offsetY: 0,
          style: {
            fontSize: '100%',
            // fontWeight: 'bold',
            // fontFamily: undefined,
            // color: '#263238',
          },
          // offsetX: 15,
        },
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
          parentHeightOffset: 0,
        },
        plotOptions: {
          bar: {
            columnWidth: '60%',
            distributed: true,
            borderRadius: 2,
            horizontal: false,
            dataLabels: {
              // orientation: 'vertical',
              position: 'top', // top, center, bottom
            },
          },
        },
        colors: ['var(--gasoline-brown)', 'var(--electric-blue)'],
        grid: {
          // borderColor: 'black',
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          // horizontal: true,
          enabled: true,
          formatter: (value) => `${this.$options.filters.numberTwoDecimal(value)} Tons`,
          // textAnchor: 'top',
          // position: 'middle',
          // TODO: figure out why short bars don't have label ABOVE the bar.
          offsetY: -40,
          style: {
            fontSize: '22px',
            colors: ['var(--dark-grey)'],
          },
          // dropShadow: {
          //   enabled: true,
          //   opacity: 1,
          //   color: 'white',
          // },
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: '150%',
          },
        },
        xaxis: {
          axisBorder: {
            show: true,
            color: 'var(--dark-grey)',
            height: 1,
            width: '100%',
            // offsetX: 0,
            // Gets rid of tiny weird gap between bars and axis
            offsetY: -1,
          },
          axisTicks: {
            show: false,
          },
          // title: {
          //   text: 'Miles',
          //   offsetY: 20,
          //   style: {
          //     fontSize: '100%',
          //   },
          // },
          // axisBorder: {
          //   show: true,
          // },
          // There's probably a better way to hide the category labels, but this works
          categories: ['Gasoline', 'Electricity'],
          labels: {
            offsetY: 10,
            show: true,
            style: {
              fontSize: '22px',
              colors: ['#304758'],
            },
          },
        },
        yaxis: {
          show: false,
          labels: {
            style: {
              fontSize: '80%',
              colors: ['#304758'],
            },
            // formatter: (value) => `$${value.toFixed(2)}`,
            formatter: (value) => `${this.$options.filters.numberTwoDecimal(value)} Tons`,
          },
        },
        legend: {
          show: false,
          fontSize: '22px',
          position: 'bottom',
          offsetX: 30,
          // offsetY: -10,
        },
      },
    };
  },
  mounted() {
  },
  methods: {
    copyLink() {
      const queries = [];
      if (this.state.filterMake) {
        queries.push(`filterMake=${this.state.filterMake}`);
      }
      if (this.state.carIndex) {
        queries.push(`carIndex=${this.state.carIndex}`);
      }
      if (this.state.dollarsPerGallon) {
        queries.push(`dollarsPerGallon=${this.state.dollarsPerGallon}`);
      }
      if (this.state.milesPerGallon) {
        queries.push(`milesPerGallon=${this.state.milesPerGallon}`);
      }
      if (this.state.dollarsPerKwh) {
        queries.push(`dollarsPerKwh=${this.state.dollarsPerKwh}`);
      }
      if (this.state.milesPerKwh) {
        queries.push(`milesPerKwh=${this.state.milesPerKwh}`);
      }
      if (this.state.milesDriven) {
        queries.push(`milesDriven=${this.state.milesDriven}`);
      }
      if (this.state.powerSourceSelected) {
        queries.push(`powerSourceSelected=${this.state.powerSourceSelected}`);
      }
      const path = `${[window.location.origin, this.$parent.client_api_path, 'savings_calculator?'].join('/').replaceAll('//', '/')}${queries.join('&')}`;
      navigator.clipboard.writeText(path);
      this.logMessage('info', 'Copy Link', path);

      return path;
    },
    setVehicle() {
      // Handles logging and also setting make when a vehicle is selected.
      this.logMessage('info', 'Select electric vehicle', this.state.carIndex);
      const car = this.getCar();
      if (car && car.make) {
        this.state.filterMake = car.make;
      }
    },
    setPowerSource() {
      // console.log('SETTING POWER SOURCE!!!', this.powerSources, this.state.powerSourceSelected);
      // logMessage('info', 'Set Emission Factor', state.powerSourceSelected);
      // this.state.powerSourceSelected = this.state.powerSourceSelected.emissionsPerKwh;
      // this.state.dollarsPerKwh = this.state.powerSourceSelected.dollarsPerKwh;
      this.state.emissionsPerKwh = this.powerSources[this.state.powerSourceSelected].emissionsPerKwh;
      this.state.dollarsPerKwh = this.powerSources[this.state.powerSourceSelected].dollarsPerKwh;
    },
    getDrivingDistanceSeries() {
      const series = [{
        data: [
          this.state.milesPerGallon,
          (this.state.dollarsPerGallon / this.state.dollarsPerKwh) * this.state.milesPerKwh,
        ],
        name: 'Distance',
      },
      ];
      return series;
    },
    getDollarSavingsSeries(weeks) {
      const series = [{
        data: [
          this.getDrivingCostGasoline(weeks),
          this.getDrivingCostElectric(weeks),
        ],
        name: 'Cost',
      },
      ];
      return series;
    },
    getEmissionsSavingsSeries() {
      const series = [{
        data: [
          this.getDrivingEmissionsGasoline(52),
          this.getDrivingEmissionsElectric(52),
        ],
        name: 'Emissions',
      },
      // {
      //   data: [
      //     this.getDrivingEmissionsGasoline(52) - this.getDrivingEmissionsElectric(52),
      //     this.getDrivingEmissionsGasoline(52 * 2) - this.getDrivingEmissionsElectric(52 * 2),
      //     this.getDrivingEmissionsGasoline(52 * 5) - this.getDrivingEmissionsElectric(52 * 5),
      //     this.getDrivingEmissionsGasoline(52 * 10) - this.getDrivingEmissionsElectric(52 * 10),
      //   ],
      //   name: 'Savings',
      // },
      ];
      return series;
    },
    getDrivingCostGasoline(numWeeks) {
      const cost = (numWeeks * this.state.milesDriven * this.state.dollarsPerGallon) / this.state.milesPerGallon;
      return cost;
    },
    getDrivingCostElectric(numWeeks) {
      const cost = (numWeeks * this.state.milesDriven * this.state.dollarsPerKwh) / this.state.milesPerKwh;
      return cost;
    },
    getDrivingEmissionsGasoline(numWeeks) {
      const emissions = (numWeeks * this.state.milesDriven * this.state.emissionsPerGallon) / this.state.milesPerGallon;
      return emissions;
    },
    getDrivingEmissionsElectric(numWeeks) {
      // return numWeeks;
      // console.log(this.emissionsPerKwh, this.state.powerSourceSelected);
      const emissions = (numWeeks * this.state.milesDriven * this.state.emissionsPerKwh) / this.state.milesPerKwh;
      return emissions;
    },
    getCar() {
      if (this.$parent.$parent.ev_data && this.state.carIndex > -1) {
        const car = this.$parent.$parent.ev_data[this.state.carIndex];
        // check here prevents error while car is loading
        if (car) {
          this.state.milesPerKwh = car.miles_per_kwh;
        }
        return car;
      }
      return null;
    },
    // TODO refactor this so the filter stuff is only in one place? eg App.vue
    getFilteredEVData() {
      // Filter
      const filtered = this.$parent.$parent.ev_data.filter((car) => this.passesFilters(car));
      // Sort based on sort key and direction
      const sorted = filtered.sort((a, b) => this.state.sortDirection * a[this.state.sortKey]
        - this.state.sortDirection * b[this.state.sortKey]);

      return sorted;
    },
    passesFilters(car) {
      // TODO hardcoded to passenger right now. Refactor to pull from attrs if making a commercial version...
      // console.log(car);
      // if (car.type !== this.$attrs.vehicleType) {
      if (car.type !== 'Passenger') {
        return false;
      }
      if (this.state.filterMake && car.make !== this.state.filterMake) {
        return false;
      }

      return true;
    },
  },
  created() {
    this.state.filterMake = this.$route.query.filterMake || this.state.filterMake;
    this.state.carIndex = Number(this.$route.query.carIndex) || -1;

    this.state.dollarsPerGallon = Number(this.$route.query.dollarsPerGallon || this.$parent.state.dollarsPerGallon || this.state.dollarsPerGallon);
    this.state.milesPerGallon = Number(this.$route.query.milesPerGallon || this.state.milesPerGallon);
    this.state.dollarsPerKwh = Number(this.$route.query.dollarsPerKwh
      || this.$parent.state.dollarsPerKwh || this.state.dollarsPerKwh);
    this.state.milesPerKwh = Number(this.$route.query.milesPerKwh || this.state.milesPerKwh);
    this.state.emissionsPerKwh = Number(this.$route.query.emissionsPerKwh || this.state.emissionsPerKwh);

    this.state.milesDriven = Number(this.$route.query.milesDriven || this.state.milesDriven);

    this.state.powerSourceSelected = this.$route.query.powerSourceSelected || this.state.powerSourceSelected;

    // Defaults if not specified on parent
    this.powerSources = this.$parent.powerSources;

    // console.log(this);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.iconMobile {
  margin-right: 20px;
}

.controlPanelItemListWrapper {
  width: 100%;
}

.itemListWrapper {
  width: 100%;
}
.itemList {
  width:100%;
/*  padding-left: 20px;*/
}
.itemBlock {
  width: 100%;
/*  margin-right: 200px;*/
/*  box-shadow: none;*/
/*  font-size: 120%;*/
/*  vertical-align: middle;*/
/*  display: inline-block;*/
/*  margin-left: 40px*/
  margin-bottom: 40px;
}
.itemBlock .itemList {
  display: block;
  width: 300px;
  float: right;
}
.itemBlock .itemBlock {
  min-width: 250px;
  max-width: 250px;
  margin: 20px;
  float: right;
  text-align: center;
  display: block;
  /* Note: if you want to keep the grey background, you'll need to darken the green text color for accesibility */
  background-color: white;
}

.cardHeader {
/*  font-size: 120%;*/
}

.chartContainer {
/*  min-width: 500px;*/
/*  max-width: 100%;*/
width: calc(100% - 300px);
float: left;
}

</style>
