<template>
  <!-- eslint-disable max-len -->
  <div id="app">
    <div id="page-container">
      <header>
        <nav>
          <div>
            <a href="https://www.ameren.com/missouri/residential/electric-vehicles" target="_blank">
              <img alt="Ameren"
                src="../../assets/Ameren_MO_logo.png"
                />
            </a>
          </div>
<!--           <button class="buttonStyleAccentDark"
            style="padding: 0 10px; margin: 0; margin-right: 100px; align-self: center;"
            @click="changeRoute('https://www.ameren.com/missouri/business/electric-vehicles')">
            ← Back to Electric Vehicles
          </button> -->
          <!-- <h4 style="color:var(--accent); vertical-align: center;"> Find an Electrical Contractor Partner </h4> -->
          <div class="navbar" v-if="! $globalObservableData.showNavDropown">
            <!-- <router-link to="/amerenmissouri/home">Home</router-link> -->
            <!-- <router-link to="/amerenmissouri/ev_comparison">Compare EVs</router-link> -->
            <!-- <router-link to="/amerenmissouri/ev_comparison_commercial">Fleet EVs</router-link> -->
            <!-- <router-link to="/amerenmissouri/charger_comparison">Compare Chargers</router-link>
            <router-link to="/amerenmissouri/savings_calculator">Calculate Savings</router-link>
            <router-link to="/amerenmissouri/charger_map">Find Chargers</router-link>
            <a href="https://www.ameren.com/missouri/business/electric-vehicles/electric-vehicle-partners/car-dealers" target="_blank"> Find Dealerships</a> -->
          </div>
          <div v-else>
            <b-dropdown right no-caret class="mobileNavDropdown">
              <template #button-content>
                <font-awesome-icon  icon="fa-solid fa-bars"/>
              </template>
              <!-- <b-dropdown-item> <router-link to="/amerenmissouri/home">Home</router-link> </b-dropdown-item>  -->
              <!-- <b-dropdown-item> <router-link to="/amerenmissouri/home">Home</router-link> </b-dropdown-item> -->
              <!-- <b-dropdown-item> <router-link to="/amerenmissouri/ev_comparison">Compare EVs</router-link> </b-dropdown-item> -->
              <!-- <b-dropdown-item> <router-link to="/amerenmissouri/ev_comparison_commercial">Fleet EVs</router-link> </b-dropdown-item> -->
              <!-- <b-dropdown-item> <router-link to="/amerenmissouri/charger_comparison">Compare Chargers</router-link> </b-dropdown-item> -->
              <!-- <b-dropdown-item> <router-link to="/amerenmissouri/savings_calculator">Calculate Savings</router-link> </b-dropdown-item> -->
              <!-- <b-dropdown-item> <router-link to="/amerenmissouri/charger_map">Find Chargers</router-link> </b-dropdown-item> -->
              <!-- For some reason <a> link doesn't work within b-dropdown-item, needed to apply href and target to the item itself -->
              <!-- To have working link and styling consistent with the others... -->
              <!-- <b-dropdown-item href="https://www.ameren.com/missouri/business/electric-vehicles/electric-vehicle-partners/car-dealers" target="_blank"><a>Find Dealerships</a></b-dropdown-item> -->
            </b-dropdown>
          </div>
        </nav>
      </header>
      <main>
        <router-view/>
      </main>
      <footer id="footer">
        <a href="https://www.thedrivenexperience.com" target="_blank">
          <img alt="Driven"
            src="../../assets/TagLine_Driven_Clr.png"
            style="height: 30px;" />
        </a>
        <div>
          EV Tools Powered by Driven, last updated 5/30/2023. Find out more at
          <a href="https://thedrivenexperience.com" target="_blank">The Driven Experience</a>.
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: {
        dollarsPerKwh: 0.12,
        dollarsPerGallon: 3.00,
      },
      client_api_path: 'amerenmissouri',
      ev_charger_description: 'Ameren Missouri has created a <a href="https://www.ameren.com/-/media/missouri-site/files/electric-vehicles/charging-guide-residential.ashx" target="_blank">Charging Installation Guide for Residents</a> to help provide practical tips on EV charging. To install Level 2 charging at your home or business we recommend you consult a licensed electrician or one of our <a href="https://www.ameren.com/missouri/business/electric-vehicles/electric-vehicle-partners/electrical-contractors" target="_blank">Electric Vehicle Partners</a>.',
      message_keys: ['filterMake', 'filterSeats', 'sortKey'],
      plugshare_map_url: 'https://www.plugshare.com/widget2.html?latitude=37.9642529&longitude=-91.8318334&spanLat=2.89134&spanLng=2.89134&plugs=1,2,3,4,5,6,42,13,7,8,9,10,11,12,14,15,16,17',
    };
  },
  mounted() {
  },
  methods: {
  },
  created() {
    // Override tab title and favicon
    const link = document.querySelector('[rel~=\'icon\']');
    /* eslint-disable */
    link.href = require('../../assets/Ameren_Logo_Favicon.png');
    /* eslint-enable */

    // Load EV Data
    this.$parent.getEVData();
    this.$parent.getChargerData();
    // this.$parent.getIncentiveData();
  },
};
</script>

<style scoped>
#app {
/*  --client-color: #599346;
  --client-color-darker: #438441;
  --client-color-selected: #1B6CB5;
  --client-color-red: #D98C24;
  --client-color-savings-icons: #599346;*/
  --accent: #599346;
  --client-color-darker: #438441;
  --client-color-selected: #1B6CB5;
  --client-color-red: #D98C24;
  --client-color-savings-icons: #599346;
}
</style>
