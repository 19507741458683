<template>
<!-- eslint-disable max-len no-trailing-spaces -->
  <div>
    <div style="position: relative;">
      <!-- Image left outside the content wrap so that it fills width -->
      <img alt="homepage" style="width:100%; margin-top: 0px; opacity: 20%;"
      :src="require('../../assets/sales_page/sales_page_header.jpg')"  rel="preload"/>
      <div style="position: absolute;top: 50%; left: 3%; transform: translate(0%, -50%); overflow: hidden;">
        <h2 style="font-size: min(20vw, 200%);">Driven To Electrify</h2>
        <br>
        <!-- TODO should I put this font size calculation onto main headers? Or only neededd here? -->
        <h6 style="font-size: min(2.25vw, 100%);">
          Driving Electrification with Intuitive Consumer Shopping Tools
          <br>
          Choose from our suite of options, readily customized to integrate into your online resources
        </h6>
        <br>
      </div>
    </div>
    <div id="content-wrap">
    <div style="margin-top: 20px;"> 
      <!-- <h6 class="" style="text-decoration: underline; text-decoration-color: var(--accent); text-underline-offset: 10px;;"> Tool Options Include </h6> -->
      <div>
        <h6>
          Tools Options
        </h6>
      </div>
      <div>
        <large> Discover EVs, charging stations, and savings over time with our core tools.  </large> <br>
        <large> Find nearby dealerships, public chargers, and more with our suite of offerings and custom app capacity.  </large>
      </div>
      <div>
        <div style="width:100%;">

          <!-- <hr> -->
          <br>
          <div class="paragraphGroup">
            <div class="t6"> 
              <font-awesome-icon icon="fa-solid fa-car-side" class="iconMobile"/>
               <router-link class="inlineRouterLink" to="/driven/ev_comparison">Compare EVs</router-link>
            </div>
            <div style="display: flex; flex-wrap: wrap; flex-direction: row;"> 
              <div class="paragraph paragraphWithImage">
                <span>
                  Effortlessly filter through all current electric vehicles (EVs) and plug-in hybrid.
                  Each vehicle listing includes a quality image, detailed information, tax incentive eligibility, and links to both the manufacturer page and our "Calculate Savings" tool, which showcases financial and environmental advantages over traditional gas vehicles.
                  <br><br>Users can:
                  <ul>
                   <li>Filter by make, vehicle type, and seating capacity </li>
                   <li>Search by keyword, make, model, and year</li>
                   <li>Sort vehicles by price, range, horsepower, and more</li>
                   <li>Compare specific vehicles with the "Compare Favorites" feature</li>
                   <li>Share a filtered list with "Copy Link"</li>
                 </ul>
                </span>
              </div>
              <img :src="require('../../assets/sales_page/1.jpg')" :alt="test" class="itemBlock">
            </div>
            <hr>

            <div class="t6"> 
              <font-awesome-icon icon="fa-solid fa-charging-station" class="iconMobile"/>
               <router-link class="inlineRouterLink" to="/driven/charger_comparison">Compare Chargers</router-link>
            </div>
            <div style="display: flex; flex-wrap: wrap; flex-direction: row;"> 
              <div class="paragraph paragraphWithImage">
                <span>
                  Locate the best EV charger for your home or business. Each charger includes product details and purchase/information links. Chargers are pre-screened for Energy Star and UL certification and the list is updated continuously.
                  <br><br>Your customers can:
                 <ul>
                   <li>Filter by make, plug type, and features </li>
                   <li>Search by keyword</li>
                   <li>Sort by price and amperage</li>
                   <li>Allows "Compare Favorites" and "Copy Link" functionality</li>
                 </ul>
                </span>
              </div>
              <img :src="require('../../assets/sales_page/2.jpg')" :alt="test" class="itemBlock">
            </div>
          <hr>

            <div class="t6"> 
              <font-awesome-icon icon="fa-solid fa-money-bill" class="iconMobile"/>
               <router-link class="inlineRouterLink" to="/driven/savings_calculator">Calculate Savings</router-link>
            </div>
            <div style="display: flex; flex-wrap: wrap; flex-direction: row;"> 
              <div class="paragraph paragraphWithImage">
                <span>
                  Calculate potential savings from driving electric with this powerful tool. Select from a list of EVs or click in directly from the EV Comparison tool to visualize potential financial benefits. Key features include:
                 <ul>
                   <li>Gas and electric prices default to your area’s average and can be further customized by users along with vehicle efficiencies and weekly driving amounts </li>
                   <li>Clean charts paired with specific highlighted numbers work together to help users understand fuel savings</li>
                   <li>Simultaneously capable of calculating CO2 emission reductions and includes the ability to customize emission coefficients to your locality</li>
                 </ul>
                </span>
              </div>
              <img :src="require('../../assets/sales_page/4.jpg')" :alt="test" class="itemBlock">
            </div>
            <hr>

            <div class="t6"> 
              <font-awesome-icon icon="fa-solid fa-sack-dollar" class="iconMobile"/>
               <router-link class="inlineRouterLink" to="/driven/incentives">Find Incentives</router-link>
            </div>
            <div style="display: flex; flex-wrap: wrap; flex-direction: row;"> 
              <div class="paragraph paragraphWithImage">
                <span>
                  Discover eligible home and transportation incentives customized to your criteria. We tailor the "Find Incentives" tool to reflect specific state, county, and city incentive programs. Your customers can:
                  <ul>
                    <li>Filter by eligibility criteria including zip code, income, and household size</li>
                    <li>Compare specific providers, categories, and discount types</li>
                    <li>Search by incentive titles and key terms</li>
                    <li>Conveniently access incentive program pages to apply or find out more</li>
                  </ul>
                </span>
              </div>
              <img :src="require('../../assets/sales_page/6.jpg')" :alt="test" class="itemBlock">
            </div>
            <hr>
            <div class="t6"> 
              <font-awesome-icon icon="fa-solid fa-location-dot" class="iconMobile"/>
               <router-link class="inlineRouterLink" to="/driven/dealers">Dealership Tool</router-link>
            </div>
            <div style="display: flex; flex-wrap: wrap; flex-direction: row;"> 
              <div class="paragraph paragraphWithImage">
                <span>
                  View a map and list of dealerships customized to your area, filterable by make, and sorted by distance from your location.
                  Dealership listings include:
                  <ul>
                    <li>Location, contact information, and website link</li>
                    <li>Easy access to directions</li>
                    <!-- <li>Capability to link directly to test drive signups</li> -->
                    <li>Partner dealerships can be additionally highlighted</li>
                  </ul>
                </span>
              </div>
              <img :src="require('../../assets/sales_page/7.jpg')" :alt="test" class="itemBlock">
            </div>
            <hr>
            <div class="t6"> 
              <font-awesome-icon icon="fa-solid fa-star" class="iconMobile"/>
               Additional Tools
            </div>
            <div style="display: flex; flex-wrap: wrap; flex-direction: row;"> 
              <div class="paragraph paragraphWithImage">
                <span>
                  Additional tools are available, including:
                  <ul>
                    <li>Locate public charging stations near you</li>
                    <li>Find new and used EVs for sale near you</li>
                    <li>Find electricians for charger installation</li>
                    <li>Display information on relevant events such as test drives</li>
                    <li>Educational and resource landing pages</li>
                    <li>Custom tools and pages purpose built for your needs</li>
                  </ul>
                </span>
              </div>
              <img :src="require('../../assets/sales_page/7.jpg')" :alt="test" class="itemBlock">
            </div>
<!--             <hr>
            <div class="t6"> 
              <font-awesome-icon icon="fa-solid fa-circle-info" class="iconMobile"/>
                Education
            </div>
            <div class="paragraph">
              <span>
                Driven offers more than a decade of experience building educational content and web pages focused on electrification along with hands-on experience hosting webinars and in-person events. We leverage our extensive database of educational materials to create descriptive pages deeply linked to our tools and customized to your needs. These educational materials can be deeply integrated with our EV Tool offerings — for example, our EV Comparison tool can link to and be linked from pages highlighting EV benefits, comparing EVs and PHEVs, and more.
              </span>
            </div> -->
          </div>
          </div>
        </div>
<!--         <div style="">
          <b-carousel
            id="carousel-1"
            :interval="5000"
            :controls="true"
            :indicators="true"
            no-hover-pause
            no-animation
            style="margin: 0 auto; text-shadow: 0px 0px 2px #000"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
            v-model="slide"
          >
            <b-carousel-slide :img-src="require('../../assets/sales_page/1.png')"></b-carousel-slide>
            <b-carousel-slide :img-src="require('../../assets/sales_page/2.png')"></b-carousel-slide>
            <b-carousel-slide :img-src="require('../../assets/sales_page/3.png')"></b-carousel-slide>
            <b-carousel-slide :img-src="require('../../assets/sales_page/4.png')"></b-carousel-slide>
            <b-carousel-slide :img-src="require('../../assets/sales_page/5.png')"></b-carousel-slide>
            <b-carousel-slide :img-src="require('../../assets/sales_page/6.png')"></b-carousel-slide>
            <b-carousel-slide :img-src="require('../../assets/sales_page/7.png')"></b-carousel-slide>
          </b-carousel>
        </div> -->
      </div>
      <hr>
      <div>
      <div>
        <h6>
          The Driven Advantage
        </h6>
      </div>
      <div>
        <large> Thorough customization, extensive accessibility features, real time analytics, and simple integration with your existing site. </large>
      </div>
      <!-- <hr> -->
      <br>
      <div class="paragraphGroup">
        <div class="t6">
          <font-awesome-icon icon="fa-solid fa-wrench" class="iconMobile"/>Customization
        </div>
        <div class="paragraph">
          <span>
            We tailor our tools to match your branding, including logos, colors, favicons, and more. We support regional customization of gas and electric prices, available incentives programs, dealerships, and charging stations. We also offer the ability to add custom text and links across the site to direct tool users back to relevant programs. Additionally, we have the capability to develop new tools to meet additional requirements and modify existing ones to provide desired features.
          </span>
        </div>

        <hr>
        <div class="t6">
          <font-awesome-icon icon="fa-solid fa-person" class="iconMobile"/>Accessibility
        </div>
        <div class="paragraph">
          <span>
            Driven prioritizes inclusivity with screen reader support, language translation, keyboard navigation, and carefully reviewed copy for accessible reading levels. Our tools are not only functional but also visually appealing and user-friendly on mobile phones and tablets, ensuring accessibility at events and on the go.
          </span>
        </div>

        <hr>
        <div class="t6">
          <font-awesome-icon icon="fa-solid fa-chart-simple" class="iconMobile"/>Analytics
        </div>
        <div class="paragraph">
          <span>
            We support detailed logging that feeds into a password-protected analytics dashboard, offering real time insights into tool usage trends and user preferences. This provides insights ranging from high level tracking of usage over time to granular views into the filters, sorting, and inputs of each tool.
          </span>
        </div>

        <hr>
        <div class="t6">
          <font-awesome-icon icon="fa-solid fa-gear" class="iconMobile"/>Implementation
        </div>
        <div class="paragraph">
          <span>
            Our tools can be linked directly from your existing site, with consistent branding, favicons, site titles, and back links providing a continuity of experience for users. This makes the implementation as simple as adding new links into your site content and marketing materials. We can alternatively surface these tools using a subdomain of your existing site.
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- eslint-enable max-len -->
</template>

<script>

export default {
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
  mounted() {
  },
  methods: {
    /* eslint-disable */
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
    /* eslint-enable'...' */
    getImgUrl(filename) {
      // Requires and gets route to image. Require setup here requires temporary lint disabling.
      // TODO we should consider a CDN setup to enable REACH members to easily add images.
      // Currently, charger images are added to /assets/EV_Chargers
      /* eslint-disable */
      try {
        return require(`../../assets/sales_page/${filename.image_url}`);
      }
      catch (e) {
        return '';
      }
      /* eslint-enable'...' */
    },
  },
  created() {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

ul li { padding: 5px; }

/* See carousel-control-next styling in App vue for controls overrides */
.carousel {
  width:40%;
}

.container {
  /* Offset from header */
  /*margin-top: 0px;
  display: block;*/
}
/* Same margin bottom as paragraph*/
.textList {
/*  font-size:110%;*/
  margin-top: 0px;
  display: table;
  /*margin-left: 60px;*/
}

.sectionheader {
  margin-bottom: 20px;
  /*text-align: center;*/
}

.paragraph {
/*  font-size: 100%;*/
  margin-top: 20px;
/*  margin-left: 70px;*/
}
.paragraphGroup {

}

.iconContainer {
/*  align-items: center;*/
  justify-content: center;  /*horizontal*/
}
.iconMobile {
  margin: auto auto;
  height: 2em;
  /*float: left;*/
  margin-right: 20px;
  /*padding: 20px;*/
  vertical-align: middle;
  /*margin-top:  10spx;*/
  /*display: block;*/
  clear: both;
  color: var(--accent);
  width: 2em;
  /*float:left;*/
}

.icon {
  margin: auto auto;
  width: 120px;
  float: left;
  margin-right: 40px;
  padding: 20px;
  /*vertical-align: middle;*/
  /*margin-top:  10spx;*/
  /*display: block;*/
  clear: both;
  /*float:left;*/
}

.paragraphGroup {
/*  margin-left: 20px;*/
}

.paragraphWithImage {
  width: 600px;
  max-width:100%;
}
.itemBlock {
  /* TODO FIGURE OUT HOW TO ALIGN AT THE TOPPP */
  display: flex; 
  justify-content: top;
  margin: 0;
  padding: 0;
/*  margin-bottom: auto;*/
/*  margin-top: 0;*/
/*  display: block;*/
/*align-self: top;*/
  width: 700px;
  max-width:80%;
  height: 100%;
  margin: 20px auto;
/*  align-self: center;*/
  padding: 10px;
/*  align-self: top;*/
/*  margin-left: auto;*/
}
img {
  margin: 0;
}

img {
  height: auto;
}

</style>
