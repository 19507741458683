<template>
  <!-- eslint-disable max-len -->
  <div id="app">
    <title>Marin County EV Tools</title>
    <div id="page-container">
      <header>
        <nav>
          <div>
            <a href="https://www.marincounty.org/" target="_blank">
              <img alt="Marin County logo"
                src="../../assets/marin_county_logo.png"
                />
            </a>
          </div>
          <div class="navbar" v-if="! $globalObservableData.showNavDropown" role="navigation">
            <router-link to="/marincounty/ev_comparison">Compare EVs</router-link>
            <router-link to="/marincounty/charger_comparison">Compare EV Chargers</router-link>
            <router-link to="/marincounty/savings_calculator">Calculate Savings</router-link>
            <router-link to="/marincounty/incentives">Find Incentives</router-link>
          </div>
          <div v-else role="navigation">
            <b-dropdown right no-caret class="mobileNavDropdown">
              <template #button-content>
                <font-awesome-icon  icon="fa-solid fa-bars"/>
              </template>
              <b-dropdown-item> <router-link to="/marincounty/ev_comparison">Compare EVs</router-link> </b-dropdown-item>
              <b-dropdown-item> <router-link to="/marincounty/charger_comparison">Compare Chargers</router-link> </b-dropdown-item>
              <b-dropdown-item> <router-link to="/marincounty/savings_calculator">Calculate Savings</router-link> </b-dropdown-item>
              <b-dropdown-item> <router-link to="/marincounty/incentives">Find Incentives</router-link> </b-dropdown-item>
            </b-dropdown>
          </div>
        </nav>
      </header>

      <main>
        <router-view/>
      </main>
      <footer id="footer">
        <div style="text-align: left;">
          <a href="https://thedrivenexperience.com" target="_blank">
            <img alt="Marin County" style="width: 120px; vertical-align: top; margin-right: 40px; margin-top: 0px"
              src="../../assets/marin_county_logo.png"
              />
          </a>
          <div class="navbar" style="text-align: left;">
            <div><router-link to="/marincounty/ev_comparison">Compare EVs</router-link></div>
            <div><router-link to="/marincounty/charger_comparison">Compare EV Chargers</router-link></div>
            <div><router-link to="/marincounty/savings_calculator">Calculate Savings</router-link></div>
            <div><router-link to="/marincounty/incentives">Find Incentives</router-link></div>
            <!-- <router-link to="/pacificpower/incentives">Find Incentives</router-link> -->
            <!-- <router-link to="/pacificpower/dealers">Find EV Dealers</router-link> -->
          </div>
          <div style="display: inline-block; vertical-align: top; float: right; font-size: 80%; width: min(100%, 500px);">
            If you are a person with a disability and require an accommodation to participate in a County program, service, or activity, requests may be made by calling (415) 473-4381 (Voice), Dial 711 for CA Relay, or by <a style="text-decoration: underline;" href="mailto:DisabilityAccess@MarinCounty.org">email</a> at least five business days in advance of the event. We will do our best to fulfill requests received with less than five business days’ notice. Copies of documents are available in alternative formats upon request.
          </div>
        </div>
        <div><hr style="background: none;"></div>
        <div style="text-align: center;">
          <a href="https://www.thedrivenexperience.com" target="_blank">
            <img alt="Driven"
              src="../../assets/TagLine_Driven_Clr.png"
              style="filter: brightness(0) invert(1); height: 40px; margin:10px" />
          </a>
        </div>
      </footer>
<!--       <footer id="footer" >
        <div style="width:80%; margin: 50px auto 0 auto;">
          If you are a person with a disability and require an accommodation to participate in a County program, service, or activity, requests may be made by calling (415) 473-4381 (Voice), Dial 711 for CA Relay, or by <a href="mailto:DisabilityAccess@MarinCounty.org">email</a> at least five business days in advance of the event. We will do our best to fulfill requests received with less than five business days’ notice. Copies of documents are available in alternative formats upon request.
        </div>
        <a href="https://www.thedrivenexperience.com" target="_blank">
          <img alt="Powered by Driven logo"
            src="../../assets/TagLine_Driven_Clr.png"
            style="height: 30px; margin-bottom: 50px;" />
        </a>
      </footer> -->
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      // Income Limits
      // AMI -- https://www.marinhousing.org/marin-county-income-limits
      household_size_ami: [
        122500,
        140000,
        157500,
        175000,
        189000,
        203000,
        217000,
        231000,
        245000,
      ],
      // SMI -- https://www.cde.ca.gov/sp/cd/ci/mb2205.asp unrelated program but only place I found SMI by household size chart
      household_size_smi: [
        84818,
        84818,
        96590,
        112105,
        130042,
        147988,
        151342,
        154705,
        158068,
        161431,
        164794,
        168158,
      ],
      // FPL -- https://aspe.hhs.gov/topics/poverty-economic-mobility/poverty-guidelines
      household_size_fpl: [
        14580,
        19720,
        24860,
        30000,
        35140,
        40280,
        45420,
        50560,
      ],
      // https://www.energysage.com/local-data/electricity-cost/ca/marin-county/#:~:text=Electric%20rates%20in%20Marin%20County%2C%20CA,-The%20easiest%20way&text=The%20average%20residential%20electricity%20rate,rate%20of%2018%20%C2%A2%2FkWh.
      state: {
        dollarsPerKwh: 0.31,
        dollarsPerGallon: 5.20,
      },
      powerSources: [
        {
          name: 'Marin Clean Energy  - Light Green',
          emissionsPerKwh: 75 * 4.53592 * (10 ** -7), // metric tons CO2/kWh
          dollarsPerKwh: 0.20,
        },
        {
          name: 'Marin Clean Energy  - Deep Green',
          emissionsPerKwh: 0, // metric tons CO2/kWh
          dollarsPerKwh: 0.20,
        },
        {
          name: 'Pacific Gas and Electric Company - Base Plan',
          emissionsPerKwh: 98 * 4.53592 * (10 ** -7), // metric tons CO2/kWh
          dollarsPerKwh: 0.20,
        },
        {
          name: 'National Average',
          emissionsPerKwh: 4.33 * (10 ** -4), // metric tons CO2/kWh
          dollarsPerKwh: 0.20,
        },
        {
          name: 'Coal Only',
          emissionsPerKwh: 0.000512,
          dollarsPerKwh: 0.20,
        },
        {
          name: 'Renewable Only',
          emissionsPerKwh: 0,
          dollarsPerKwh: 0.20,
        },
      ],
      client_api_path: 'marincounty',
      // Placed directly in components to support fontawesome icons
      ev_charger_description: 'Use MCE’s <a href="https://www.mcecleanenergy.org/mce-sync/"  target="_blank">EV smart charging app, MCE Sync</a>, while charging at home to earn monthly cash back!',
      ev_comparison_description: 'As you’re browsing through, consider <a href="https://www.mcecleanenergy.org/ev-rebate/"  target="_blank">MCE’s EV rebate</a> of up to $3,500 at participating dealerships.',
    };
  },
  mounted() {
  },
  methods: {
  },
  created() {
    // Override tab title and favicon
    const link = document.querySelector('[rel~=\'icon\']');
    /* eslint-disable */
    link.href = require('../../assets/marin_county_logo.png');
    /* eslint-enable */

    // Load EV Data
    this.$parent.getEVData();
    this.$parent.getChargerData();
    this.$parent.getIncentiveData();
  },
};
</script>

<style scoped>
#app {
  /* NOTE: as of 5/15/24 these are the same as the default App colors */
  /* Still placing here, just in case? */
  /* Client custom */
/*  --accent: #B78300;*/
/*  --light-accent: #FFF6DF;*/
  --accent: rgb(37, 85, 142);
  --light-accent: white;
  /* Misc tool colors, eg savings chart */
/*  --savings-green: #73784a;*/
  --savings-green: #0EAA00;
  --gasoline-brown: #b45340;
  --electric-blue: rgb(7, 119, 207);
}

</style>
