<template>
  <!-- eslint-disable max-len -->
  <div id="app">
    <div id="page-container">
      <header>
        <nav>
          <div>
            <a href="https://www.pacificpower.net/" target="_blank">
              <img alt="PacificPower" style="width: 220px; margin-top: 25px;"
                src="../../assets/pacific-power-logo.svg"
                />
            </a>
          </div>
          <div class="navbar" v-if="! $globalObservableData.showNavDropown">
            <router-link to="/pacificpower/ev_comparison">COMPARE EVs</router-link>
            <router-link to="/pacificpower/charger_comparison">COMPARE CHARGERS</router-link>
            <router-link to="/pacificpower/savings_calculator">SAVINGS</router-link>
            <!-- <router-link to="/pacificpower/incentives">Find Incentives</router-link> -->
            <router-link to="/pacificpower/dealers">DEALERS</router-link>
            <router-link to="/pacificpower/charger_map">FIND CHARGERS</router-link>
            <a style="color: var(--grey)">SHOP EVs</a>
            <!-- <a style="color: var(--grey)">DEALERS</a> -->
            <!-- <a style="color: var(--grey)">Incentives</a> -->
            <a style="color: var(--grey)">ELECTRICIANS</a>
            <a style="color: var(--grey)">EVENTS</a>
            <!-- <a style="color: var(--error-red); float:right;">[DEMO]</a> -->
          </div>
          <div v-else>
            <b-dropdown right no-caret class="mobileNavDropdown">
              <template #button-content>
                <font-awesome-icon  icon="fa-solid fa-bars"/>
              </template>
              <b-dropdown-item>
                <router-link to="/pacificpower/ev_comparison">COMPARE EVs</router-link>
              </b-dropdown-item>
              <b-dropdown-item>
                <router-link to="/pacificpower/charger_comparison">COMPARE CHARGERS</router-link>
              </b-dropdown-item>
              <b-dropdown-item>
                <router-link to="/pacificpower/savings_calculator">SAVINGS</router-link>
              </b-dropdown-item>
              <b-dropdown-item>
                <router-link to="/pacificpower/dealers">DEALERS</router-link>
              </b-dropdown-item>
              <b-dropdown-item>
                <router-link to="/pacificpower/charger_map">FIND CHARGERS</router-link>
              </b-dropdown-item>
              <b-dropdown-item>
                <a style="color: var(--grey)">SHOP EVs</a>
              </b-dropdown-item>
              <b-dropdown-item>
                <a style="color: var(--grey)">ELECTRICIANS</a>
              </b-dropdown-item>
              <b-dropdown-item>
                <a style="color: var(--grey)">EVENTS</a>
              </b-dropdown-item>
              <!-- <b-dropdown-item> <router-link to="/pacificpower/incentives">Find Incentives</router-link></b-dropdown-item> -->
              <!-- <b-dropdown-item> <router-link to="/pacificpower/dealers">Find EV Dealers</router-link></b-dropdown-item> -->
            </b-dropdown>
          </div>
        </nav>
      </header>
      <main>
        <router-view/>
      </main>
      <footer id="footer">
        <div style="text-align: center;">
          <a href="https://www.pacificpower.net/" target="_blank">
            <img alt="Pacific Power" style="width: 330px; filter: brightness(0) invert(1); vertical-align: top; margin-right: 40px; margin-top: 10px"
              src="../../assets/pacific-power-logo.svg"
              />
          </a>
          <div class="navbar" style="text-align: left;">
              <div>
                <router-link to="/pacificpower/ev_comparison">COMPARE EVs</router-link>
              </div>
              <div>
                <router-link to="/pacificpower/charger_comparison">COMPARE CHARGERS</router-link>
              </div>
              <div>
                <router-link to="/pacificpower/savings_calculator">SAVINGS</router-link>
              </div>
              <div>
                <router-link to="/pacificpower/dealers">DEALERS</router-link>
              </div>
              <div>
                <router-link to="/pacificpower/charger_map">FIND CHARGERS</router-link>
              </div>
            <!-- <router-link to="/pacificpower/incentives">Find Incentives</router-link> -->
            <!-- <router-link to="/pacificpower/dealers">Find EV Dealers</router-link> -->
          </div>
<!--           <div style="display: inline-block; vertical-align: top; float: right; font-size: 80%; width: min(100%, 500px);">
            Your custom footer text and links go here. <br>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
          </div> -->
        </div>
        <div><hr style="background: none;"></div>
        <div style="text-align: center;">
          <a href="https://www.thedrivenexperience.com" target="_blank">
            <img alt="Driven"
              src="../../assets/TagLine_Driven_Clr.png"
              style="filter: brightness(0) invert(1); height: 40px; margin:10px" />
          </a>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // Income Limits
      // AMI -- https://www.marinhousing.org/marin-county-income-limits
      household_size_ami: [
        122500,
        140000,
        157500,
        175000,
        186000,
        203000,
        217000,
        231000,
        245000,
      ],
      // SMI -- https://www.cde.ca.gov/sp/cd/ci/mb2205.asp unrelated program but only place I found SMI by household size chart
      household_size_smi: [
        84818,
        84818,
        96590,
        112105,
        130042,
        147988,
        151342,
        154705,
        158068,
        161431,
        164794,
        168158,
      ],
      // FPL -- https://aspe.hhs.gov/topics/poverty-economic-mobility/poverty-guidelines
      household_size_fpl: [
        14580,
        19720,
        24860,
        30000,
        35140,
        40280,
        45420,
        50560,
      ],
      // https://www.energysage.com/local-data/electricity-cost/ca/marin-county/#:~:text=Electric%20rates%20in%20Marin%20County%2C%20CA,-The%20easiest%20way&text=The%20average%20residential%20electricity%20rate,rate%20of%2018%20%C2%A2%2FkWh.
      // TODO this should prepopulate from whatever powerSource is selected....
      state: {
        dollarsPerKwh: 0.102,
        dollarsPerGallon: 4.65,
        emissionsPerKwh: 4.33 * (10 ** -4),
      },
      client_api_path: 'pacificpower',
      ev_charger_description: '',
      ev_data: [],
      // ev_comparison_description: '* As you’re browsing through, consider <a href="https://www.mcecleanenergy.org/ev-rebate/"  target="_blank">MCE’s EV rebate</a> of up to $3,500 at participating dealerships.',
      // https://www.pacificpower.net/content/dam/pcorp/documents/en/pacificpower/rates-regulation/california/CA_Price_Summary.pdf
      // https://www.pacificpower.net/content/dam/pcorp/documents/en/pacificpower/rates-regulation/oregon/tariffs/Oregon_Price_Summary.pdf

      // https://www.pacificpower.net/about/value/residential-price-comparison.html
      powerSources: [
        {
          name: 'Pacific Power WA',
          emissionsPerKwh: 4.33 * (10 ** -4), // metric tons CO2/kWh
          dollarsPerKwh: 0.102,
        },
        {
          name: 'Pacific Power CA',
          emissionsPerKwh: 4.33 * (10 ** -4), // metric tons CO2/kWh
          dollarsPerKwh: 0.163,
        },
        {
          name: 'Pacific Power OR',
          emissionsPerKwh: 0.000512,
          dollarsPerKwh: 0.117,
        },
        {
          name: 'National Average',
          emissionsPerKwh: 4.33 * (10 ** -4), // metric tons CO2/kWh
          dollarsPerKwh: 0.172,
        },
        {
          name: 'Coal Only',
          emissionsPerKwh: 0.000512,
          dollarsPerKwh: 0.172,
        },
        {
          name: 'Renewable Only',
          emissionsPerKwh: 0,
          dollarsPerKwh: 0.172,
        },
      ],
      center: { lat: 40, lng: -90 },
      zoom: 6,
    };
  },
  mounted() {
  },
  methods: {
  },
  created() {
    // Override tab title and favicon
    const link = document.querySelector('[rel~=\'icon\']');
    /* eslint-disable */
    link.href = require('../../assets/pacific-power-favicon.png');
    // Load EV Data
    this.$parent.getEVData();
    this.$parent.getChargerData();
    this.$parent.getIncentiveData();
  },
};
</script>

<style scoped>

#app {
  /* 75% lightened with https://mdigi.tools/lighten-color/#356db1 */
  /* Maybe this should be automatic with opacity?.. Maybe there should be a secondary accent? */
  font-family: Lato, sans-serif;
  --font-weight-heavy: 700;
  --font-weight-medium: 600;
  --light-accent: rgb(201, 218, 239);
  --accent: rgb(53, 109, 177);
  --gasoline-brown: #E4002B;
  --electric-blue: #0072CE;
/*  --savings-green: #6CC24A;*/
/*  --client-color: rgb(136, 189, 197);
  --client-color-darker: rgb(38, 94, 110);
  --client-color-selected: rgb(7, 119, 207);
  --client-color-red: #b45340;
  --client-color-savings-icons: #989f63;*/
}

nav a {
  font-weight: 700;
  font-size: 16px;
  color: black;
  text-decoration: none;
  margin: 10px;
/*  color: var(--active-link-blue);*/
}
.navbar a {
  font-size: 16px;
}

nav a.router-link-active {
/*  color: var(--accent);*/
  text-decoration: underline;
  text-decoration-color: var(--accent);
  text-underline-offset: 10px;
  text-decoration-thickness: 2px;
/*  text-decoration-thickness: 4px;*/
}

header {
  height: 100px;
}
</style>
