<template>
<!-- eslint-disable max-len -->
  <div>

    <div id="content-wrap">
      <div v-if="$attrs.vehicleType === 'Commercial'">
        <h1 class="pageTitle" id="title" role="banner">
          Compare Commercial EVs
        </h1>
      </div>
      <div v-else>
<!--         <h1 class="pageTitle" id="title" role="banner">
          Compare EVs
        </h1> -->
      </div>
<!--       <h2 class="subheader">
        Discover electric vehicles and plug-in hybrids that fit your needs.
        <br><br>
        You can look through all the electric vehicles below. Or use the filters to limit the vehicles you're viewing.
        <ul style="">
          <li>
            Search by keywords or filter by make, vehicle type, and number of seats.
          </li>
          <li>
            Sort vehicles in order of MSRP, Battery Range, Total Range, Efficiency, Horsepower, or Seats. Toggle sort order between Low to High or High to Low.
          </li>
        </ul>
        <font-awesome-icon icon="fa-solid fa-heart" style="margin-left: 10px; margin-right: 10px;"/>Click the heart button to like your favorite vehicles. Then "Compare Liked" to see only the vehicles you have liked.
        <br><br>
        <font-awesome-icon icon="fa-solid fa-link" style="margin-left: 10px; margin-right: 10px;"/>Click the "Copy Link" button. Then paste into a message to share your filtered view with friends and family.
      </h2> -->
<!--       <div>
        <div v-if="$parent.ev_comparison_description">
          <br>
          <font-awesome-icon icon="fa-solid fa-circle-info" style="margin-left: 10px; margin-right: 10px;"/>
          <h2 class="subheader" v-html="$parent.ev_comparison_description"></h2>
        </div>
      </div> -->
      <div style="align-content: center; height: auto; margin: 0 0 20px 0;">
        <span class="subheader" style="font-size: 20px; color: var(--dark-grey); font-weight: 600; float: left; margin-top: 15px;">
          {{totalItems}} results
        </span>
        <button class="buttonStyleAccent"
          id="copy-link"
          aria-label="copy link"
          @click="copyLink()"
          style="margin-right: 0; float:right">
          <font-awesome-icon icon="fa-solid fa-link" />
          Copy Link
        </button>

        <button class="buttonStyleAccentDark"
        @click="state.filterLiked = !state.filterLiked; logMessage('info', 'Compare Liked Cars', state.filterLiked)"
        style="float:right">
          <font-awesome-icon :icon="state.filterLiked
            ? 'fa-solid fa-heart' : 'fa-regular fa-heart'"/>
            Compare Favorites ({{numLiked}})
        </button>
      </div>
      <!-- <hr class="headerHR"> -->
      <div class="controlPanelItemListWrapper">
        <!-- Todo: determine how to fix side panel position with scroll... style="position: fixed; top:200px" -->
        <div class="controlPanel" aria-label="control panel" role="form">
          <div class="iconAddonWrapper">
            <img src="../../assets/search.svg" alt="search icon" class="iconAddonLeft">
            <input v-model="state.searchInput" @change="logMessage('info', 'Search', state.searchInput)"
              aria-label="search"
              role="search"
              type="text"
              id="searchInput"
              placeholder="Search"
              class="textinput">
          </div>
          <select v-model="state.filterFuelType" @change="logMessage('info', 'Filter Fuel Type', state.filterFuelType)"
            aria-label="filter fuel type"
            name="fuel-type"
            id="fuel-type"
            class="round">
            <option value="" selected>Fuel</option>
            <!-- Options are hardcoded to display different human readable names -->
            <option value="BEV"> Battery Electric Vehicle </option>
            <option value="PHEV"> Plug-In Hybrid EV</option>
          </select>
          <select v-model="state.filterMake" @change="logMessage('info', 'Filter Make', state.filterMake)"
            aria-label="filter make"
            name="make-model"
            id="make-model"
            class="round">
            <option value="" selected>Make</option>
            <!-- Dynamically compute options from the data. Automatically updates if options are added/removed from data -->
            <option v-for="(val, index) in $parent.$parent.ev_data.filter(car => car.type === this.$attrs.vehicleType).map(
                (item) => item.make
              ).filter(
                (value, index, current_value) => current_value.indexOf(value) === index).sort()" :key="index"> {{val}}
            </option>
          </select>
          <select v-model="state.filterFormFactor" @change="logMessage('info', 'Filter Type', state.filterFormFactor)"
            aria-label="filter type"
            name="form-factor"
            id="form-factor"
            class="round">
            <option value="" selected>Type</option>
            <!-- Dynamically compute options from the data. Automatically updates if options are added/removed from data -->
            <option v-for="(val, index) in $parent.$parent.ev_data.filter(car => car.type === this.$attrs.vehicleType).map(
                (item) => item.form_factor
              ).filter(
                (value, index, current_value) => current_value.indexOf(value) === index).sort()" :key="index"> {{val}}
            </option>
          </select>
  <!--         <select v-model="state.filterSeats" @change="logMessage('info', 'Filter Seats',state.filterSeats)"
            aria-label="filter seats"
            name="seats"
            id="seats"
            class="round">
              <option value="" selected>Seats</option> -->
              <!-- Dynamically compute options from the data. Automatically updates if options are added/removed from data -->
  <!--             <option v-for="(val, index) in $parent.$parent.ev_data.filter(car => car.type === this.$attrs.vehicleType).map(
                  (item) => item.seats_max
                ).filter(
                  (value, index, current_value) => current_value.indexOf(value) === index).sort((a,b) => a-b)" :key="index"> {{val}}
              </option>
          </select> -->
          <!-- TODO initialize min/max values from data instead of hardcoding -->
          <div class="sliderContainer">
            <div>Minimum Range: {{state.filterRange}}</div>
            <input
              v-model="state.filterRange"
              @change="logMessage('info', 'Filter Seats',state.filterRange)"
              aria-label="filter seats"
              name="seats"
              id="seats"
              type="range"
              :min="0"
              :max="500"
              class="sliderInput">
          </div>
          <div class="sliderContainer">
            <div>Minimum Seats: {{state.filterSeats}}</div>
            <input
              v-model="state.filterSeats"
              @change="logMessage('info', 'Filter Seats',state.filterSeats)"
              aria-label="filter seats"
              name="seats"
              id="seats"
              type="range"
              :min="2"
              :max="8"
              class="sliderInput">
          </div>
          <hr>
          <select v-model="state.sortKey" @change="logMessage('info', 'Sort By', state.sortKey)"
            aria-label="sort by"
            name="sort"
            id="sort"
            class="round">
              <option value='msrp'>Sort By: MSRP</option>
              <!-- Options hardcoded to surface specific keys for filtering in a human readable way -->
              <option value='electric_range' selected>Sort By: Battery Range</option>
              <option value='total_range' selected>Sort By: Total Range</option>
              <option value='miles_per_kwh'>Sort By: Efficiency (miles/kWh)</option>
              <option value='horsepower'>Sort By: Horsepower</option>
              <option value='seats_max'>Sort By: Seats</option>
              <option v-if="this.$attrs.vehicleType == 'Commercial'" value='payload'>Sort By: Payload</option>
              <option v-if="this.$attrs.vehicleType == 'Commercial'" value='curb_weight'>Sort By: Curb Weight</option>
              <option v-if="this.$attrs.vehicleType == 'Commercial'" value='gvwr'>Sort By: GVWR</option>
              <!-- <option value='model_year'>Sort: Year</option> -->
          </select>
          <select v-model="state.sortDirection" @change="logMessage('info', 'Sort Order', state.sortDirection > 0 ? 'Low to High' : 'High to Low')"
            aria-label="toggle sort order">
            <option value="1" selected>Low to High</option>
            <!-- Options are hardcoded to display different human readable names -->
            <option value="-1"> High to Low </option>
          </select>
          <!-- TODO this reset needs to be updated every time state model is changed (or default vals are)
            I could see this leading to issues. TODO refactor so that these defaults only live in one place. -->
          <button class="buttonStyle"
            @click="state = {
              searchInput: '',
              sortKey: 'msrp',
              sortDirection: 1,
              filterMake: '',
              filterFormFactor: '',
              filterSeats: 2,
              filterRange: 0,
              filterFuelType: '',
              filterLiked: false,
            }">
            <font-awesome-icon icon="fa-regular fa-circle-xmark" />
            Reset Filters
          </button>
        </div>
        <div class="itemListWrapper">
          <div id="itemList" class="itemList">
            <div v-for="(car, index) in getFilteredEVData()"
              v-bind:key="index"
              class="itemBlock"
              aria-labelledby="car_name">
              <div>
                <button @click="car.liked = !car.liked; logMessage('info', 'Liked Car', `${car.make} {charger.display_name} (${car.model_year})`)"
                  class="likeButton"
                  aria-label="like button">
                  <font-awesome-icon :icon="car.liked
                    ? 'fa-solid fa-heart' : 'fa-regular fa-heart'"
                    style="height: auto;"/>
                </button>
                <h6>
                  <strong class='right' style="font-size: 100%;" id="car_name">{{car.make}} {{car.model}}</strong>
                  <!-- <div style="display:inline-block; width: 200px; height: 140px;"></div> -->
                </h6>

                <div>
                   <span class="large">{{car.trim}} {{car.form_factor}} ({{car.model_year}})</span>
                </div>
                <span>
                  <!-- specify width/height and max width heights to force lazy load image placeholder size to not distort display -->
                  <!-- Also 100% max width causes image to shrink in proportion with screen size on smaller devices -->
                  <!-- Removed v-lazy optimization due to garbage experience on mobile. Downsides of no lazy load: higher api calls and slower initial page load -->
                  <div style="text-align: center;">
                    <img :src="car.image_url || require('../../assets/dalle_generic_commercial_3.png')" :key="car.image_url" width=auto height=250 style="max-width: 100%; height:auto; max-height: 250px; margin: 0 auto;" :alt="car.display_name + ' ' + car.model_year" />
                  </div>
                  <!-- Button routes to Savings Calculator preset to display this vehicle and use it's efficiency -->
                  <!-- <div style="display: flex; justify-content: left; align-items: left;"> -->
                  <div>
                    <button class="buttonStyleDark" style="padding: 0 10px;"
                      @click="$router.push({path: `/${$parent.client_api_path}/savings_calculator`,
                      query: { carIndex: car.index, filterMake: car.make }})">
                      <font-awesome-icon icon="fa-solid fa-calculator" />
                      Calculate Savings
                    </button>
                    <button class="buttonStyle" style="padding: 0 10px;"
                      @click="changeRoute(car.official_website_url)">
                      <font-awesome-icon icon="fa-solid fa-circle-info" />
                      Learn More
                    </button>
                  </div>
                  <div>
                    <b>MSRP: </b>
                      <span v-if="car.msrp">{{car.msrp | currencyUSDWhole}}</span>
                      <span v-else>Unavailable</span>
                    <br />

                    <div v-if="$attrs.vehicleType === 'Commercial'">
                      <b>Payload: </b>
                      <span>{{car.payload}} lbs</span>
                      <br />
                      <b>Curb Weight: </b>
                      <span>{{car.curb_weight}} lbs</span>
                      <br>
                      <b>GVWR: </b>
                      <span>{{car.gvwr}} lbs</span>
                      <br />
                      <!-- <b>Lift Capacity: </b>
                      <span>{{car.lift_capacity || 0}} lbs</span>
                      <br /> -->
                    </div>

                    <b>Efficiency:</b> <span>{{ car.miles_per_kwh | numberTwoDecimal}} miles/kWh</span>
                    <br />

                    <span><b>Battery Range:</b> {{car.electric_range | numberWhole}} miles</span>
                    <span v-if="car.electric_range !==car.total_range">
                      <br>
                      <b>Total Range:</b> {{car.total_range | numberWhole}} miles
                    </span>
                    <br />

                    <b>Battery Capacity: </b>
                    <span>{{car.battery_capacity}} kWh</span>
                    <br />

                    <!-- <b>Horsepower: </b>
                    <span>{{car.horsepower}}</span>
                    <br />

                    <b>Seats: </b>
                    <span>{{car.seats_max}}</span>
                    <br />

                    <b>Vehicle Type: </b>
                    <span>{{car.form_factor}}</span>
                    <br />

                    <b>Plug Types: </b>
                    <span>{{(car.plug_types).join(', ')}}</span>
                    <br /> -->

                    <!-- Boolean logic since we only display CA incentives for Marin County -->
                    <!-- And also only display "Incentives:" if there are incentives to display... -->
                    <!-- There's definitely a more elegant way to do this, which I'll sort out when we have more state specific incentives to deal with. Maybe these should eventually be handled using linked records like the home incentive calculator -->
                  </div>
                </span>
              </div>
              <div class="vehicleIncentiveBox" v-if="car.eligible_for_usa_tax_credit ||
                ($parent.client_api_path == 'marincounty' && (car.eligible_for_ca_hov || car.eligible_for_ca_rebate))">
                <b>Available Incentives:</b>
                <ul style="margin: 0">
                  <!-- <a href='https://www.irs.gov/credits-deductions/credits-for-new-clean-vehicles-purchased-in-2023-or-after' target='_blank'> -->
                  <li v-if="car.eligible_for_usa_tax_credit">Federal Tax Credit: {{car.usa_tax_credit_rebate | currencyUSDWhole}}</li>
                  <!-- Only display these two incentives for Marin County. Can expand if we obtain other CA clients. -->
                  <li v-if="car.eligible_for_ca_hov && $parent.client_api_path == 'marincounty'">Eligible for CA HOV</li>
                  <li v-if="car.eligible_for_ca_rebate && $parent.client_api_path == 'marincounty'">Eligible for CA rebate</li>
                  <!-- <li v-if="car.san_joaquin_valley_ev_rebate">San Joaquin Valley EV Rebate: {{car.san_joaquin_valley_ev_rebate | currencyUSDWhole}}</li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
/* eslint-disable no-console */

export default {
  data() {
    return {
      state: {
        searchInput: '',
        sortKey: 'msrp',
        sortDirection: 1,
        filterMake: '',
        filterFormFactor: '',
        filterSeats: 2,
        filterRange: 0,
        filterFuelType: '',
        filterLiked: false,
      },
      totalItems: 0,
      minPrice: 0,
      maxPrice: 0,
      numLiked: 0,
    };
  },
  methods: {
    copyLink() {
      // Turns state of filters/sort/search into a query parameterized url and copies to clipboard.
      // State vars are loaded on created(), with the exception of likes which are loaded in App.vue
      const queries = [];
      if (this.state.searchInput) {
        queries.push(`searchInput=${this.state.searchInput}`);
      }
      if (this.state.sortKey) {
        queries.push(`sortKey=${this.state.sortKey}`);
      }
      if (this.state.sortDirection) {
        queries.push(`sortDirection=${this.state.sortDirection}`);
      }
      if (this.state.filterMake) {
        queries.push(`filterMake=${this.state.filterMake}`);
      }
      if (this.state.filterFormFactor) {
        queries.push(`filterFormFactor=${this.state.filterFormFactor}`);
      }
      if (this.state.filterSeats) {
        queries.push(`filterSeats=${this.state.filterSeats}`);
      }
      if (this.state.filterRange) {
        queries.push(`filterRange=${this.state.filterRange}`);
      }
      if (this.state.filterFuelType) {
        queries.push(`filterFuelType=${this.state.filterFuelType}`);
      }
      if (this.state.filterLiked) {
        queries.push(`filterLiked=${this.state.filterLiked}`);
      }
      // Comma separated list of likes for use in query param using filter map
      const liked = this.$parent.$parent.ev_data.filter((car) => car.liked).map((car) => car.index).join(',');
      if (liked) {
        queries.push(`liked=${liked}`);
      }
      const path = `${[window.location.origin, this.$parent.client_api_path, 'ev_comparison?'].join('/').replaceAll('//', '/')}${queries.join('&')}`;
      navigator.clipboard.writeText(path);
      this.logMessage('info', 'Copy Link', path);

      return path;
    },
    getFilteredEVData() {
      // Filter
      const filtered = this.$parent.$parent.ev_data.filter((car) => this.passesFilters(car));
      // Update values for banner display of number and min to max
      this.totalItems = filtered.length;
      // https://stackoverflow.com/questions/42413319/get-min-value-from-a-nested-json-object
      this.minPrice = Math.min.apply(null, filtered.map((v) => v.msrp));
      this.maxPrice = Math.max.apply(null, filtered.map((v) => v.msrp));
      this.numLiked = filtered.filter((car) => car.liked).length;
      // Sort based on sort key and direction
      const sorted = filtered.sort((a, b) => this.state.sortDirection * a[this.state.sortKey]
        - this.state.sortDirection * b[this.state.sortKey]);

      return sorted;
    },
    passesFilters(car) {
      // console.log(this);
      if (car.type !== this.$attrs.vehicleType) {
        return false;
      }
      // Car string for searching is defined in App.vue data loading
      if (this.state.searchInput
        && !car.carStr.includes(this.state.searchInput.toUpperCase())) {
        return false;
      }
      if (this.state.filterMake && car.make !== this.state.filterMake) {
        return false;
      }
      if (this.state.filterFormFactor && car.form_factor !== this.state.filterFormFactor) {
        return false;
      }
      // Seats and range filters are MINIMUMS not exact match.
      if (this.state.filterSeats && car.seats_max < parseInt(this.state.filterSeats, 10)) {
        return false;
      }
      if (this.state.filterRange && car.total_range < parseInt(this.state.filterRange, 10)) {
        return false;
      }
      if (this.state.filterFuelType && car.fuel !== this.state.filterFuelType) {
        return false;
      }
      if (this.state.filterLiked && !car.liked) {
        return false;
      }

      return true;
    },
  },
  created() {
    // TODO: not sure how I want to handle this.
    // I think for now easiest option is hardcoded max/mins
    // Preset min/maxes for slider inputs.
    // Do this first so we can default starting vals to the min correctly.
    // const evData = this.getFilteredEVData();
    // this.minSeats = Math.min.apply(null, evData.map((v) => v.seats_max));
    // this.maxSeats = Math.max.apply(null, evData.map((v) => v.seats_max));

    // this.minRange = Math.min.apply(null, evData.map((v) => v.total_range));
    // this.maxRange = Math.max.apply(null, evData.map((v) => v.total_range));
    // console.log('MIN RANGE STUFF', this.minSeats, this.maxSeats);

    // Load state from url query params OR default
    this.state.searchInput = this.$route.query.searchInput || this.state.searchInput;
    this.state.sortKey = this.$route.query.sortKey || this.state.sortKey;
    this.state.sortDirection = this.$route.query.sortDirection || this.state.sortDirection;
    this.state.filterMake = this.$route.query.filterMake || this.state.filterMake;
    this.state.filterFormFactor = this.$route.query.filterFormFactor
      || this.state.filterFormFactor;
    this.state.filterSeats = this.$route.query.filterSeats || this.state.filterSeats;
    this.state.filterRange = this.$route.query.filterRange || this.state.filterRange;
    this.state.filterFormFactor = this.$route.query.filterFormFactor || this.state.filterFormFactor;
    this.state.filterFuelType = this.$route.query.filterFuelType || this.state.filterFuelType;
    this.state.filterLiked = this.$route.query.filterLiked || this.state.filterLiked;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
