<template>
  <div id="app" v-resize="setDimensions">
    <!-- For accessibility -->
    <h1 hidden> Electrification Tools </h1>
    <!-- eslint-disable -->
    <!-- Resets component every time a new route is loaded -->
    <!-- This ensures full reset when swapping between commercial and passenger ev comparison views -->
    <!-- Since data pulls happen here, and not in the components, this should be fine -->
    <!-- If this causes issues, I could instead swap to a "watch" on the route that resets data more manually -->
    <router-view :key="$route.path" />
  </div>
</template>
<script>

// Used for pulling data froom backend
import axios from 'axios';

export default {
  data() {
    // Store data at root "grandparent" level to avoid need to reload.
    // Also preserves "likes"
    // Data is pulled in by "parent" client components calling methods.
    return {
      ev_data: [],
      charger_data: [],
      incentive_data: [],
    };
  },
  mounted() {
    // Automatically log all clicked links. This will also fire on router links
    document.addEventListener('click', (e) => {
      if (e.srcElement.href) {
        this.logMessage('info', 'Clicked Link', e.srcElement.href);
      }
    });
  },
  methods: {
    // Works with functions in main.js to support screen resizing and dropdown navigation
    setDimensions({ width, height }) {
      this.$globalObservableData.width = width;
      this.$globalObservableData.height = height;
      this.$globalObservableData.showNavDropown = this.$mobile || width < 1000;
    },
    /* eslint-disable */
    getEVData() {
      // Pull liked vehicle list from query param and turn into set. Ternary just creates empty set if there is no query param.
      const likedIndex = new Set(this.$route.query.liked ? this.$route.query.liked.split(',').map(Number) : []);

      const path = `${this.$baseUrl}/ev_data`;
      axios.get(path)
        .then((res) => {
          // Filter for BEV and PHEV fuel types. This filtering could be pushed to the backend python code
          const evData = res.data.vehicle_data.filter(
            (car) => car.display_vehicle && (car.image_url !== null || car.type === 'Commercial') && (car.fuel === 'BEV'|| car.fuel === 'PHEV')).sort(
            (a, b) => {
            const keyA = `${a.make} ${a.display_name} (${a.model_year}`;
            const keyB = `${b.make} ${b.display_name} (${b.model_year}`;
            return keyA < keyB ? -1 : (keyA > keyB ? 1 : 0)
          });
          // Augment fields for assorted functionality.
          for (let i = 0; i < evData.length; i += 1) {
            evData[i].index = i;
            // Compute MpkWh efficiency metric from electric range (miles) / battery size (kWh)
            evData[i].miles_per_kwh = evData[i].electric_range / evData[i].battery_capacity;
            // This is what powers the search functionality. If you want to be able to search by a different field, add it here.
            evData[i].carStr = `${evData[i].make} ${evData[i].display_name} (${evData[i].model_year}) ${evData[i].model} ${evData[i].trim} ${evData[i].model_year} ${evData[i].form_factor} ${evData[i].fuel}`.toUpperCase();
            // Supports liked comparison and it's copy link function
            evData[i].liked = false;
            if (likedIndex.has(i)) {
              evData[i].liked = true;
            }
          }
          // Sort vehicles by display title (make display name (year))
          // TODO: this display name could be one of the augmented fields, simplifying display in Vehicle Comp and Savings Calc
          this.ev_data = evData;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    getChargerData() {
      const likedIndex = new Set(this.$route.query.liked ? this.$route.query.liked.split(',').map(Number) : []);

      const path = `${this.$baseUrl}/ev_charger_data`;
      axios.get(path)
        .then((res) => {
          // console.log(res);
          // I wanted to do this as a forEach, but for some reason it doesn't let liked toggle
          // Matched for i setup of vehicle getting instead.
          const data = res.data.charger_data;
          for (let i = 0; i < data.length; i += 1) {
            data[i].index = i;
            data[i].liked = false;
            if (likedIndex.has(i)) {
              data[i].liked = true;
            }
          }
          this.charger_data = data;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    getIncentiveData() {
      const path = `${this.$baseUrl}/incentives`;
      axios.get(path)
        .then((res) => {
          // console.log(res);
          // Filter to Transportation only incentive.
          // TODO if we add Home category back in: will need to parameterize this and the corresponding dropdown filter
          this.incentive_data = res.data.incentive_data.filter((incentive) => incentive.Category === 'Transportation');
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
          return 
        });
    },
  },
  created() {
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Driven to Electrify';
      },
    },
  },
};
</script>

<!-- 
  Root "grandparent" styles apply across all components.
  Preferable to make CSS changes here when possible to reflect sitewide.
  They can be overridden by client parent components, which in turn are overridden by tool child components.
  var(...) values used for color are defined in client parent components
-->
<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
#app {
  /*display: flex;*/
  /*display: inline-block;*/
  align-items: left;
  font-family: Inter, sans-serif;
  --font-weight-heavy: 900;
  --font-weight-medium: 600;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
/*  --light-accent: #FFF6DF;
  --Accent: #CF9403;*/
  --borderRadiuslarge: 10px;
  /* Colors */
  /* Client custom */
  --accent: #B78300;
  --light-accent: #FFF6DF;
  /* Global Colors */
  --grey-text: #637083;
  --black: #181818;
  --dark-grey: #494949;
  --light-grey: #F4F4F4;
  /*  --grey: #929292;*/
  /* Changed to this grey below to be accessibility safe on white background */
  --grey: #767676;
  --white: #FFFFFF;
  /* Utility/Colored Colors */
  --error-red: #D3150A;
  --success-green: #00890D;
  --pending-yellow: #EFBA00;
  --active-link-blue: #008BEF;
  /* Misc tool colors, eg savings chart */
  --savings-green: #0EAA00;
  --gasoline-brown: #B45340;
  --electric-blue: #003366;
}

a::hover {
  color: var(--active-link-blue);
}
#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 1rem;
}

/* Makes sure footer is below the initial page load eg for short content pages or before content loads */
/* Could probably be done with flex box or calc differently if we wanted the footer to display on the page without scroll in those cases */
#page-container main {
  min-height: 100vh;
}
footer {
  padding: 30px 40px 10px 40px;
  width: 100%;
  height: auto;
  text-align: left;
  background-color: var(--dark-grey);
}
footer div {
  color: var(--light-grey);
  background-color: var(--dark-grey);
}
footer hr {
  color: var(--light-grey);
  margin: 10px;
}
footer a {
  color: var(--light-grey);
  line-height: 2em;
  text-underline-offset: 3px;
}
footer a:hover {
  color: var(--grey);
}
footer .navbar {
  display: inline-block;
  vertical-align: top;
  padding-top: 0;
  font-size: 120%;
}

.google_translate {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
}
.skiptranslate .goog-te-gadget {
/*  margin:10px;*/
}

header {
  background-color: white;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  display: flex;
  box-shadow: 0 0 2px 0 black;
  /* Needs non static position to maintain dropshadow over background image */
  position: relative;
  z-index: 1;
}

h1, h2, h3, h4, h5, h6 {
  display:inline;
  /* Vertical center alignment */
  margin: auto 0;
  font-weight: var(--font-weight-heavy);
}
/* TODO adjust these to Figma sizes... */
h1, .t1 {
  line-height: 5rem;
  font-size: 72px;
}

h2, .t2 {
  line-height: 4rem;
  font-size: 64px;
}

h3, .t3 {
  line-height: 3rem;
  font-size: 48px;
}

h4, .t4 {
  line-height: 2.25rem;
  font-size: 36px;
}

h5, .t5 {
  line-height: 1.75rem;
  font-size: 28px;
}

h6, .t6 {
  line-height: 1.3125rem;
  font-size: 21px;
}

.large {
  line-height: 1.125rem;
  font-size: 18px;
}

.standard {
  line-height: 1rem;
  font-size: 16px;
}

.small {
  line-height: 0.75rem;
  font-size: 14px;
}

.tiny {
  line-height: 0.625rem;
  font-size: 12px;
}

html, body, main {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-top: 0;
  /*padding: 0;*/
  width: 100%;
  text-align: left;
  font-size: 100%;
  /* Prevent horizontal scrolling on mobile */
  max-width: 100%;
/*  overflow-x: hidden;*/
  /* 100% height removes weird double vertical scroll effect of overflow-x  */
/*  height: 100%;*/
}

main {
/*  margin-top: 100px;*/
}

header * {
  /*display: inline;*/
}

header img {
  height:60px;
  margin:20px;
}

.mobileNavDropdown {
  position: absolute;
  right: 20px;
  top: 40px;
  height: 40px;
  width: 40px;
  color: var(--client-color);
  background-color:var(--client-color);
}

nav {
  display: flex;
  z-index:1;
/*  position: fixed;*/
  font-size:120%;
  font-weight: bold;
  width: 100%;
  height: 100px;
  /*overflow: scroll;*/
  text-align: left;
}

nav a {
  font-weight: bold;
  color: black;
  text-decoration: none;
  margin: 0 10px 0 10px;
/*  color: var(--active-link-blue);*/
}
nav a:hover {
  color: var(--active-link-blue);
}

nav a.router-link{
  color: black;
}

nav a.router-link-active {
  text-decoration: underline;
  text-decoration-color: var(--accent);
  text-underline-offset: 10px;
  text-decoration-thickness: 2px;
}
.router-link:hover{
  color: black;
}


/* Made for driven sales page to highlight that tool description headers link to tools */
.inlineRouterLink {
  color: var(--client-color-darker);
  text-decoration: none;
}
.inlineRouterLink:hover {
  color: var(--client-color-darker);
  text-decoration: underline;
  text-decoration-thickness: 4px;
  text-decoration-color: var(--client-color);
}

hr {
  display: flex;
  z-index:-1;
  margin-top:30px;
  margin-bottom:30px;
  width:100%;
}

.headerHR{
  margin-top: 10px;
  margin-bottom: 20px;
}

.container {
  /* Offset from header */
  /*margin-top: 20px;*/
  /*display: inline-block;*/
/*  margin-left: 20px;
  margin-right: 20px;*/
  /*width: 100%;*/
  /*margin: auto;*/
}

.pageTitle {
/*  font-size:120%;*/
  margin-right: 20px;
  margin-top:5px;
  display: inline-block;
  width:auto;
  position: flex;
  vertical-align: center;
  /*margin-left:15px;*/
  font-weight: bold;
  text-align: left;
  color: var(--client-color-darker);
}

.navbar {
  padding-right: 180px;
  justify-content: left;
}

.paragraphGroup {
  /*font-size:24px;*/
  /*font-size: 150%;*/
  /*text-align: left;*/
}
.paragraph {
  margin-bottom: 50px;
  font-size:110%;
}
.subheader {
  font-size: 100%;
  line-height: 1.5;
}
.sectionheader {
  font-size: 120%;
  font-weight: bold;
}
.dollarDisplay {
  font-size:80%;
  color:var(--client-color);
}

/* The switch - the box around the slider */
.switch {
  vertical-align: middle;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  margin: 5px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 6px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
/*
header {
  position: fixed;
}*/
/* The navigation bar */
.controlPanel {
  /*overflow: hidden;*/
  /*display: flex;*/
/*  align-items: left;*/
  /* Position the controlPanel at the top of the page */
/*  position: fixed;*/
/*  top: 150px;*/
/*  left: 40px;*/
  height: fit-content;
  background-color: var(--light-grey);
/*  height: flex;*/
/*  display: flex;*/
/*  flex-wrap: wrap;*/
  vertical-align: middle;
  /*height: 40px;*/
  /*margin-bottom: 10px;*/
  /*width: auto;*/
  display: inline-block;
  /* Need min width rather than set widh or flex box parent container ignores it */
  min-width: 220px;
  /* Disabled max width to support expanding controlPanel when screen size shrinks */
  /* Todo may eventually replace this expansion with popup / show hide logic */
  max-width: 220px;
  background: var(--light-grey, #F4F4F4);
  padding: 10px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 6px 0px #00000033;
}

.controlPanel hr {
  height: 0;
  margin: 20px 0 20px 0;
/*  padding-left: 10px;*/
}
.controlPanel h6 {
  margin-bottom: 10px;
  display: block;
  font-weight: var(--font-weight-medium);
}
/********** Range Input Styles **********/
/*Range Reset*/
input[type="range"] {
   -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 100%;
}

/* Removes default focus */
input[type="range"]:focus {
  outline: none;
}

/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider track */
input[type="range"]::-webkit-slider-runnable-track {
  background-color: var(--grey);
  border-radius: 0.5rem;
  height: 2px;  
}

.sliderContainer {
  margin: 10px;
}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -12px; /* Centers thumb on the track */

  /*custom styles*/
  height: 24px;
  width: 24px;
  background-color: white;
  border-radius: 12px;
  border: 2px solid var(--dark-grey);
}

input[type="range"]:focus::-webkit-slider-thumb {   
/*  border: 2px solid var(--grey);*/
  outline: 2px solid var(--grey);
/*  outline-offset: 0.125rem; */
}

/******** Firefox styles ********/
/* slider track */
input[type="range"]::-moz-range-track {
  background-color: var(--grey);
  border-radius: 0.5rem;
  height: 2px; 
}

/* slider thumb */
input[type="range"]::-moz-range-thumb {
  border: none; /*Removes extra border that FF applies*/
  border-radius: 0; /*Removes default border-radius that FF applies*/

  /*custom styles*/
  /* Not sure why - maybe it's border, but seems to needs smaller height */
  /* Maybe calculation with border is different??? */
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 12px;
  border: 2px solid var(--dark-grey);
}

input[type="range"]:focus::-moz-range-thumb {
  outline: 2px solid var(--grey);
}


/* Links inside the controlPanel */
.controlPanel a {
  float: left;
  display: block;
  text-align: left;
  /*padding: 14px 16px;*/
  text-decoration: none;
}

select {
/*  height: 100%;*/
  background-color: white;
/*  border: solid var(--client-color);*/
/*  border-radius: 4px;*/
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  margin-bottom: 20px;
  width: 100%;
  height: 38px;
  border-radius: 8px;
  border: 1px 0px 0px 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
    overflow: hidden;
  /* Positions dropdown arrow at end - 10px using calc, vertically in the middle */
  background: url("assets/dropdown.svg") no-repeat calc(100% - 10px) center;
  background-color: var(--white);
  padding-left: 10px;
  padding-right: 25px;

  border: 1px solid var(--grey);

  color: var(--grey);
}

/* Grey out "placeholder" option text for clarity */
select:has(option:checked:not([value])),
select:has(option:checked:not([value=""])) {
  color: black;
}

select:focus, input:focus {
  outline-color: var(--grey);
}

.textinput {
  background-color: white;
  border-width: 3px;
  border-radius: 10px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  margin-bottom: 20px;
/*  padding: .2em;*/
  width: 100%;
  height: 38px;
  border-radius: 8px;
  border: 1px solid var(--grey);
}

/* Make sure placeholder text matches select dropdown item text color */
.textinput::placeholder {
  color: var(--grey);
}

.iconMobile {
  margin: auto auto;
  height: auto;
  vertical-align: middle;
  clear: both;
  color: var(--accent);
  width:50px;
  margin:auto;
}

.iconAddonWrapper {
  position: relative;
}
.iconAddonLeft {
  position: absolute;
  padding-top: 12px;
  padding-left: 10px;
  pointer-events: none;
  left:  0px;
/*  margin-right: 10px;*/
}
/* Override textinput padding above to support glyph icon. */
.iconAddonWrapper .textinput {
  padding-left: 30px;
}

.numberInputRight {
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
}

.numberInputRight::after {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translate(0, -50%);
    /*  Offsets margin-bottom of .numberInput  */
/*    padding-bottom: 20px;*/
    content: attr(data-endtext);
    pointer-events: none;
    color: var(--grey);
}

.numberInput {
  background-color: white;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
/*  margin-bottom: 20px;*/
  padding: .2em;
  height: 38px;
  width: 200px;
  z-index: 0;
  position: relative;
  padding-left: 10px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--grey);
}

input:disabled {
  background-color: var(--light-grey);
}


.buttonStyle, .buttonStyleDark, .buttonStyleAccent, .buttonStyleAccentDark {
  color: black;
/*  background-color: var(--light-grey);*/
  background-color: var(--white);
  line-height: 1.5em;
  display: inline-block;
  vertical-align: center;
  font: inherit;
  font-weight: bold;
  margin: 10px;
  width: 100%;
  height: 38px;
  border: 1px solid var(--dark-grey);
  gap: 8px;
  border-radius: 8px;
  border: 1px 0px 0px 0px;
  width: auto;
  padding:0 10px;
/*  box-shadow: 0px 0px 6px 0px #00000033;*/
}

.itemBlock button {
  margin-left: 0;
}

/* Override width / margin for control panel */
.controlPanel button {
  width: 100%;
  margin: 0;
  margin-bottom: .5em;
  background: var(--light-grey);
/*  box-shadow: 0px 0px 6px 0px #00000033;*/
}

.buttonFloating {
  margin: .5em;
  width:auto;
  padding:0 10px; 
}

.buttonStyleDark {
  color: white;
  background-color: var(--dark-grey);
}

.buttonStyleAccent {
  color: var(--accent);
  border-color: var(--accent);
}

.buttonStyleAccentDark{
  color: white;
  background-color: var(--accent);
  border-color: var(--accent);
}

/* Darken/brighten buttons accordingly on hover to show they're interactable */
.buttonStyleAccent:hover, .buttonStyle:hover {
  filter: brightness(80%);
}

.buttonStyleAccentDark:hover, .buttonStyleDark:hover {
  filter: opacity(80%);
}

.likeButton {
  /* Following is duplicate of buttonStyle above */
  /* There is definitely a better way of doing this by extending the above class, but this is the quick solution */
  /* TODO: have this extend buttonStyle in such a way that the @media functions don't bump width of this to 100% */
  color: black;
  background-color: white;
  border: solid var(--client-color);
  border-radius: 4px;
  line-height: 1.5em;
  display: inline-block;
  vertical-align: center;
  font: inherit;
  font-weight: bold;
  margin: .2em;
  padding: .1em;
  /*margin-bottom: */
  width: 200px;
  height: 38px;
  border-width:3px;
  border-radius:10px;
  /*height: 100%;*/
  /*margin: 5px;*/

  /* DIFFERENCE FROM buttonStyle - fixed size, separated so that width does not expand to 100% on mobile view via @media function */
  float: right;
  border:none;
  height:30px;
  width:30px;
  color: var(--accent);
}

.controlPanelItemListWrapper {
  display: flex;
}

.itemListWrapper {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  margin-top: 0;
  /* Adds some padding so that blocks don't get quite so close to control panel */
  /* Note: itemblock left margin also contributes to this gap! Also: may need to tweak to get 3 items per line for a normal laptop */
  padding-left: 20px;
}
/* Flex rows */
.itemList {
  /* Using flex box with row wrapping instead of inline blocks to support all blocks being the same automatic height to fit content of tallest block */
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: right;
/*  margin-left: 20px;*/
}

.itemBlock {
  vertical-align: top;
  /* No more than 100% width to handle mobile devices and prevent overflow/scrolling */
  width: min(375px, 100%);
/*  height: 700px;*/
  margin-bottom: 20px;
  margin-left: 20px;
  background-color: white;
  padding: 20px;
/*  border-color: var(--client-color);*/
/*  box-shadow: 10px;*/
/*  border-radius: 20px;*/
/*  border-width:4px;*/
  box-shadow: 0px 0px 6px 0px #00000033;

/*  border-style:solid;*/
  text-align: left;
  position: relative;
/*  display: flex;*/
/*  flex-direction: column;*/
}

.vehicleIncentiveBox {
  background-color: var(--light-accent);
  padding: 16px;
  border: 1px solid var(--accent);
  color:var(--accent);
  border-radius: var(--borderRadiuslarge);
  margin-top: 10px;
}

.centered {
  display: flex;
  /*justify-content: center;*/
}

.html {
  width: 100%; height: 100%;
}
.head {
  width: 100%;
}

/* Heart like button icon */
/*.fa-heart-o {
  color: red;
  cursor: pointer;
}

.fa-heart {
  color: red;
  cursor: pointer;
}
*/
/* Search bar */
.searchspan {
  margin-right: 6px;
  margin-top: -20px;
  position: relative;
  left: -245px;
}

.carousel-control-next-icon {
  background-image: none; /* Remove the default background image */
  display: inline-block; /* Display as an inline-block */
  width: 30px; /* Set a width for the icon */
  height: 30px; /* Set a height for the icon */
  background-color: transparent; /* Make the background transparent */
  position: relative; /* Position relative for the pseudo-element */
}

.carousel-control-next-icon:after {
  content: '>'; /* Use the > character as content */
  position: absolute; /* Absolute position to center in the parent */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(0, -50%); /* Offset by half of its size to truly center */
  color: black; /* Set the color of the > character */
  font-size: 50px; /* Set the size of the > character */
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5); /* Apply drop shadow */
}

.carousel-control-prev-icon {
  background-image: none; /* Remove the default background image */
  display: inline-block; /* Display as an inline-block */
  width: 30px; /* Set a width for the icon */
  height: 30px; /* Set a height for the icon */
  background-color: transparent; /* Make the background transparent */
  position: relative; /* Position relative for the pseudo-element */
}

.carousel-control-prev-icon:after {
  content: '<'; /* Use the > character as content */
  position: absolute; /* Absolute position to center in the parent */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(0, -50%); /* Offset by half of its size to truly center */
  color: black; /* Set the color of the > character */
  font-size: 50px; /* Set the size of the > character */
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5); /* Apply drop shadow */
}

/* Override Bootstrap Carousel Indicators */
.carousel-indicators li {
  background-color: #fff; /* Change to a darker color for visibility */
  width: 12px; /* Increase width for larger indicators */
  height: 12px; /* Increase height for larger indicators */
  border-radius: 50%; /* Maintain the circular shape */
  border: 2px solid #fff; /* Add a white border for contrast */
  box-shadow: 0 0 2px #000; /* Optional: Add a shadow for more depth */
  margin: 10px;
}

.carousel-indicators {
  position: static; /* Remove the absolute positioning */
  text-align: center; /* Center align the indicators */
  padding-top: 20px; /* Add some space above the indicators */
}
/* Add margins for controls and indicators */
.carousel {
/*  margin-bottom: 20px;*/
  padding-left: 120px;
  padding-right: 120px;
}

.carousel-indicators .active {
  background-color: #555; /* Different color for the active indicator */
}

/* Set a fixed height for the carousel slides */
.carousel-item {
/*  max-height: 600px;*/
}

/* Adjust the images within each slide */
.carousel-item img {
  height: 100%;
  width: auto;
  object-fit: cover; /* This ensures images cover the slide area while maintaining aspect ratio */
}

/* Adjust to auto height, full width (one car/charger/etc and input per row) on small screens */
@media (max-width : 1000px) {
  .itemList, .itemListWrapper, .itemBlock {
    width:100%;
    height: auto;
    margin-left: 0;
  }
  .itemList {
    justify-content: left;
  }
  .itemBlock {
    margin-left: 0;
  }
  .itemListWrapper {
    padding-left: 0;
  }
  .controlPanel {
    width: 100%;
    max-width: 100%;
  }
  .controlPanelItemListWrapper {
    display: block;
  }
  select {
    width: 100%;
  }
  input {
    width: 100%;
  }
  button {
    width: 100%;
  }
  .numberInputRight {
    width: 100%;
  }
  .numberInput {
    width: 100%;
  }
  .buttonStyle, .buttonStyleDark, .buttonStyleAccent, .buttonStyleAccentDark {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .chartContainer {
    min-width: 100%;
  }
}
</style>