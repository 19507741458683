<template>
  <!-- eslint-disable max-len -->
  <div id="content-wrap">
      <div style="align-content: center; height: auto; margin: 0 0 20px 0;">
        <span class="subheader" style="font-size: 20px; color: var(--dark-grey); font-weight: 600; float: left; margin-top: 15px;">
          {{getFilteredDealerships().length}} results
        </span>
        <!-- Do we really need a copy link button here? probably not.. -->
<!--         <button class="buttonStyleAccent"
          id="copy-link"
          aria-label="copy link"
          @click="copyLink()"
          style="margin-right: 0; float:right">
          <font-awesome-icon icon="fa-solid fa-link" />
          Copy Link
        </button> -->

<!--         <button class="buttonStyleAccentDark"
        @click="state.filterLiked = !state.filterLiked; logMessage('info', 'Compare Liked Cars', state.filterLiked)"
        style="float:right"
          aria-labelledby="compare-liked">
          <font-awesome-icon :icon="state.filterLiked
            ? 'fa-solid fa-heart' : 'fa-regular fa-heart'"/>
            Compare Favorites ({{numLiked}})
        </button> -->
      </div>
    <div class="controlPanelItemListWrapper">
      <div class="controlPanel" style="">
        <div class="iconAddonWrapper">
          <img src="../../assets/search.svg" alt="search icon" class="iconAddonLeft">
          <GmapAutocomplete
            @place_changed='setPlace'
            placeholder="Search location..."
            class="textinput"
          />
        </div>
        <select v-model="filterMake" @change="logMessage('info', 'Filter Make', filterMake)"
          style="margin-bottom:0"
          aria-labelledby="filter-make"
          name="year"
          id="year"
          class="round">
            <option value="" selected>All Makes</option>
            <option v-for="(make, index) in makeOptions" :key="index"> {{make}} </option>
        </select>
  <!--       <button class="buttonStyle"
          @click="this.geolocate()">
          <font-awesome-icon icon="fa-regular fa-circle-xmark" />
          Reset Filters
        </button> -->
        <button class="buttonStyle"
          @click="dealerPartnersOnly = !dealerPartnersOnly; logMessage('info', 'Filter Dealer Partners Only', dealerPartnersOnly)" v-if="$parent.client_api_path === 'nvenergy'"
          style="margin-top: 20px">
            <div v-if="dealerPartnersOnly">
              <font-awesome-icon icon="fa-solid fa-handshake" />
              <span style="color:black;"> Dealer Partners </span>
            </div>
            <div v-else>
              <font-awesome-icon icon="fa-solid fa-car" />
              <span style="color:black;"> All Dealers </span>
            </div>
          </button>
      </div>
      <!-- TODO make this float or auto sized rather than margin based -->
      <div class="itemListWrapper">
        <div id="dealerList" class="itemList">
        <GmapMap class="itemBlock"
          :center='center'
          :zoom='zoom'
          style='width:calc(100%); height: 400px; '
        >
      <!--     <GmapMarker
            :position="{ lat: 36.1716, lng: -115.1391}"
          /> -->
          <!-- <GmapCluster> -->
          <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
          </gmap-info-window>
          <GmapMarker
            :key="index"
            v-for="(m, index) in getFilteredDealerships()"
            :position="m.position"
            :title="m.Account_Name"
            :clickable="true" @click="toggleInfoWindow(m,index)"
            :draggable="false"
            :icon="m.icon"/>
          />
        <!-- </GmapCluster> -->
        </GmapMap>
          <!-- TODO: dynamic height, hide unset fields, etc --><!--
              fields=["Account_Name",
      "Billing_Street", "Billing_City", "Billing_State", "Billing_Code", "latitude", "longitude",
      "Website", "Company_Email", "Phone", "Relationship_to_REACH"] -->
          <div v-for="(dealer, index) in getFilteredDealerships()"
            v-bind:key="index"
            class="itemBlock">
            <h5>{{dealer.Account_Name}}</h5>
            <div style="display: flex; justify-content: left;">
              <button class="buttonStyleDark" style="width:auto; padding: 0 10px;"
                  @click="changeRoute(`https://www.google.com/maps/place/${dealer.latitude},${dealer.longitude}`)">
                <font-awesome-icon icon="fa-regular fa-compass" />
                Get Directions
              </button>
              <button class="buttonStyle" style="width:auto; padding: 0 10px;"
                @click="changeRoute(dealer.Website)">
                <font-awesome-icon icon="fa-solid fa-circle-info" />
                Learn More
              </button>
            </div>
            <div style="">
              <div v-if="dealer.haversine_distance">
                <b>Distance: </b> <span>{{dealer.haversine_distance | numberTwoDecimal}} miles</span>
                <br />
              </div>
              <div v-if="dealer.Billing_Street">
                <b>Street: </b> <span>{{dealer.Billing_Street}}</span>
                <br />
              </div>
              <div v-if="dealer.Billing_City">
                <b>City: </b> <span>{{dealer.Billing_City}}</span>
                <br />
              </div>
              <div v-if="dealer.Billing_State">
                <b>State: </b> <span>{{dealer.Billing_State}}</span>
                <br />
              </div>
              <div v-if="dealer.Billing_Code">
                <b>Zip Code: </b> <span>{{dealer.Billing_Code}}</span>
                <br />
              </div>
              <div v-if="dealer.Company_Email">
                <b>Email: </b> <span>{{dealer.Company_Email}}</span>
                <br />
              </div>
              <div v-if="dealer.Phone">
                <b>Phone: </b> <span>{{dealer.Phone}}</span>
                <br />
              </div>

              <div v-if="dealer.Description" style="width: 70%">
                <b>Description:</b> <br/>
                <span style="white-space: pre-wrap;">{{dealer.dealer_Description}}</span>
              </div>
            </div>
            <div
              v-if="$parent.client_api_path == 'nvenergy' && dealer.Program && dealer.Program.includes('NVE Dealer Partner')"
              style="vertical-align: bottom;">
              <div style="height: 90px"></div>
              <img alt="NVEnergy" class="powershiftLogo"
                style="position: absolute; bottom: 10px"
                src="../../assets/NVE_DealerSeal_Upd_CMYK.png"
                />
            </div>
          </div>
        </div>
        <!-- <img v-bind:src="event.Event_Image" width=400 alt="Car"  /> -->
      </div>
    </div>
  </div>

</template>

<script>
/* eslint-disable no-console */
import axios from 'axios';

// GMAP vue lib at https://github.com/xkjyeah/vue-google-maps
import { gmapApi } from 'vue2-google-maps';

export default {
  data() {
    return {
      dealerships: [],
      filterMake: '',
      makeOptions: new Set(),
      dealerPartnersOnly: false,

      // Google Maps options
      center: this.$parent.center ? this.$parent.center : { lat: 38.75, lng: -115.1391 },
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      zoom: this.$parent.zoom ? this.$parent.zoom : 6,

      infoOptions: {
        content: '',
        // optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
    };
  },
  mounted() {
    this.geolocate();
  },
  computed: {
    google: gmapApi,
  },
  methods: {
    /* eslint-disable max-len */
    haversine_distance(mk1, mk2) {
      const R = 3958.8; // Radius of the Earth in miles
      const rlat1 = mk1.lat * (Math.PI / 180); // Convert degrees to radians
      const rlat2 = mk2.lat * (Math.PI / 180); // Convert degrees to radians
      const difflat = rlat2 - rlat1; // Radian difference (latitudes)
      const difflon = (mk2.lng - mk1.lng) * (Math.PI / 180); // Radian difference (longitudes)

      const d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
      // console.log(mk1, mk2, d);
      return d;
    },
    setPlace(place) {
      // console.log(place);
      this.zoom = 12;
      this.center = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
      this.logMessage('info', 'Search Map Location', JSON.stringify(this.center));
      this.dealerships.forEach((dealership, index) => {
        this.dealerships[index].haversine_distance = this.haversine_distance(this.center, dealership.position);
      });
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.logMessage('info', 'Set User Location On Load', JSON.stringify(this.center));
        this.dealerships.forEach((dealership, index) => {
          this.dealerships[index].haversine_distance = this.haversine_distance(this.center, dealership.position);
        });
      });
    },
    toggleInfoWindow(marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoOptions.content = marker.infoText;

      // check if its the same marker that was selected if yes toggle
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        // if different marker set infowindow to open and reset current marker index
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    getFilteredDealerships() {
      const filtered = this.dealerships.filter(
        (dealer) => {
          // console.log(this.dealerPartnersOnly);
          if (this.dealerPartnersOnly && dealer.Program && !dealer.Program.includes('NVE Dealer Partner')) {
            return false;
          }
          return !this.filterMake || dealer.OEM.includes(this.filterMake);
        },
      );
      const sorted = filtered.sort((a, b) => a.haversine_distance - b.haversine_distance);
      return sorted;
    },
    getDealerships() {
      const path = `${this.$baseUrl}/data/${this.$parent.client_api_path}/dealerships`;
      axios.get(path)
        .then((res) => {
          // console.log(res);
          this.dealerships = res.data.dealerships;
          res.data.dealerships.forEach((dealer, index) => {
            dealer.OEM.forEach((make) => this.makeOptions.add(make));
            const infoText = `
            <b>${dealer.Account_Name}</b><br/>
            <a href=${dealer.Website} target="_blank">Website</a>, <a href=https://www.google.com/maps/place/${dealer.latitude},${dealer.longitude} target="_blank">Get Directions</a><br/>
            <b>Address:</b> ${dealer.Billing_Street}, <br/> ${dealer.Billing_City},
            ${dealer.Billing_State}, ${dealer.Billing_Code}<br>
            `;
            const position = { lat: dealer.latitude, lng: dealer.longitude };
            this.dealerships[index].infoText = infoText;
            this.dealerships[index].position = position;
            this.dealerships[index].haversine_distance = this.haversine_distance(this.center, position);
            if (this.$parent.client_api_path === 'nvenergy' && !dealer.Program.includes('NVE Dealer Partner')) {
              this.dealerships[index].icon = 'https://maps.google.com/mapfiles/ms/icons/red-dot.png';
            } else {
              this.dealerships[index].icon = 'https://maps.google.com/mapfiles/ms/icons/green-dot.png';
            }
            // return;
            // return dealer;
            // return { infoText, position };

            // markers can only be keyboard focusable when they have click listeners
            // open info window when marker is clicked
            // marker.addListener("click", () => {
            //   infoWindow.setContent(label);
            //   infoWindow.open(map, marker);
            // });
            // return marker;
          });
          this.makeOptions = Array.from(this.makeOptions).sort();
          // this.markers = markers;

          // console.log(res.data, this.markers, this.makeOptions);
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
  },
  created() {
    this.getDealerships();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.powershiftLogo {
  width: 150px;
}

.logoBlock{
  position: absolute;
  bottom: 10px;
  right: 10px;
/*  float: right;*/
  /*position: relative;*/
  color: green;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
}

.itemBlock {
/*  margin: 10px;*/
/*  min-width: 400px;*/
/*  height: auto;*/
/*  text-overflow: wrap;*/
  align-items: top;
}

/* Map tool actually makes sense to justify list blocks below the map to the left */
/* It looks better that way, and copy link still stays lined up with map! */
.itemList {
  justify-content: left;
/*  padding: 0;*/
}
.itemListWrapper {
/*  padding: 0;*/
}
.textinput {
/*  width: 250px;*/
}
</style>
