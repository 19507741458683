<template>
<!-- eslint-disable max-len -->
  <div>
    <div id="content-wrap">
<!--       <div>
        <h1 class="pageTitle" id="title" role="banner">
          Compare EV Chargers
        </h1>
      </div>
      <h2 class="subheader">
        Find Level 2 EV Chargers that fit your needs. All chargers shown are Energy Star and UL Certified.
        <br><br>
        You can look through all the chargers below. Or use the filters to limit the types of chargers you're viewing.
        <ul>
          <li>
            Search by keywords. Filter the results by features like Wifi or Bluetooth Connectivity.
          </li>
          <li>
            Sort chargers by MSRP, Amperage, and Name. Toggle sort order between Low to High or High to Low.
          </li>
        </ul>
        <font-awesome-icon icon="fa-solid fa-heart" style="margin-left: 10px; margin-right: 10px;"/>Click the heart button to like your favorite chargers. Then "Compare Liked" to see only the chargers you have liked.
        <br><br>
        <font-awesome-icon icon="fa-solid fa-link" style="margin-left: 10px; margin-right: 10px;"/>Click the "Copy Link" button. Then paste into a message to share your filtered view with friends and family.
      </h2>
      <br><br>
      <div>
          <h2 class="subheader">
          <font-awesome-icon icon="fa-solid fa-bolt" style="margin-left: 10px; margin-right: 10px;"/>REMINDER: It’s important to know the amperage of the charger you plan to install. Work with a certified electrician to plan for including your new charger into your home's existing electrical system.
        </h2>
      </div>
      <div>
        <div v-if="$parent.ev_charger_description">
          <br>
          <font-awesome-icon icon="fa-solid fa-circle-info" style="margin-left: 10px; margin-right: 10px;"/>
          <h2 class="subheader" v-html="$parent.ev_charger_description"></h2>
        </div>
      </div> -->
      <div style="align-content: center; height: auto; margin: 0 0 20px 0;">
        <span class="subheader" style="font-size: 20px; color: var(--dark-grey); font-weight: 600; float: left;">
          {{totalItems}} results
          <br>
           <span style="font-size: 16px">* Energy Star & UL Certified</span>

        </span>
        <button class="buttonStyleAccent"
          id="copy-link"
          aria-label="copy link"
          @click="copyLink()"
          style="margin-right: 0; float:right">
          <font-awesome-icon icon="fa-solid fa-link" />
          Copy Link
        </button>

        <button class="buttonStyleAccentDark"
        @click="state.filterLiked = !state.filterLiked; logMessage('info', 'Compare Liked Cars', state.filterLiked)"
        style="float:right">
          <font-awesome-icon :icon="state.filterLiked
            ? 'fa-solid fa-heart' : 'fa-regular fa-heart'"/>
            Compare Favorites ({{numLiked}})
        </button>
      </div>
      <div class="controlPanelItemListWrapper">
        <div class="controlPanel" aria-label="control panel" role="form" style="">
          <div class="iconAddonWrapper">
            <img src="../../assets/search.svg" alt="search icon" class="iconAddonLeft">
            <input v-model="state.searchInput" @change="logMessage('info', 'Search', state.searchInput)"
              aria-label="search"
              role="search"
              type="text"
              id="searchInput"
              placeholder="Search"
              class="textinput">
          </div>
          <select v-model="state.filterMake" @change="logMessage('info', 'Filter Make', state.filterMake)"
            aria-label="filter make"
            id="make-model"
            name="make-model"
            class="round">
            <option value="" selected>All Makes</option>
            <!-- Dynamically compute options from the data. Automatically updates if options are added/removed from data -->
            <option v-for="(val, index) in $parent.$parent.charger_data.map(
                (item) => item.make
              ).filter(
                (value, index, current_value) => current_value.indexOf(value) === index).sort()" :key="index"> {{val}}
            </option>
          </select>
          <select v-model="state.filterSpecialFeatures" @change="logMessage('info', 'Filter Wifi Enabled', state.filterSpecialFeatures)"
            style="margin-bottom: 0;"
            aria-label="filter smart connection"
            name="wifi_enabled"
            id="wifi_enabled"
            class="round">
              <option value="" selected>All Features</option>
              <!-- Hardcoded options due to this field being a multiselect -->
              <option value="Wifi Connected">Wifi Connected</option>
              <option value="Bluetooth Connected">Bluetooth Connected</option>
              <option value="Scheduling">Scheduling</option>
          </select>
          <hr>
          <select v-model="state.sortKey" @change="logMessage('info', 'Sort By', state.sortKey)"
            aria-label="sort by"
            name="sort" id="sort"
            class="round">
              <option value='price_dollars'>Sort By: MSRP</option>
              <option value='amps' selected>Sort By: Amperage</option>
              <!-- <option value='volts'>Sort By: Voltage</option> -->
              <option value='Name'>Sort By: Name</option>
          </select>
          <select v-model="state.sortDirection" @change="logMessage('info', 'Sort Order', state.sortDirection > 0 ? 'Low to High' : 'High to Low')"
            aria-label="toggle sort order">
            <option value="1" selected>Low to High</option>
            <!-- Options are hardcoded to display different human readable names -->
            <option value="-1"> High to Low </option>
          </select>
          <!-- TODO this reset needs to be updated every time state model is changed (or default vals are)
            I could see this leading to issues. TODO refactor so that these defaults only live in one place. -->
          <button class="buttonStyle"
            @click="state = {
              searchInput: '',
              sortKey: 'price_dollars',
              sortDirection: 1,
              filterMake: '',
              filterSpecialFeatures: '',
              filterLiked: false,
            }">
            <font-awesome-icon icon="fa-regular fa-circle-xmark" />
            Reset Filters
          </button>
        </div>
        <div class="itemListWrapper">
          <div class="itemList">
            <!-- V if only displays charger if image is present -->
            <div v-for="(charger, index) in getFilteredData()"
              v-bind:key="index"
              class="itemBlock"
              aria-labelledby="charger-make-model">
              <div>
                <button @click="charger.liked = !charger.liked; logMessage('info', 'Liked Charger', `${charger.make} {charger.model}`)"
                  class="likeButton"
                  aria-label="like button">
                  <font-awesome-icon :icon="charger.liked
                    ? 'fa-solid fa-heart' : 'fa-regular fa-heart'"
                    style="height: auto;" />
                </button>
                <div>
                  <span class='right' style="font-size: 100%;" id="charger-make-model">
                      <h6>{{charger.make}}</h6>
                      <br />
                      <span class="large">{{charger.model}}</span>
                  </span>
                </div>
                <div>
                    <div style="text-align: center;">
                      <img :src="getImgUrl(charger)"
                      style="max-height:300px; max-width: 100%; overflow: hidden; margin-bottom: 10px;"
                      :alt="charger.alt_tag" /></div>
<!--                     <button class="buttonStyle"
                      style="width: auto; padding: 0 10px; float: right;"
                      @click="changeRoute(charger.url)">
                      <font-awesome-icon icon="fa-solid fa-circle-info" />
                      Learn More
                    </button> -->
                    <b>Price:</b> <span>{{charger.price_dollars | currencyUSDWhole}}</span>
                    <br />

                    <b>Current:</b> <span>{{charger.amps | numberWhole}} Amps</span>
                    <br />

                    <b>Voltage:</b> <span>{{charger.volts | numberWhole}} Volts</span>
                    <br />
                    <div v-if="charger.Special_Features.length">
                      <b>Special Features:</b> <span>{{charger.Special_Features.join(', ')}}</span>
                    </div>
                </div>
                <div style="display:inline-block; width: 100%; height: 50px;"></div>
                <!-- Todo? consider adding icons to charger comp. -->
                <!-- <div style="position: absolute; right: 20px; bottom: 20px;">
                  <div>
                    <font-awesome-icon icon="fa-solid fa-solar-panel" class="iconMobile"
                      v-if="charger.Special_Features.includes('Wifi Connected')" />
                  </div>
                </div> -->
                <button class="buttonStyle"
                  style="width: auto; padding: 0 10px; margin-bottom: 0; position: absolute; left: 20px; bottom: 20px;"
                  @click="changeRoute(incentive.Resource_URL)">
                  <font-awesome-icon icon="fa-solid fa-circle-info" />
                  Learn More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
/* eslint-disable no-console */

export default {
  data() {
    return {
      /* eslint-disable-next-line */
      default_image: require('../../assets/EV_Chargers/electric-car-charging-station.jpg'),
      makes: [],
      state: {
        searchInput: '',
        sortKey: 'price_dollars',
        sortDirection: 1,
        filterMake: '',
        filterSpecialFeatures: '',
        filterLiked: false,
      },
      totalItems: 0,
      numLiked: 0,
      minPrice: 0,
      maxPrice: 0,

    };
  },
  mounted() {
  },
  methods: {
    copyLink() {
      // Turns state of filters/sort/search into a query parameterized url and copies to clipboard.
      // State vars are loaded on created(), with the exception of likes which are loaded in App.vue
      const queries = [];
      if (this.state.searchInput) {
        queries.push(`searchInput=${this.state.searchInput}`);
      }
      if (this.state.sortKey) {
        queries.push(`sortKey=${this.state.sortKey}`);
      }
      if (this.state.sortDirection) {
        queries.push(`sortDirection=${this.state.sortDirection}`);
      }
      if (this.state.filterMake) {
        queries.push(`filterMake=${this.state.filterMake}`);
      }
      if (this.state.filterSpecialFeatures) {
        queries.push(`filterSpecialFeatures=${this.state.filterSpecialFeatures}`);
      }
      if (this.state.filterLiked) {
        queries.push(`filterLiked=${this.state.filterLiked}`);
      }
      // Comma separated list of likes for use in query param using filter map
      const liked = this.$parent.$parent.charger_data.filter((charger) => charger.liked).map((charger) => charger.index).join(',');
      if (liked) {
        queries.push(`liked=${liked}`);
      }
      const path = `${[window.location.origin, this.$parent.client_api_path, 'charger_comparison?'].join('/').replaceAll('//', '/')}${queries.join('&')}`;
      navigator.clipboard.writeText(path);

      this.logMessage('info', 'Copy Link', path);
      return path;
    },
    getImgUrl(charger) {
      // Requires and gets route to image. Require setup here requires temporary lint disabling.
      // TODO we should consider a CDN setup to enable REACH members to easily add images.
      // Currently, charger images are added to /assets/EV_Chargers
      /* eslint-disable */
      try {
        return require(`../../assets/EV_Chargers/${charger.image_url}`);
      }
      catch (e) {
        // return require('../../assets/EV_Chargers/electric-car-charging-station.jpg');
        // Return null, handled by a v-if to simply not display the charger
        return null;
      }
      /* eslint-enable'...' */
    },
    getFilteredData() {
      // Filter
      const filtered = this.$parent.$parent.charger_data.filter((charger) => this.passesFilters(charger));
      // Update values for banner display of number and min to max
      this.totalItems = filtered.length;
      // https://stackoverflow.com/questions/42413319/get-min-value-from-a-nested-json-object
      this.minPrice = Math.min.apply(null, filtered.map((v) => v.price_dollars));
      this.maxPrice = Math.max.apply(null, filtered.map((v) => v.price_dollars));
      this.numLiked = filtered.filter((item) => item.liked).length;
      // Sort based on sort key and direction
      const sorted = filtered.sort((a, b) => {
        // Handle Name sorting using string comparison
        if (this.state.sortKey === 'Name') {
          return this.state.sortDirection
            * (a[this.state.sortKey].localeCompare(b[this.state.sortKey]));
        }
        // Handle other sorting using numeric comparison
        return this.state.sortDirection * (a[this.state.sortKey] - b[this.state.sortKey]);
      });
      return sorted;
    },
    passesFilters(charger) {
      // TODO - consider pulling out just specific fields like for EVs and Incentives.
      // Currently turns entire charger object into a stringified json blob to compare against.
      const chargerStr = JSON.stringify(charger);

      // Only show chargers with images
      if (!this.getImgUrl(charger)) {
        return false;
      }
      if (this.state.searchInput
        && !chargerStr.toUpperCase().includes(this.state.searchInput.toUpperCase())) {
        return false;
      }
      if (this.state.filterMake && charger.make !== this.state.filterMake) {
        return false;
      }
      if (this.state.filterSpecialFeatures && !charger.Special_Features.includes(this.state.filterSpecialFeatures)) {
        return false;
      }
      if (this.state.filterYear && charger.year !== this.state.filterYear) {
        return false;
      }
      if (this.state.filterLiked && !charger.liked) {
        return false;
      }
      return true;
    },
  },
  created() {
    // Initialize state from url query params OR defaults
    this.state.searchInput = this.$route.query.searchInput || this.state.searchInput;
    this.state.sortKey = this.$route.query.sortKey || this.state.sortKey;
    this.state.sortDirection = this.$route.query.sortDirection || this.state.sortDirection;
    this.state.filterMake = this.$route.query.filterMake || this.state.filterMake;
    this.state.filterSpecialFeatures = this.$route.query.filterSpecialFeatures || this.state.filterSpecialFeatures;
    this.state.filterLiked = this.$route.query.filterLiked || this.state.filterLiked;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.paragraph {
   margin-bottom: 10px;
}

</style>
