<template>
<!-- eslint-disable max-len -->
  <div>
    <div id="content-wrap">
      <div>
        <button class="buttonStyleAccentDark"
        style="padding: 0 10px; margin: 0; margin-bottom: 20px;"
        @click="changeRoute('https://www.ameren.com/missouri/business/electric-vehicles')">
        <!-- <font-awesome-icon icon="fa-solid fa-circle-info" /> -->
        ← Back to Electric Vehicles
      </button>
    </div>
<div class="t4 center" style="color:var(--accent)"> Find an Electrical Contractor Partner </div>
<div style="margin-top:20px" class="center">
Business and residential customers can rely on Ameren Missouri Electric Vehicle Partners (EVPs) to facilitate their work related to electric vehicles. Get start-to-finish project support from our network of contractors, electricians and building design professionals who can help with project analysis, planning, and installation. These professionals have the knowledge and experience to assist you in understanding the benefits of electric vehicles and accessing money-saving incentives that may be available for your project.
</div>
<br>
<div style="display: flex; flex-wrap:row; justify-content: center;">
  <div class="iconTextBlock">
    <img src="../../assets/amo_helmet.png" alt="search icon" class="iconTextBlockIcon">
    EVPs are dedicated to helping customers from start-to-finish on projects like EV charging installations.
  </div>
  <div class="iconTextBlock">
    <img src="../../assets/amo_doc.png" alt="search icon" class="iconTextBlockIcon">
    Our EVPs guide customers through the incentive application process to help them receive maximum reimbursement.
  </div>
  <div class="iconTextBlock">
    <img src="../../assets/amo_clipboard.png" alt="search icon" class="iconTextBlockIcon">
    Search the directory listing below to connect with contractors who can help with project analysis, planning and installation.
  </div>
</div>
<br>
<hr>
<div style="display: flex; flex-wrap:row; justify-content: center;">
  <div class="iconTextBlock" style="">
    <img src="../../assets/amo_evp_battery.png" alt="search icon" class="iconTextBlockIcon">

    <div>
      Projects Completed by EVP
      <ul>
        <li>1 battery = 1-2 projects</li>

        <li>2 batteries = 3-4 projects</li>

        <li>3 batteries = 5+ projects</li>
      </ul>
    </div>
  </div>
  <div class="iconTextBlock" style="">
    <!-- Manually make slightly shorter than other icons, quick way to get the scale looking similar. Not clean. -->
    <font-awesome-icon icon="fa-solid fa-magnifying-glass" class="iconTextBlockIcon" style="color:var(--accent); vertical-align: top; height:70px;" />
    <!-- <font-awesome-icon icon="fa-solid fa-circle-info" class="iconTextBlockIcon" style="color:var(--accent); height: 50px;" /> -->
    Use the “Search" box to search across all categories and use select from the dropdowns to filter by specific categories (e.g., Projects Completed or Company Name)
  </div>
</div>

<!--       <div>
        <h1 class="pageTitle" id="title" role="banner">
          Compare EV Chargers
        </h1>
      </div>
      <h2 class="subheader">
        Find Level 2 EV Chargers that fit your needs. All chargers shown are Energy Star and UL Certified.
        <br><br>
        You can look through all the chargers below. Or use the filters to limit the types of chargers you're viewing.
        <ul>
          <li>
            Search by keywords. Filter the results by features like Wifi or Bluetooth Connectivity.
          </li>
          <li>
            Sort chargers by MSRP, Amperage, and Name. Toggle sort order between Low to High or High to Low.
          </li>
        </ul>
        <font-awesome-icon icon="fa-solid fa-heart" style="margin-left: 10px; margin-right: 10px;"/>Click the heart button to like your favorite chargers. Then "Compare Liked" to see only the chargers you have liked.
        <br><br>
        <font-awesome-icon icon="fa-solid fa-link" style="margin-left: 10px; margin-right: 10px;"/>Click the "Copy Link" button. Then paste into a message to share your filtered view with friends and family.
      </h2>
      <br><br>
      <div>
          <h2 class="subheader">
          <font-awesome-icon icon="fa-solid fa-bolt" style="margin-left: 10px; margin-right: 10px;"/>REMINDER: It’s important to know the amperage of the charger you plan to install. Work with a certified electrician to plan for including your new charger into your home's existing electrical system.
        </h2>
      </div>
      <div>
        <div v-if="$parent.ev_charger_description">
          <br>
          <font-awesome-icon icon="fa-solid fa-circle-info" style="margin-left: 10px; margin-right: 10px;"/>
          <h2 class="subheader" v-html="$parent.ev_charger_description"></h2>
        </div>
      </div> -->
      <div style="align-content: center; height: auto; margin: 0 0 20px 0;">
        <span class="subheader" style="font-size: 20px; color: var(--dark-grey); font-weight: 600; float: left;">
          {{totalItems}} results
        </span>
<!--         <button class="buttonStyleAccent"
          id="copy-link"
          aria-label="copy link"
          @click="copyLink()"
          style="margin-right: 0; float:right">
          <font-awesome-icon icon="fa-solid fa-link" />
          Copy Link
        </button> -->

<!--         <button class="buttonStyleAccentDark"
        @click="state.filterLiked = !state.filterLiked; logMessage('info', 'Compare Liked Cars', state.filterLiked)"
        style="float:right">
          <font-awesome-icon :icon="state.filterLiked
            ? 'fa-solid fa-heart' : 'fa-regular fa-heart'"/>
            Compare Favorites ({{numLiked}})
        </button> -->
      </div>
      <div class="controlPanelItemListWrapper">
        <div class="controlPanel" aria-label="control panel" role="form" style="">
          <div class="iconAddonWrapper">
            <img src="../../assets/search.svg" alt="search icon" class="iconAddonLeft">
            <input v-model="state.searchInput" @change="logMessage('info', 'Search', state.searchInput)"
              aria-label="search"
              role="search"
              type="text"
              id="searchInput"
              placeholder="Search"
              class="textinput">
          </div>
          <select v-model="state.filterServing" @change="logMessage('info', 'Filter Make', state.filterServing)"
            aria-label="filter make"
            id="make-model"
            name="make-model"
            class="round">
            <option value="" selected>Serving All</option>
            <!-- Dynamically compute options from the data. Automatically updates if options are added/removed from data -->
            <!-- EDIT: not dynamically computing because only two options are Home and Home/Business, weird. -->
            <option value="Home" selected>Home</option>
            <option value="Business" selected>Business</option>
            <!-- <option v-for="(val, index) in evps.map(
                (item) => item.EVP_Home_or_Business
              ).filter(
                (value, index, current_value) => current_value.indexOf(value) === index).sort()" :key="index"> {{val}}
            </option> -->
          </select>
          <select v-model="state.filterBusinessType" @change="logMessage('info', 'Filter Wifi Enabled', state.filterBusinessType)"
            style=""
            aria-label="filter smart connection"
            name="wifi_enabled"
            id="wifi_enabled"
            class="round">
              <option value="" selected>All Business Types</option>
              <!-- Dynamic option generation uses flatmap/split here since each val can contain multiple options -->
              <!-- Comes in as newline separated, but we comma separate for display and override... this is ugly -->
              <option v-for="(val, index) in evps.flatMap(
                  (item) => item.EVP_Business_Types.split(/\r?\n/)
                ).filter(
                  (value, index, current_value) => value && current_value.indexOf(value) === index).sort()" :key="index"> {{val}}
              </option>
          </select>
          <select v-model="state.filterServicesProvided" @change="logMessage('info', 'Filter Wifi Enabled', state.filterServicesProvided)"
            style=""
            aria-label="filter smart connection"
            name="wifi_enabled"
            id="wifi_enabled"
            class="round">
              <option value="" selected>All Services</option>
              <!-- Dynamic option generation uses flatmap/split here since each val can contain multiple options -->
              <!-- Comes in as newline separated, but we comma separate for display and override... this is ugly -->
              <option v-for="(val, index) in evps.flatMap(
                  (item) => item.EVP_Services_Provided.split(/\r?\n/)
                ).filter(
                  (value, index, current_value) => value && current_value.indexOf(value) === index).sort()" :key="index"> {{val}}
              </option>
          </select>
          <select v-model="state.filterServiceAreas" @change="logMessage('info', 'Filter Wifi Enabled', state.filterServiceAreas)"
            style="margin-bottom: 0;"
            aria-label="filter smart connection"
            name="wifi_enabled"
            id="wifi_enabled"
            class="round">
              <option value="" selected>All Service Areas</option>
              <!-- Dynamic option generation uses flatmap/split here since each val can contain multiple options -->
              <!-- Comes in as newline separated, but we comma separate for display and override... this is ugly -->
              <!-- Filter out All since we surface that differently as a default above -->
              <option v-for="(val, index) in evps.flatMap(
                  (item) => item.EVP_Service_Areas.split(/\r?\n/).filter((item) => item != 'All')
                ).filter(
                  (value, index, current_value) => value && current_value.indexOf(value) === index).sort()" :key="index"> {{val}}
              </option>
          </select>
          <hr>
          <select v-model="state.sortKey" @change="logMessage('info', 'Sort By', state.sortKey)"
            aria-label="sort by"
            name="sort" id="sort"
            class="round">
              <!-- <option value='price_dollars'>Sort By: MSRP</option> -->
              <!-- <option value='amps' selected>Sort By: Amperage</option> -->
              <!-- <option value='volts'>Sort By: Voltage</option> -->
              <option value='Account_Name'>Sort By: Name</option>
              <option value='EVP_Projects_Completed_Batteries'>Sort By: Projects Completed</option>
          </select>
          <select v-model="state.sortDirection" @change="logMessage('info', 'Sort Order', state.sortDirection > 0 ? 'Low to High' : 'High to Low')"
            aria-label="toggle sort order">
            <option value="1" selected>Low to High</option>
            <!-- Options are hardcoded to display different human readable names -->
            <option value="-1"> High to Low </option>
          </select>
          <!-- TODO this reset needs to be updated every time state model is changed (or default vals are)
            I could see this leading to issues. TODO refactor so that these defaults only live in one place. -->
          <button class="buttonStyle"
            @click="state = {
              searchInput: '',
              sortKey: 'EVP_Projects_Completed_Batteries',
              sortDirection: -1,
              filterServing: '',
              filterBusinessType: '',
              filterServicesProvided: '',
              filterServiceAreas: '',
              filterLiked: false,
            }">
            <font-awesome-icon icon="fa-regular fa-circle-xmark" />
            Reset Filters
          </button>
        </div>
        <!-- {{evps}} -->
        <div class="itemListWrapper">
          <div class="itemList">
            <!-- V if only displays charger if image is present -->
            <div v-for="(item, index) in getFilteredData()"
              v-bind:key="index"
              class="itemBlock"
              aria-labelledby="charger-make-model">
              <div>
                <!-- <button @click="charger.liked = !charger.liked; logMessage('info', 'Liked Charger', `${charger.make} {charger.model}`)"
                  class="likeButton"
                  aria-label="like button">
                  <font-awesome-icon :icon="charger.liked
                    ? 'fa-solid fa-heart' : 'fa-regular fa-heart'"
                    style="height: auto;" />
                </button> -->
                <div>
                  <span class='right' style="font-size: 100%;" id="charger-make-model">
                      <h6>{{item.Account_Name}}</h6>
                      <br />
                      <!-- <span class="large">{{charger.model}}</span> -->
                  </span>
                </div>
                <div style="margin-top: 10px;">
                  <!-- <div v-if="dealer.haversine_distance">
                    <b>Distance: </b> <span>{{dealer.haversine_distance | numberTwoDecimal}} miles</span>
                    <br />
                  </div> -->
                  <!-- This is a bit jank, but basically description is plaintext multiline in the CRM pulled straight off of previous AMO site -->
                  <!-- So in order to properly format, we split each line into pairs of k,v when loading data -->
                  <!-- And then here we can display with bolding on k as desired -->
                  <!-- Ideally given more time I'd have separate fields for each v instead, or better yet pull contact, email, etc from pre-existing CRM fields.  -->
                  <!-- Edit: no longer a v-for because I need to make the email a link... still funky -->
                  <div v-for="pair, index in item.descriptionParsed" :key="index">
                    <b> {{pair[0]}}: </b>
                    <a v-if="pair[0] == 'Email'" :href="'mailto:' + pair[1]">{{pair[1]}}</a>
                    <a v-else-if="pair[0] == 'Website'" :href="'https://' + pair[1]" target="__blank">{{pair[1]}}</a>
                    <span v-else>{{pair[1]}}</span>
                  </div>
<!--                   <b>{{item.descriptionParsed[0][0]}}: </b> {{item.descriptionParsed[0][1]}}
                  <br>
                  <b>{{item.descriptionParsed[1][0]}}: </b> {{item.descriptionParsed[1][1]}}
                  <br>
                  <b>{{item.descriptionParsed[2][0]}}: </b> {{item.descriptionParsed[2][1]}}
                  <br>
                  <b>{{item.descriptionParsed[3][0]}}: </b> -->
                    <!-- By goly this feels hacky -->
                    <!-- If item is email, make it an email link -->
                    <!-- Otherwise it's a website (Sach's) but missing https:// so prepend that -->

                  <br>
                  <b>Serving: </b> <span>{{item.EVP_Home_or_Business}}</span>
                  <br />
                  <b>Business Type: </b> <span>{{item.EVP_Business_Types_display}}</span>
                  <br>
                  <b>Services Provided: </b> <span>{{item.EVP_Services_Provided_display}}</span>
                  <br />
                  <b>Service Areas: </b> <span style="">{{item.EVP_Service_Areas_display}}</span>
                  <br />
                  <!-- This is also a bit ugly, but reasonable and safe since there should only be 0-3 icons -->
                  <div v-if="item.EVP_Projects_Completed_Batteries" style="display:inline-block; width: 100%; height: 50px;"></div>
                  <div v-if="item.EVP_Projects_Completed_Batteries == 1" class="batteryIconWrapper">
                    <img src="../../assets/amo_evp_battery.png" alt="search icon" class="batteryIcon">
                  </div>
                  <div v-if="item.EVP_Projects_Completed_Batteries == 2" class="batteryIconWrapper">
                    <img src="../../assets/amo_evp_battery.png" alt="search icon" class="batteryIcon">
                    <img src="../../assets/amo_evp_battery.png" alt="search icon" class="batteryIcon">
                  </div>
                  <div v-if="item.EVP_Projects_Completed_Batteries == 3" class="batteryIconWrapper">
                    <img src="../../assets/amo_evp_battery.png" alt="search icon" class="batteryIcon">
                    <img src="../../assets/amo_evp_battery.png" alt="search icon" class="batteryIcon">
                    <img src="../../assets/amo_evp_battery.png" alt="search icon" class="batteryIcon">
                  </div>
                <!--   <div v-if="item.Description" style="width: 70%">
                    <b>Description:</b> <br/>
                    <span style="white-space: pre-wrap;">{{item.dealer_Description}}</span>
                  </div> -->
                </div>
<!--                 <button class="buttonStyle"
                  style="padding: 0 10px; margin-bottom: 0;"
                  @click="changeRoute(incentive.Resource_URL)">
                  <font-awesome-icon icon="fa-solid fa-circle-info" />
                  Learn More
                </button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
/* eslint-disable no-console */
import axios from 'axios';

export default {
  data() {
    return {
      /* eslint-disable-next-line */
      default_image: require('../../assets/EV_Chargers/electric-car-charging-station.jpg'),
      evps: [],
      makes: [],
      state: {
        searchInput: '',
        sortKey: 'EVP_Projects_Completed_Batteries',
        sortDirection: -1,
        filterServing: '',
        filterBusinessType: '',
        filterServicesProvided: '',
        filterServiceAreas: '',
        filterLiked: false,
      },
      totalItems: 0,
      numLiked: 0,
      minPrice: 0,
      maxPrice: 0,

    };
  },
  mounted() {
  },
  methods: {
    copyLink() {
      // Turns state of filters/sort/search into a query parameterized url and copies to clipboard.
      // State vars are loaded on created(), with the exception of likes which are loaded in App.vue
      const queries = [];
      if (this.state.searchInput) {
        queries.push(`searchInput=${this.state.searchInput}`);
      }
      if (this.state.sortKey) {
        queries.push(`sortKey=${this.state.sortKey}`);
      }
      if (this.state.sortDirection) {
        queries.push(`sortDirection=${this.state.sortDirection}`);
      }
      if (this.state.filterServing) {
        queries.push(`filterServing=${this.state.filterServing}`);
      }
      if (this.state.filterBusinessType) {
        queries.push(`filterBusinessType=${this.state.filterBusinessType}`);
      }
      if (this.state.filterLiked) {
        queries.push(`filterLiked=${this.state.filterLiked}`);
      }
      // Comma separated list of likes for use in query param using filter map
      const liked = this.$parent.$parent.charger_data.filter((charger) => charger.liked).map((charger) => charger.index).join(',');
      if (liked) {
        queries.push(`liked=${liked}`);
      }
      const path = `${[window.location.origin, this.$parent.client_api_path, 'charger_comparison?'].join('/').replaceAll('//', '/')}${queries.join('&')}`;
      navigator.clipboard.writeText(path);

      this.logMessage('info', 'Copy Link', path);
      return path;
    },
    getImgUrl(charger) {
      // Requires and gets route to image. Require setup here requires temporary lint disabling.
      // TODO we should consider a CDN setup to enable REACH members to easily add images.
      // Currently, charger images are added to /assets/EV_Chargers
      /* eslint-disable */
      try {
        return require(`../../assets/EV_Chargers/${charger.image_url}`);
      }
      catch (e) {
        // return require('../../assets/EV_Chargers/electric-car-charging-station.jpg');
        // Return null, handled by a v-if to simply not display the charger
        return null;
      }
      /* eslint-enable'...' */
    },
    getEVPData() {
      // Backend currently hardcoded to serve amerenmissouri only.
      const path = `${this.$baseUrl}/data/${this.$parent.client_api_path}/evps`;
      axios.get(path)
        .then((res) => {
          // console.log(res);
          this.evps = res.data.evps;
          // console.log(res);
          res.data.evps.forEach((item, index) => {
            let parsed = "";
            if (item.EVP_Description) {
              parsed = item.EVP_Description.split(/\r?\n/).map((line) => line.split(': '));
            }
            // const splitSemicolon = 
            this.evps[index].descriptionParsed = parsed;

            this.evps[index].EVP_Service_Areas_display = item.EVP_Service_Areas.split(/\r?\n/).join(', ');
            this.evps[index].EVP_Services_Provided_display = item.EVP_Services_Provided.split(/\r?\n/).join(', ');
            this.evps[index].EVP_Business_Types_display = item.EVP_Business_Types.split(/\r?\n/).join(', ');
            // dealer.OEM.forEach((make) => this.makeOptions.add(make));
            // const infoText = `
            // <b>${dealer.Account_Name}</b><br/>
            // <a href=${dealer.Website} target="_blank">Website</a>, <a href=https://www.google.com/maps/place/${dealer.latitude},${dealer.longitude} target="_blank">Get Directions</a><br/>
            // <b>Address:</b> ${dealer.Billing_Street}, <br/> ${dealer.Billing_City},
            // ${dealer.Billing_State}, ${dealer.Billing_Code}<br>
            // `;
            // const position = { lat: dealer.latitude, lng: dealer.longitude };
            // this.dealerships[index].infoText = infoText;
            // this.dealerships[index].position = position;
            // this.dealerships[index].haversine_distance = this.haversine_distance(this.center, position);
            // if (this.$parent.client_api_path === 'nvenergy' && !dealer.Program.includes('NVE Dealer Partner')) {
            //   this.dealerships[index].icon = 'https://maps.google.com/mapfiles/ms/icons/red-dot.png';
            // } else {
            //   this.dealerships[index].icon = 'https://maps.google.com/mapfiles/ms/icons/green-dot.png';
            // }
            // return;
            // return dealer;
            // return { infoText, position };

            // markers can only be keyboard focusable when they have click listeners
            // open info window when marker is clicked
            // marker.addListener("click", () => {
            //   infoWindow.setContent(label);
            //   infoWindow.open(map, marker);
            // });
            // return marker;
          });
          // this.makeOptions = Array.from(this.makeOptions).sort();
          // this.markers = markers;

          // console.log(res.data, this.markers, this.makeOptions);
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    getFilteredData() {
      // Filter
      const filtered = this.evps.filter((item) => this.passesFilters(item));
      // console.log(filtered);
      // Update values for banner display of number and min to max
      this.totalItems = filtered.length;
      // https://stackoverflow.com/questions/42413319/get-min-value-from-a-nested-json-object
      // this.minPrice = Math.min.apply(null, filtered.map((v) => v.price_dollars));
      // this.maxPrice = Math.max.apply(null, filtered.map((v) => v.price_dollars));
      // this.numLiked = filtered.filter((item) => item.liked).length;
      // Sort based on sort key and direction
      const sorted = filtered.sort((a, b) => {
        // Handle Name sorting using string comparison
        if (this.state.sortKey === 'Account_Name') {
          return this.state.sortDirection
            * (a[this.state.sortKey].localeCompare(b[this.state.sortKey]));
        }
        // Handle other sorting using numeric comparison
        return this.state.sortDirection * (a[this.state.sortKey] - b[this.state.sortKey]);
      });
      return sorted;
    },
    passesFilters(item) {
      // TODO - consider pulling out just specific fields like for EVs and Incentives.
      // Currently turns entire  object into a stringified json blob to compare against.
      const itemStr = JSON.stringify(item);


      if (this.state.searchInput
        && !itemStr.toUpperCase().includes(this.state.searchInput.toUpperCase())) {
        return false;
      }
        // filterServing: '',
        // filterBusinessType: '',
        // filterServicesProvided: '',
        // filterServiceAreas: '',
      if (this.state.filterServing && !item.EVP_Home_or_Business.includes(this.state.filterServing)) {
        return false;
      }
      if (this.state.filterBusinessType && !item.EVP_Business_Types.includes(this.state.filterBusinessType)) {
        return false;
      }
      if (this.state.filterServicesProvided && !item.EVP_Services_Provided.includes(this.state.filterServicesProvided)) {
        return false;
      }
      if (this.state.filterServiceAreas && item.EVP_Service_Areas !== 'All' && !item.EVP_Service_Areas.includes(this.state.filterServiceAreas)) {
        return false;
      }
      // if (this.state.filterBusinessType && !charger.Special_Features.includes(this.state.filterBusinessType)) {
      //   return false;
      // }
      // if (this.state.filterYear && charger.year !== this.state.filterYear) {
      //   return false;
      // }
      // if (this.state.filterLiked && !charger.liked) {
      //   return false;
      // }
      return true;
    },
  },
  created() {
    this.getEVPData();
    // Initialize state from url query params OR defaults
    this.state.searchInput = this.$route.query.searchInput || this.state.searchInput;
    this.state.sortKey = this.$route.query.sortKey || this.state.sortKey;
    this.state.sortDirection = this.$route.query.sortDirection || this.state.sortDirection;
    this.state.filterServing = this.$route.query.filterServing || this.state.filterServing;
    this.state.filterBusinessType = this.$route.query.filterBusinessType || this.state.filterBusinessType;
    this.state.filterLiked = this.$route.query.filterLiked || this.state.filterLiked;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#app, .center {
  text-align: center;
}

.iconTextBlock {
  text-align: center;
  width: 30%;
  display:flex;
  vertical-align: top;
  margin-right: 20px;
}
.iconTextBlock ul  {
  text-align: left;
}


.iconTextBlockIcon {
  vertical-align: top;
  max-height: 80px;
  margin-right: 20px;
  width: auto;
  height: auto;
}
.paragraph {
   margin-bottom: 10px;
}

.batteryIcon {
  height: 50px;
  margin-left: 10px;
}

.batteryIconWrapper {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

</style>
