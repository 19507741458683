<template>
<!-- eslint-disable max-len no-trailing-spaces -->
  <div>
    <!-- Image left outside the content wrap so that it fills width -->
    <div id="content-wrap">
      <!-- Formerly used plugshare map here. Unclear on licensing with that, while AFDC appears open to use! -->
<!--       <div>
        <div style="height:100%;">
          <iframe title="dashboard" frameborder=0 width="100%"  :src="getDashboardEmbedCode()" allow='geolocation'></iframe>
        </div>
      </div> -->
      <div style="height: auto; margin-bottom: 20px; width: 100%;" >
        <!-- https://afdc.energy.gov/stations#/find/nearest -->
        <div id="afdc-stations">
          <div id="afdc-stations-loading">Loading alternative fueling station locator...</div></div><noscript>Please enable JavaScript to view the alternative fueling station locator.</noscript>
      </div>
    </div>
  </div>
<!-- eslint-enable max-len -->
</template>

<script>

export default {
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    geolocate() {
      // Initial map load in case user declines providing location.
      // Could have this default to client region...
      this.s('https://fonts.googleapis.com/css?family=Karla:400,400i,700,700i');
      this.s('https://widgets.nrel.gov/afdc/station-locator/css/app.2d4ea1ff.css');
      this.t('https://widgets.nrel.gov/afdc/station-locator/js/chunk-vendors.c7debdc4.js');
      this.t('https://widgets.nrel.gov/afdc/station-locator/js/app.13c1cd31.js');
      window.afdcStationsOptions = {
        country: 'all',
        localeCountry: 'US',
        path: '/find/nearest',
        query: {
          fuel: 'ELEC',
          // location: `${this.center.lat}, ${this.center.lng}`,
        },
      };
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        console.log(this.center);
        // Update options fully (not just location property, doesn't seem to update map)
        // Just reload the map if we got the position.
        this.t('https://widgets.nrel.gov/afdc/station-locator/js/chunk-vendors.c7debdc4.js');
        this.t('https://widgets.nrel.gov/afdc/station-locator/js/app.13c1cd31.js');
        window.afdcStationsOptions = {
          country: 'all',
          localeCountry: 'US',
          path: '/find/nearest',
          query: {
            fuel: 'ELEC',
            location: `${this.center.lat}, ${this.center.lng}`,
          },
        };

        // this.t('https://widgets.nrel.gov/afdc/station-locator/js/app.13c1cd31.js');
        this.logMessage('info', 'Set User Location On Load', JSON.stringify(this.center));
        // return `${this.center.lat}, ${this.center.lng}`;
      });
    },
    getDashboardEmbedCode() {
      // Defaults to map of USA
      return this.$parent.plugshare_map_url || 'https://www.plugshare.com/widget2.html?plugs=1,2,3,4,5,6,42,13,7,8,9,10,11,12,14,15,16,17';
    },
    t(t) {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = !1;
      s.crossOrigin = 'anonymous';
      s.src = t;
      document.body.appendChild(s);
    },
    s(t) {
      const s = document.createElement('link');
      s.rel = 'stylesheet';
      s.href = t;
      document.body.appendChild(s);
    },
  },
  created() {
    this.geolocate();
    // this.s('https://fonts.googleapis.com/css?family=Karla:400,400i,700,700i');
    // this.s('https://widgets.nrel.gov/afdc/station-locator/css/app.2d4ea1ff.css');
    // this.t('https://widgets.nrel.gov/afdc/station-locator/js/chunk-vendors.c7debdc4.js');
    // this.t('https://widgets.nrel.gov/afdc/station-locator/js/app.13c1cd31.js');
    // window.afdcStationsOptions = {
    //   country: 'all',
    //   localeCountry: 'US',
    //   path: '/find/nearest',
    //   query: {
    //     fuel: 'ELEC',
    //     // location: `${this.center.lat}, ${this.center.lng}`,
    //   },
    // };
    // https://widgets.nrel.gov/afdc/station-locator/assets/embed.js
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

iframe {
  height: calc(100vh - 200px);
}
</style>
