<template>
<!-- eslint-disable max-len -->
  <div>
    <div id="content-wrap">
<!--       <div>
        <h1 class="pageTitle" id="title" role="banner">
          Find Incentives
        </h1>
      </div>
      <h2 class="subheader">
        Find electrification and energy efficiency incentives available to you.
        <br><br>
        You can look through all the incentives below. Or use the filters to limit the types of incentives you're viewing.
        <br/>
        <ul>
          <li>
            You can filter eligibility by home ownership, household size, income, and more.
          </li>
          <li>
            You can filter by categories or types such as rebate and tax credit.
          </li>
          <li>
            Search by keywords and sort by amount or name to narrow down further.
          </li>
        </ul>
        <font-awesome-icon icon="fa-solid fa-link" style="margin-left: 10px; margin-right: 10px;"/> Click the "Copy Link" button. Then paste into a message to share your filtered view with friends and family.
      </h2> -->
      <!-- <hr class="headerHR"> -->
      <div style="align-content: center; height: auto; margin: 0 0 20px 0;">
        <span class="subheader" style="font-size: 20px; color: var(--dark-grey); font-weight: 600; float: left; margin-top: 15px;">
          {{totalItems}} results
        </span>
        <button class="buttonStyleAccent"
          id="copy-link"
          aria-label="copy link"
          @click="copyLink()"
          style="margin-right: 0; float:right">
          <font-awesome-icon icon="fa-solid fa-link" />
          Copy Link
        </button>

<!--         <button class="buttonStyleAccentDark"
        @click="state.filterLiked = !state.filterLiked; logMessage('info', 'Compare Liked Cars', state.filterLiked)"
        style="float:right"
          aria-labelledby="compare-liked">
          <font-awesome-icon :icon="state.filterLiked
            ? 'fa-solid fa-heart' : 'fa-regular fa-heart'"/>
            Compare Favorites ({{numLiked}})
        </button> -->
      </div>
      <div class="controlPanelItemListWrapper">
        <div class="controlPanel" role="form">
          <div class="iconAddonWrapper">
            <img src="../../assets/search.svg" alt="search icon" class="iconAddonLeft">
            <input v-model="state.searchInput" @change="logMessage('info', 'Search', state.searchInput)"
              aria-label="search"
              role="search"
              type="text"
              id="searchInput"
              placeholder="Search"
              class="textinput">
          </div>
          <span class="standard"> Filter Eligibility</span>
          <!-- Removed Owner / Renter filter due to removal of Home incentives. May need to dynamically display if we use those for another client. -->
  <!--         <select v-model="state.filterHomeowner"
            @change="logMessage('info', 'Filter Homeowner Status', state.filterHomeowner)"
            aria-label="filter homeowner status"
            name="homeowner_status"
            id="homeowner_status"
            class="round">
              <option value="" selected>Owner/Renter</option>
              <option value="Homeowner">Homeowner</option>
              <option value="Renter">Renter</option>
          </select> -->
          <select v-model="state.filterTaxFiling"
            @change="logMessage('info', 'Filter Tax Filing', state.filterTaxFiling)"
            aria-label="filter tax filing status"
            name="tax_status"
            id="tax_status"
            class="round">
              <option value="" selected>Tax Filing Status</option>
              <!-- Differs from other dynamic option computations by using flatMap, because eligibilities are an array within incentives -->
              <!-- Dynamically compute options from the data. Automatically updates if options are added/removed from data -->
              <option v-for="(val, index) in $parent.$parent.incentive_data.flatMap(
                  (item) => item.eligibilities.map((eligibility) => eligibility.Tax_Filing_Status)
                ).filter(
                  (value, index, current_value) => value && current_value.indexOf(value) === index).sort()" :key="index"> {{val}}
              </option>
          </select>
          <select v-model="state.filterHouseholdSize"
            @change="logMessage('info', 'Filter Household Size', state.filterHouseholdSize)"
            aria-label="filter household size"
            name="household_size"
            id="household_size"
            class="round">
              <option value="" selected>Household Size</option>
              <!-- Hardcoded options for human readability - eg adding "Person/People" -->
              <option value=1>1 Person</option>
              <option value=2>2 People</option>
              <option value=3>3 People</option>
              <option value=4>4 People</option>
              <option value=5>5 People</option>
              <option value=6>6 People</option>
              <option value=7>7 People</option>
              <option value=8>8 People</option>
          </select>
            <!-- <span class="pageTitle" style="margin-left: 10px; margin-right: 0;">$</span> -->
          <div class="numberInputRight" data-endtext="$/year">
            <input v-model="state.filterIncome"
              @change="logMessage('info', 'Dollars per Gallon', state.filterIncome)"
              aria-label="dollars per gallon"
              placeholder="Income"
              type="number"
              step="1000"
              min="0.00"
              class="numberInput">
          </div>
          <input v-model="state.filterZipCode"
            @change="logMessage('info', 'Filter Zip Code', state.filterZipCode)"
            aria-label="filter zip code"
            id="filter-zip-code"
            placeholder="Zip Code"
            type="number"
            max="99999"
            min="0"
            class="numberInput">
          <hr>
          <span class="standard">Filter Incentives</span>
          <select v-model="state.filterGovernmentLevel"
            @change="logMessage('info', 'Filter Government Level', state.filterGovernmentLevel)"
            aria-label="filter government level"
            name="filter-government-level"
            id="filter-government-level"
            class="round">
              <option value="" selected>All Offerer Types</option>
              <!-- Dynamically compute options from the data. Automatically updates if options are added/removed from data -->
              <option v-for="(val, index) in $parent.$parent.incentive_data.map(
                  (item) => item.Government_Level
                ).filter(
                  (value, index, current_value) => current_value.indexOf(value) === index).sort()" :key="index"> {{val}}
              </option>
          </select>
          <select v-model="state.filterOfferer" @change="logMessage('info', 'Filter Offerer', state.filterOfferer)"
            aria-label="filter offerer"
            name="filter-offerer"
            id="filter-offerer"
            class="round">
            <option value="" selected>All Offerers</option>
            <!-- Dynamically compute options from the data. Automatically updates if options are added/removed from data -->
            <option v-for="(val, index) in $parent.$parent.incentive_data.map(
                (item) => item.Offerer
              ).filter(
                (value, index, current_value) => current_value.indexOf(value) === index).sort()" :key="index"> {{val}}
            </option>
          </select>
          <select v-model="state.filterCategory" @change="logMessage('info', 'Filter Category', state.filterCategory)"
            aria-label="filter category"
            name="filter-category"
            id="filter-category"
            class="round">
              <!-- Hardcoding dropdown options to add indentation, and since this pulls from two fields -->
              <option value="" selected>All Categories</option>
              <!-- Home incentives removed at request of Marin County. TODO if we adapt this tool to others that want to include Home, we'll need to dynamically set this. -->
  <!--             <option value="Home">Home: All</option>
              <option value="Solar">&emsp;Solar</option>
              <option value="Battery Storage">&emsp;Battery Storage</option>
              <option value="Heater / Air Conditioner">&emsp;Heater / Air Conditioner</option>
              <option value="Water Heater">&emsp;Water Heater</option>
              <option value="Induction Cooking">&emsp;Induction Cooking</option>
              <option value="Other">&emsp;Other</option>
              <option value="Transportation">Transportation: All</option> -->
              <option value="Electric Vehicle">Electric Vehicle</option>
              <option value="Electric Vehicle Charger">Electric Vehicle Charger</option>
              <option value="E-Bike">E-Bike</option>
              <!-- No incentives currently use these options, so don't surface to user -->
              <!-- <option value="Public Transit<">&emsp;Public Transit</option> -->
              <!-- <option value="Other">Other</option> -->
          </select>
          <select v-model="state.filterType" @change="logMessage('info', 'Filter Type', state.filterType)"
            style="margin-bottom:0"
            aria-label="filter type"
            name="filter-type"
            id="filter-type"
            class="round">
              <option value="" selected>All Types</option>
              <!-- Hardcoding to keep "Other" as the last option -->
              <option value="Rebate">Rebate</option>
              <option value="Tax Credit">Tax Credit</option>
              <option value="Sale Discount">Sale Discount</option>
              <option value="Financing">Financing</option>
              <option value="Other">Other</option>
          </select>
          <hr>
          <select v-model="state.sortKey"
            @change="logMessage('info', 'Sort By', state.sortKey)"
            aria-label="sort by"
            name="sort-by" id="sort-by"
            class="round">
              <option value='Incentive_Amount_Max'>Sort By: Amount</option>
              <option value='Name'>Sort By: Name</option>
          </select>
          <select v-model="state.sortDirection" @change="logMessage('info', 'Sort Order', state.sortDirection > 0 ? 'Low to High' : 'High to Low')"
            aria-label="toggle sort order">
            <option value="1" selected>Low to High</option>
            <!-- Options are hardcoded to display different human readable names -->
            <option value="-1"> High to Low </option>
          </select>
          <!-- TODO this reset needs to be updated every time state model is changed (or default vals are)
            I could see this leading to issues. TODO refactor so that these defaults only live in one place. -->
          <button class="buttonStyle"
            @click="state = {
              searchInput: '',
              sortKey: 'Incentive_Amount_Max',
              sortDirection: -1,

              filterOfferer: '',
              filterGovernmentLevel: '',
              filterCategory: '',
              filterType: '',

              filterHomeowner: '',
              filterTaxFiling: '',
              filterHouseholdSize: '',
              filterIncome: '',
              filterZipCode: '',
            }">
            <font-awesome-icon icon="fa-regular fa-circle-xmark" />
            Reset Filters
          </button>
        </div>
        <br/>
        <div class="itemListWrapper">
          <div class="itemList">
            <div v-for="(incentive, index) in getFilteredData()"
              v-bind:key="index"
              class="itemBlock"
              aria-labelledby="incentive_name"
              style="position: relative;">
              <div>
                <div>
<!--                   <div style="opacity: 30%; position: absolute; width: 100px; height: 100px;">
                    <font-awesome-icon icon="fa-solid fa-solar-panel" class="iconMobile"
                      v-if="incentive.Home_Subcategory === 'Solar'" />
                    <font-awesome-icon icon="fa-solid fa-battery-three-quarters" class="iconMobile"
                      v-if="incentive.Home_Subcategory === 'Battery Storage'" />
                    <font-awesome-icon icon="fa-solid fa-temperature-low" class="iconMobile"
                      v-if="incentive.Home_Subcategory === 'Heater / Air Conditioner'" />
                    <font-awesome-icon icon="fa-solid fa-water" class="iconMobile"
                      v-if="incentive.Home_Subcategory === 'Water Heater'" />
                    <font-awesome-icon icon="fa-solid fa-kitchen-set" class="iconMobile"
                      v-if="incentive.Home_Subcategory === 'Induction Cooking'" />
                    <font-awesome-icon icon="fa-solid fa-house" class="iconMobile"
                      v-if="incentive.Home_Subcategory === 'Other'" />

                    <font-awesome-icon icon="fa-solid fa-car-side" class="iconMobile"
                      v-if="incentive.Transportation_Subcategory === 'Electric Vehicle'" />
                    <font-awesome-icon icon="fa-solid fa-charging-station" class="iconMobile"
                      v-if="incentive.Transportation_Subcategory === 'Electric Vehicle Charger'" />
                    <font-awesome-icon icon="fa-solid fa-bicycle" class="iconMobile"
                      v-if="incentive.Transportation_Subcategory === 'E-Bike'" />
                    <font-awesome-icon icon="fa-solid fa-bus" class="iconMobile"
                      v-if="incentive.Transportation_Subcategory === 'Public Transit'" />
                  </div> -->
                  <h6 id="incentive_name">{{incentive.Incentives_Display_Name}}</h6>
                  <br>
                  <span class="large">{{incentive.Offerer}}</span>
                  <br>
                  <!-- <div style="display: flex; vertical-align: center;"> -->
                    <div style="margin: 5px 0 5px 0;">
                    <span class="large" style="color:var(--accent);">
                      {{incentive.Incentive_Amount_Description}}
                      <span v-if="incentive.Incentive_Type && incentive.Incentive_Type != 'Other'"> ({{incentive.Incentive_Type}})</span>
                    </span>
                    <!-- <button class="buttonStyle"
                      style="width: auto; margin-left: auto; float: right;"
                      @click="changeRoute(incentive.Resource_URL)">
                      <font-awesome-icon icon="fa-solid fa-circle-info" />
                      Learn More
                    </button> -->
                  </div>
                </div>
                <!-- max inner block ensures description shrinks until 500px / browser width low enough for next inline block to spill over-->
                <!-- min outer block ensures width capped at space available, no text overflow past right edge of display block -->
                <div style="width:min(max(50%,500px),100%); display:inline-block; vertical-align: top; margin-bottom: 10px;">
                  <span>{{incentive.Description}}</span>
                </div>
                <div style="width:min(max(30%,400px),100%); display:inline-block; vertical-align: top;">
                    <b>Amount:</b> <span>{{incentive.Incentive_Amount_Description}}</span><span v-if="incentive.Incentive_Type && incentive.Incentive_Type != 'Other'"> ({{incentive.Incentive_Type}})</span>
                    <br />
                    <!-- <b>Category:</b> <span>{{incentive.Category}} - {{incentive.Home_Subcategory || incentive.Transportation_Subcategory}}</span> -->
                    <!-- TODO: use above if including Home incentives in future... -->
                    <b>Category:</b> <span>{{incentive.Home_Subcategory || incentive.Transportation_Subcategory}}</span>
                    <br />
                    <!-- <b>Incentive Type:</b> <span>{{incentive.Incentive_Type}}</span> -->
                    <!-- <br /> -->
                    <b>Offerer:</b> <span>{{incentive.Offerer}} ({{incentive.Government_Level}})</span>
                    <br />
                    <!-- <b>Offerer:</b> <span>{{incentive.Offerer}}</span> -->
                    <!-- <br /> -->
                </div>
                <div style="display:inline-block; width: 100%; height: 50px;"></div>
                  <!-- This is an invisible block that clears space for the absolute positioned button and icon below. This is necessary to prevent text overlapping on small width screens like mobile. -->
                <!-- </div> -->
                <div style="position: absolute; right: 20px; bottom: 20px;">
                  <div>
                    <font-awesome-icon icon="fa-solid fa-solar-panel" class="iconMobile"
                      v-if="incentive.Home_Subcategory === 'Solar'" />
                    <font-awesome-icon icon="fa-solid fa-battery-three-quarters" class="iconMobile"
                      v-if="incentive.Home_Subcategory === 'Battery Storage'" />
                    <font-awesome-icon icon="fa-solid fa-temperature-low" class="iconMobile"
                      v-if="incentive.Home_Subcategory === 'Heater / Air Conditioner'" />
                    <font-awesome-icon icon="fa-solid fa-water" class="iconMobile"
                      v-if="incentive.Home_Subcategory === 'Water Heater'" />
                    <font-awesome-icon icon="fa-solid fa-kitchen-set" class="iconMobile"
                      v-if="incentive.Home_Subcategory === 'Induction Cooking'" />
                    <font-awesome-icon icon="fa-solid fa-house" class="iconMobile"
                      v-if="incentive.Home_Subcategory === 'Other'" />

                    <font-awesome-icon icon="fa-solid fa-car-side" class="iconMobile"
                      v-if="incentive.Transportation_Subcategory === 'Electric Vehicle'" />
                    <font-awesome-icon icon="fa-solid fa-charging-station" class="iconMobile"
                      v-if="incentive.Transportation_Subcategory === 'Electric Vehicle Charger'" />
                    <font-awesome-icon icon="fa-solid fa-bicycle" class="iconMobile"
                      v-if="incentive.Transportation_Subcategory === 'E-Bike'" />
                    <font-awesome-icon icon="fa-solid fa-bus" class="iconMobile"
                      v-if="incentive.Transportation_Subcategory === 'Public Transit'" />
                  </div>
                </div>
                <button class="buttonStyle"
                  style="width: auto; padding: 0 10px; margin-bottom: 0; position: absolute; left: 20px; bottom: 20px;"
                  @click="changeRoute(incentive.Resource_URL)">
                  <font-awesome-icon icon="fa-solid fa-circle-info" />
                  Learn More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
/* eslint-disable */
import axios from 'axios';

export default {
  data() {
    return {
      /* eslint-disable-next-line */
      data: [],
      state: {
        searchInput: '',
        sortKey: 'Incentive_Amount_Max',
        sortDirection: -1,

        filterOfferer: '',
        filterGovernmentLevel: '',
        filterCategory: '',
        filterType: '',

        filterHomeowner: '',
        filterTaxFiling: '',
        filterHouseholdSize: '',
        filterIncome: '',
        filterZipCode: '',
      },
      totalItems: 0,
      totalEligibleMinAmount: 0,
      totalEligibleMaxAmount: 0,
    };
  },
  mounted() {
  },
  methods: {
    copyLink() {
      // Initialize state from query params
      const queries = [];
      if (this.state.searchInput) {
        queries.push(`searchInput=${this.state.searchInput}`);
      }
      if (this.state.sortKey) {
        queries.push(`sortKey=${this.state.sortKey}`);
      }
      if (this.state.sortDirection) {
        queries.push(`sortDirection=${this.state.sortDirection}`);
      }

      if (this.state.filterOfferer) {
        queries.push(`filterOfferer=${this.state.filterOfferer}`);
      }
      if (this.state.filterGovernmentLevel) {
        queries.push(`filterGovernmentLevel=${this.state.filterGovernmentLevel}`);
      }
      if (this.state.filterCategory) {
        queries.push(`filterCategory=${this.state.filterCategory}`);
      }
      if (this.state.filterType) {
        queries.push(`filterType=${this.state.filterType}`);
      }

      if (this.state.filterHomeowner) {
        queries.push(`filterHomeowner=${this.state.filterHomeowner}`);
      }
      if (this.state.filterTaxFiling) {
        queries.push(`filterTaxFiling=${this.state.filterTaxFiling}`);
      }
      if (this.state.filterHouseholdSize) {
        queries.push(`filterHouseholdSize=${this.state.filterHouseholdSize}`);
      }
      if (this.state.filterIncome) {
        queries.push(`filterIncome=${this.state.filterIncome}`);
      }
      if (this.state.filterZipCode) {
        queries.push(`filterZipCode=${this.state.filterZipCode}`);
      }

      const path = `${[window.location.origin, this.$parent.client_api_path, 'incentives?'].join('/').replaceAll('//', '/')}${queries.join('&')}`;
      navigator.clipboard.writeText(path);
      this.logMessage('info', 'Copy Link', path);

      return path;
    },

    getFilteredData() {
      // Filter
      const filtered = this.$parent.$parent.incentive_data.filter((d) => this.passesFilters(d));

      // Compute length and min max for banner display
      this.totalItems = filtered.length;
      this.totalEligibleMinAmount = filtered.reduce((n, {Incentive_Amount_Min}) => n + Incentive_Amount_Min, 0);
      this.totalEligibleMaxAmount = filtered.reduce((n, {Incentive_Amount_Max}) => n + Incentive_Amount_Max, 0);

      // Sort
      const sorted = filtered.sort((a, b) => {
        // Handle Name sorting using string comparison
        if (this.state.sortKey === 'Name') {
          return this.state.sortDirection
            * (a[this.state.sortKey].localeCompare(b[this.state.sortKey]));
        }
        // Handle other sorting using numeric comparison
        return this.state.sortDirection * (a[this.state.sortKey] - b[this.state.sortKey]);
      });

      return sorted;
    },

    passesFilters(incentive) {
      // String comparison for searching
      const incentiveStr = `${incentive. Incentives_Display_Name} | ${incentive.Offerer} ${incentive.Description} ${incentive.Name} ${incentive.Government_Level}`;
      if (this.state.searchInput
        && !incentiveStr.toUpperCase().includes(this.state.searchInput.toUpperCase())) {
        return false;
      }
      // Dropdown filters for incentive info
      if (this.state.filterGovernmentLevel && incentive.Government_Level !== this.state.filterGovernmentLevel) {
        return false;
      }
      if (this.state.filterOfferer && incentive.Offerer !== this.state.filterOfferer) {
        return false;
      }
      if (this.state.filterCategory && incentive.Category !== this.state.filterCategory) {
        // Also check subcategories for a match. This is a little special being one select dropdown filtering three fields.
        if (incentive.Transportation_Subcategory !== this.state.filterCategory && incentive.Home_Subcategory !== this.state.filterCategory) {
          return false;
        }
      }
      if (this.state.filterType && incentive.Incentive_Type !== this.state.filterType) {
        return false;
      }

      // Eligibility filtering
      if (incentive.eligibilities.length === 0) return true;
      // Calculate Income as a Percentage of Area Median Income, State Median Income, and Federal Poverty Line for specified Household Size
      // Defaults househould size to 1 if not set.
      let household_size = Math.max(this.state.filterHouseholdSize, 1);
      let ami_percentage = 100*this.state.filterIncome/this.$parent.household_size_ami[household_size - 1];
      let smi_percentage = 100*this.state.filterIncome/this.$parent.household_size_smi[household_size - 1];
      let fpl_percentage = 100*this.state.filterIncome/this.$parent.household_size_fpl[household_size - 1];
      // console.log(ami_percentage, smi_percentage, fpl_percentage);

      // Match all conditions of any one of the eligibilities. If a filter isn't set, consider that condition matched.
      // Only one eligibility needs to fully match for an incentive to pass the filter.
      let matches_eligibility = false;
      incentive.eligibilities.forEach((eligibility) => {
        matches_eligibility = matches_eligibility || ((!eligibility.Home_Ownership || !this.state.filterHomeowner || (eligibility.Home_Ownership  === this.state.filterHomeowner)) &&
          (!eligibility.Tax_Filing_Status || !this.state.filterTaxFiling || (eligibility.Tax_Filing_Status  === this.state.filterTaxFiling)) &&

          (!eligibility.Min_Household_Size || (eligibility.Min_Household_Size  <= household_size)) &&
          (!eligibility.Max_Household_Size || (eligibility.Max_Household_Size  >= household_size)) && 

          (!eligibility.Min_Income || !this.state.filterIncome || (eligibility.Min_Income  <= this.state.filterIncome)) &&
          (!eligibility.Max_Income || !this.state.filterIncome || (eligibility.Max_Income  >= this.state.filterIncome)) &&

          (!eligibility.Min_Income_AMI || !this.state.filterIncome || (eligibility.Min_Income_AMI  <= ami_percentage)) &&
          (!eligibility.Max_Income_AMI || !this.state.filterIncome || (eligibility.Max_Income_AMI  >= ami_percentage)) &&

          (!eligibility.Min_Income_SMI || !this.state.filterIncome || (eligibility.Min_Income_SMI  <= smi_percentage)) &&
          (!eligibility.Max_Income_SMI || !this.state.filterIncome || (eligibility.Max_Income_SMI  >= smi_percentage)) &&

          (!eligibility.Min_Income_FPL || !this.state.filterIncome || (eligibility.Min_Income_FPL  <= fpl_percentage)) &&
          (!eligibility.Max_Income_FPL || !this.state.filterIncome || (eligibility.Max_Income_FPL  >= fpl_percentage)) &&

          (!eligibility.Zip_Code || !!this.state.filterZipCode || (eligibility.Zip_Code.includes(this.state.filterZipCode.toString()))));
      });
      return matches_eligibility;
    },
  },
  created() {
    // Initialize state from query params
    this.state.searchInput = this.$route.query.searchInput || this.state.searchInput;
    this.state.sortKey = this.$route.query.sortKey || this.state.sortKey;
    this.state.sortDirection = this.$route.query.sortDirection || this.state.sortDirection;

    this.state.filterOfferer = this.$route.query.filterOfferer || this.state.filterOfferer;
    this.state.filterGovernmentLevel = this.$route.query.filterGovernmentLevel || this.state.filterGovernmentLevel;
    this.state.filterCategory = this.$route.query.filterCategory || this.state.filterCategory;
    this.state.filterType = this.$route.query.filterType || this.state.filterType;

    this.state.filterHomeowner = this.$route.query.filterHomeowner || this.state.filterHomeowner;
    this.state.filterTaxFiling = this.$route.query.filterTaxFiling || this.state.filterTaxFiling;
    this.state.filterHouseholdSize = this.$route.query.filterHouseholdSize || this.state.filterHouseholdSize;
    this.state.filterIncome = this.$route.query.filterIncome || this.state.filterIncome;
    this.state.filterZipCode = this.$route.query.filterZipCode || this.state.filterZipCode;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.iconMobile {
  float: right;
  margin: auto auto;
  height: auto;
  vertical-align: middle;
  clear: both;
  color: var(--accent);
  width:50px;
/*  margin:auto;*/
margin: 0;
/*  margin-top: 20px;*/
}

.itemBlock {
/*  width: auto;*/
/*  min-width:calc(33.333% - 20px);*/
/*min-width: ;*/
/*  max-width: 100%;*/
/*flex: 1 1 calc(33.333% - 20px); /* Flex-grow, Flex-shrink, Flex-basis */
/*  width: 100%;*/
/*  height: auto;*/
/*  margin-left: 20px;*/
/*  margin-right: 0;*/
}

.paragraph {
   margin-bottom: 10px;
}

</style>
