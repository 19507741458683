<template>
<!-- eslint-disable max-len -->
  <div id="content-wrap">
    <div>
      <title> Electric Vehicle Tools Home </title>
    </div>
    <div >
      <h5 id="title" role="banner">
        Electric Vehicle Events
      </h5>
      <div class="paragraphGroup">
        <div class="paragraph" style="margin-top:20px; margin-bottom: 20px;" >
          {{$parent.event_page_description}}
        </div>
      </div>
        <!-- <div v-for="(event, index) in events" v-bind:key="index">
          {{event['Name']}}
        </div>  -->
        <div id="eventList" class="itemList">
          <!-- TODO: dynamic height, hide unset fields, etc -->
          <div v-for="(event, index) in getFilteredEventData()"
            v-bind:key="index"
            class="itemBlock"
            style="width:100%; height: auto;">

            <h6>{{event.Name}}</h6>

            <img alt="Event Image" v-if="event.Event_Image"
              :src="event.Event_Image" style="float: right; margin:20px; height: 250px;"/>
            <div>
              <button v-if="event.Event_Signup" class="buttonStyle" @click="changeRoute(event.Event_Signup)"> Register Here </button>
              <button v-if="event.Event_Website" class="buttonStyle" @click="changeRoute(event.Event_Website)"> Find Out More </button>
            </div>
            <div v-if="event.Event_Location">
              <b>Event Type:</b> <span>{{event.Event_Location}}</span>
              <br />
            </div>
            <div v-if="event.Street_Address">
              <b>Street:</b> <span>{{event.Street_Address}}</span>
              <br />
            </div>
            <div v-if="event.City">
              <b>City:</b> <span>{{event.City}}</span>
              <br />
            </div>
<!--             <div v-if="event.Event_Type">
              <b>Type:</b> <span>{{event.Event_Type}}</span>
              <br />
            </div> -->
            <div v-if="event.Date">
              <b>Date:</b> <span>{{event.Date}}</span>
              <br />
            </div>
            <div v-if="event.Event_Description" style="width: 70%">
              <b>Event Description:</b> <br/>
              <span style="white-space: pre-wrap;">{{event.Event_Description}}</span>
            </div>
          </div>
          <!-- <img v-bind:src="event.Event_Image" width=400 alt="Car"  /> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
<!-- eslint-enable max-len -->
</template>

<script>
/* eslint-disable no-console */
import axios from 'axios';

export default {
  data() {
    return {
      events: [],
    };
  },
  mounted() {
  },
  methods: {
    getEventData() {
      const path = `${this.$baseUrl}/data/${this.$parent.client_api_path}/events`;
      axios.get(path)
        .then((res) => {
          console.log('res', res);
          this.events = res.data.events;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    getFilteredEventData() {
      const sortDir = -1;
      const filtered = this.events.filter((event) => this.passesFilters(event));
      const sorted = filtered.sort((a, b) => sortDir * new Date(a.Date)
        - sortDir * new Date(b.Date));

      if (sorted.length === 0) {
        // Handle no events by displaying below:
        return [{ Name: 'There are no upcoming events at this time.' }];
      }
      return sorted;
    },
    passesFilters(event) {
      // const eventStr = JSON.stringify(event);
      return event.Date && new Date(event.Date) > new Date();
      // if (this.state.searchInput
      //   && !carStr.toUpperCase().includes(this.state.searchInput.toUpperCase())) {
      //   return false;
      // }

      // return true;
    },
  },
  created() {
    this.getEventData();
    console.log('this.events is now: ', this.events);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
