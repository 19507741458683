<template>
<!-- eslint-disable max-len no-trailing-spaces -->
  <div>
    <!-- Image left outside the content wrap so that it fills width -->
    <div id="content-wrap">
      <div>
        <title> Analytics </title>
      </div>
      <div>
        <div style="height:100%;">
          <iframe title="dashboard" frameborder=0 width="100%" height="2000px" :src="getDashboardEmbedCode()"></iframe>
        </div>
      </div>
    </div>
  </div>
<!-- eslint-enable max-len -->
</template>

<script>

export default {
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    getDashboardEmbedCode() {
      const embed = `https://analytics.zoho.com/open-view/2389782000005492279/4087933afb1e03a6ad3c1e870a4a5041?ZOHO_CRITERIA=%22logs%22.%22level%22%20%3D%20'info'%20AND%20%22logs%22.%22url%22%20LIKE%20'%25driventoelectrify.com%2F${this.$parent.client_api_path}%25'`;
      // console.log(embed);
      return embed;
    },
  },
  created() {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
